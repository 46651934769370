import { Box, createStyles, Grid, Hidden, makeStyles } from '@material-ui/core';
import ToggleTheme from 'components/ToggleTheme';
import { MOBILE_M } from 'config/theme/breakpoints';
import React, { FC } from 'react';

type Props = {};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.grey[900],
      flexGrow: 1,
      minHeight: '100vh',
      display: 'flex'
    },
    container: {
      margin: 'auto',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        marginTop: 0
      }
    },
    desktopLinks: {
      position: 'absolute',
      top: 48,
      right: 48
    },
    imageContainer: {
      alignSelf: 'stretch',
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(3)
      }
    },
    image: {
      backgroundImage: 'url(/assets/login.svg)',
      backgroundSize: 'cover',
      width: '100%',
      [theme.breakpoints.down(MOBILE_M)]: {
        backgroundImage: 'url(/assets/login-mobile.svg)',
        paddingTop: '40%',
        backgroundPosition: '50% 100%'
      },
      [theme.breakpoints.between(MOBILE_M, 'sm')]: {
        borderBottomRightRadius: 60,
        borderBottomLeftRadius: 60,
        paddingTop: '100%',
        backgroundPositionY: '100%'
      },
      [theme.breakpoints.between('sm', 'md')]: {
        borderBottomRightRadius: 60,
        borderBottomLeftRadius: 60,
        paddingTop: '69%',
        backgroundPositionY: '100%'
      },
      [theme.breakpoints.up('lg')]: {
        height: '100%',
        minHeight: '100vh',
        borderBottomRightRadius: 60,
        borderTopRightRadius: 60,
        backgroundPosition: '50% 100%'
      }
    },
    form: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0)
      }
    }
  })
);

const NotAuthenticatedLayout: FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Hidden mdDown implementation="css">
        <Box className={classes.desktopLinks}>
          <ToggleTheme/>
        </Box>
      </Hidden>
      <Grid container spacing={0} className={classes.container}
            alignItems="center"
            justify="center">
        <Grid item lg xs={12} className={classes.imageContainer}>
          <div className={classes.image}/>
        </Grid>
        <Grid item lg xs={12} className={classes.form}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default NotAuthenticatedLayout;
