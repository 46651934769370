import { createStyles, FormControl, makeStyles, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import HiddenJs from '@material-ui/core/Hidden/HiddenJs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CurrentScoreContext from 'contexts/currentScore';
import { format } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FirebaseReducer } from 'react-redux-firebase';
import { IRootState } from 'reducers';
import { useGetPerformanceScoresQuery } from 'utils/queryProxy';
import { findScoreByDate, parseScores } from 'utils/scoreUtils';

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      marginRight: theme.spacing(0.7),
      fontWeight: 600
    },
    menuPaper: {
      marginTop: theme.spacing(0.5)
    }
  })
);

const useMenuListStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontSize: 12,
      '&$selected, &$selected:hover': {
        backgroundColor: theme.palette.action.selected,
        fontWeight: 'inherit'
      },
    },
    selected: {}
  })
);

const useInputStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontSize: 12,
      // background: theme.palette.type === 'light' ? '#E3F3FE' : '#363540',
      background: theme.palette.type === 'light' ? '#E3F3FE' : '#292830',
      '& $notchedOutline': {
        borderWidth: 0
      },
      '&:hover $notchedOutline': {
        borderWidth: 0
      },
      '&$focused $notchedOutline': {
        borderWidth: 0
      }
    },
    input: {
      lineHeight: 1.5,
      height: '1.5em',
      minHeight: '1.5em',
      fontSize: 12
    },
    focused: {},
    notchedOutline: {}
  })
);

const ScoresSelect: React.FC = () => {
  const { score, setScore } = useContext(CurrentScoreContext);
  const inputClasses = useInputStyles();
  const menuListClasses = useMenuListStyles();
  const classes = useStyles();
  const auth = useSelector<IRootState, FirebaseReducer.AuthState>(state => state.firebase.auth);

  const { data } = useGetPerformanceScoresQuery({
    variables: {
      firebaseUID: auth.uid
    }
  });

  useEffect(() => {
    if (data && !score?.testDate) {
      const score = parseScores(data.getPerformanceScores)[0];
      setScore(score);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (data) {
    const scores = parseScores(data.getPerformanceScores);

    const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setScore(findScoreByDate(scores, Number(event.target.value)) || score);
    };

    return <FormControl variant="outlined" size="small">
      <Select
        id="performance-scores-select"
        value={score?.testDate || scores[0].testDate}
        onChange={onChange}
        renderValue={(selected) =>
          <>
            <HiddenJs xsDown><span className={classes.label}>Test date:</span></HiddenJs>
            {format(selected as number | Date, 'PP')}
          </>}
        input={<OutlinedInput classes={inputClasses} />}
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          PaperProps: {
            className: classes.menuPaper
          }
        }}
      >
        {scores.map(score => (
          <MenuItem value={score.testDate} key={score.testDate}
            classes={menuListClasses}>{format(score.testDate, 'PP')}</MenuItem>
        ))}
      </Select>
    </FormControl>;
  } else
    return null;
};

export default ScoresSelect;