import { Button, Grid, Hidden, IconButton, InputAdornment, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import ToggleTheme from 'components/ToggleTheme';
import React, { useState } from 'react';
import breakpoints from '../../../../config/theme/breakpoints';
// @ts-ignore
import { Hide as HideIcon, Message as MessageIcon, Password as PasswordIcon, Show as ShowIcon } from 'react-iconly';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  },
  form: {
    width: '100%'
  },
  input: {
    maxWidth: 500
  },
  forgotLink: {
    marginRight: 0,
    marginLeft: 'auto'
  },
  submit: {
    fontSize: 18,
    width: 261,
    height: 56
  },
  hint: {
    color: theme.palette.grey[500]
  },
  MuiInputBase: {
    input: {
      padding: '8px 15px',
      fontSize: 14,
      lineHeight: '34px',
      minHeight: 34,
      '&:-webkit-autofill': {
        WebkitBoxShadow: 'inset 0 0 0 50px #fff',
        borderRadius: 'inherit'
      },
      '&:-webkit-autofill::first-line': {
        fontSize: 14,
        fontFamily: 'Poppins, Verdana, sans-serif'
      },
      [breakpoints.down('sm')]: {
        fontSize: 16,
        '&:-webkit-autofill::first-line': {
          fontSize: 16,
          fontFamily: 'Poppins, Verdana, sans-serif'
        }
      }
    }
  },
}));

export interface ILoginFormProps {
  loginError: any;
  handleLogin: Function;
  handleReset: Function;
}

const LoginForm: React.FC<ILoginFormProps> = ({ loginError, handleLogin, handleReset }) => {
  const classes = useStyles();
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(event.currentTarget);
    handleLogin(formData.get('email'), formData.get('password'));
    event.preventDefault();
  };

  const handleResetClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    handleReset();
    event.preventDefault();
  };

  return (
    <Grid container alignItems="center" direction="column"
      justify="center">
      <Grid item className={classes.title}>
        <Typography component="h1" variant="h1">
          Log into account
        </Typography>
      </Grid>
      <Grid item xs className={classes.input}>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            placeholder="Email"
            name="email"
            type="email"
            InputProps={{
              startAdornment: <InputAdornment position="start">
                <MessageIcon primaryColor="#9CB1C5" set="light" />
              </InputAdornment>
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            placeholder="Password"
            type={isPasswordVisible ? 'text' : 'password'}
            id="password"
            InputProps={{
              startAdornment: <InputAdornment position="start">
                <PasswordIcon primaryColor="#9CB1C5" set="light" />
              </InputAdornment>,
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  size="small"
                >
                  {isPasswordVisible ? <ShowIcon primaryColor="#9CB1C5" set="light" /> :
                    <HideIcon primaryColor="#9CB1C5" set="light" />}
                </IconButton>
              </InputAdornment>
            }}
          />
          <Grid container alignItems="center" justify="center" direction="column" spacing={2}>
            <Grid item className={classes.forgotLink}>
              <Typography>
                <Link href="#" onClick={handleResetClick}>Forgot your password?</Link>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Log in
              </Button>
            </Grid>
            <Grid item>
              <Typography className={classes.hint}>
                Don't have an account? <Link href="https://www.elevateme.ai/health-plans/">Start your journey here.</Link>
              </Typography>
            </Grid>
            <Grid item>
              {loginError && (
                <Alert severity="error">The email and password that you entered did not match our records. Please
                  double-check and try again.</Alert>
              )}
            </Grid>
            <Hidden lgUp implementation="css">
              <Grid item>
                <ToggleTheme />
              </Grid>
            </Hidden>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default LoginForm;