import * as yup from 'yup';

const validationSchema = yup.object({
  goals: yup.array().min(1).max(3).required(),
  lifestyle: yup.object({
    exerciseFrequency: yup.string().required('This is a required field'),
    currentExercise: yup.array(),
    sleepDuration: yup.string().required('This is a required field'),
    sunExposure: yup.string().required('This is a required field'),
    smokingHabit: yup.string().required('This is a required field'),
    diet: yup.array(),
    foodsAvoided: yup.array(),
    // diet: yup.array().min(1).required(),
    // foodsAvoided: yup.array().min(1).required(),
    // beverages: yup.array().min(1).required(),
    willingToTakeSupplements: yup.string().required('This is a required field'),
    currentSupplements: yup.array()
  })
});

export default validationSchema;