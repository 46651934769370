import { useQuery } from '@apollo/react-hooks';
import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TabContext, TabPanel } from '@material-ui/lab';
import { PAGE } from 'actions/demo';
import DemoBanner from 'components/DemoBanner/DemoBanner';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import ScoresSelect from 'components/ScoresSelect';
import { Tab, Tabs } from 'components/styled';
import StyledPaper from 'components/StyledPaper';
import CurrentScoreContext from 'contexts/currentScore';
import { Bound, TestResult } from 'generated/graphql';
import withDemoAccount from 'hocs/withDemoAccount';
import withUser, { WithUserProps } from 'hocs/withUser';
import Biomarker from 'models/Biomarker';
import BloodGroups from 'pages/Insights/BloodGroups';
import DemoInsights from 'pages/Insights/components/DemoInsights';
import PerformanceAreas from 'pages/Insights/PerformanceAreas';
import GET_BIOMARKERS from 'queries/getBiomarkers';
import React, { useContext } from 'react';
import { fbanalytics } from '../../config/firebase'
import { RouteComponentProps } from 'react-router-dom';
import {
  extractBounds,
  filterByTestResult,
  getAgeBracket,
  getGenderBound,
  getIntervals,
  getValueInterval
} from 'utils/biomarkersUtils';
import { useGetTestResultsQuery } from 'utils/queryProxy';
import { findByDate, parse } from 'utils/testDateUtils';


const useStyles = makeStyles((theme) =>
  createStyles({
    tabPanel: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(1)}px 0`
      }
    },
    root: {
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    }
  })
);

const Insights: React.FC<WithUserProps & RouteComponentProps<{ areaKey?: string }>> = ({
  user,
  match: { params: { areaKey } }
}) => {
  const classes = useStyles();
  fbanalytics.logEvent('insights_visit');
  const [tab, setTab] = React.useState('bloodGroups');
  const { score } = useContext(CurrentScoreContext);

  const { data: biomarkersData } = useQuery(GET_BIOMARKERS(getGenderBound(user.gender), getAgeBracket(user.dob)));
  const { data: testResultData } = useGetTestResultsQuery({
    variables: {
      firebaseUID: user.firebaseUID
    }
  });

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
  };

  if (biomarkersData && testResultData && score) {
    let biomarkers = biomarkersData.getBiomarkers as Array<Biomarker>;
    const testResult = findByDate(parse(testResultData.getTestResults) as Array<TestResult>, score?.testDate) as TestResult;
    biomarkers = extractBounds(filterByTestResult(biomarkers, testResult), getGenderBound(user.gender), getAgeBracket(user.dob));

    biomarkers.forEach(biomarker => {
      const maxValue = Math.max(...testResultData.getTestResults.map(tr => tr[biomarker.biomarkerRef]));
      const value = testResult[biomarker.biomarkerRef] as number;
      const intervals = getIntervals(biomarker.bound as Bound, maxValue);
      biomarker.maxValue = maxValue;
      let interval = getValueInterval(intervals, value);
      if (interval) {
        if (interval.quality > 3) {
          biomarker.position = (7 - interval.quality + (value - interval.lower) / (interval.lower - interval.upper)) * 100;
        } else if (interval.quality === 3) {
          let currentPosition = (value - interval.lower) / (interval.upper - interval.lower);
          if (currentPosition > 0.5) currentPosition = 1 - currentPosition;
          biomarker.position = (interval.quality + currentPosition) * 100;
        } else {
          biomarker.position = (interval.quality + (value - interval.lower) / (interval.upper - interval.lower)) * 100;
        }
      } else biomarker.position = 0;
    });
    biomarkers.sort((a, b) => (a.position as number) - (b.position as number));

    return (<>
      <DemoBanner page={PAGE.INSIGHTS} />
      <PageTitle title="Results">
        <Box pl={2} component="span">
          <ScoresSelect />
        </Box>
      </PageTitle>
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="inherit"
        >
          <Tab label="Blood groups" value="bloodGroups" />
          <Tab label="Health areas" value="performanceAreas" />

        </Tabs>
        <StyledPaper elevation={0} container className={classes.root}>
          <TabPanel value="bloodGroups" className={classes.tabPanel}>
            <BloodGroups biomarkers={biomarkers} testResult={testResult} />
          </TabPanel>
          <TabPanel value="performanceAreas" className={classes.tabPanel}>
            <PerformanceAreas biomarkers={biomarkers} testResult={testResult} area={areaKey} />
          </TabPanel>
        </StyledPaper>
      </TabContext>
    </>);
  }

  return (<>
    <PageTitle title="Results">
      <Box pl={2} component="span">
        <ScoresSelect />
      </Box>
    </PageTitle>
    <TabContext value={tab}>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="inherit"
      >
        <Tab label="Blood groups" value="bloodGroups" />
        <Tab label="Health areas" value="performanceAreas" />

      </Tabs>
      <StyledPaper elevation={0} container className={classes.root}>
        <TabPanel value="bloodGroups" className={classes.tabPanel}>
          <Loading />
        </TabPanel>
      </StyledPaper>
    </TabContext>
  </>);
};

export default withUser(withDemoAccount(Insights, DemoInsights, PAGE.INSIGHTS));
