import { SvgIconProps } from '@material-ui/core/SvgIcon';
import StyledSvgIcon from 'icons/StyledSvgIcon';
import React from 'react';

const MoonIcon = (props: SvgIconProps) => {
  return <StyledSvgIcon viewBox="0 0 16 16" {...props}>
    <path
      d="M13.8481 9.34799C12.8566 9.57541 11.8237 9.54726 10.8461 9.2662C9.86847 8.98513 8.9783 8.46035 8.25903 7.74109C7.53977 7.02182 7.01499 6.13165 6.73393 5.15406C6.45286 4.17647 6.42472 3.1435 6.65213 2.15206C5.60834 2.39284 4.64795 2.90862 3.87082 3.64589C3.09369 4.38315 2.52809 5.315 2.23273 6.34469C1.93737 7.37437 1.92303 8.46435 2.19119 9.50144C2.45935 10.5385 3.00024 11.485 3.7577 12.2424C4.51515 12.9999 5.46156 13.5408 6.49866 13.8089C7.53576 14.0771 8.62574 14.0628 9.65542 13.7674C10.6851 13.472 11.617 12.9064 12.3542 12.1293C13.0915 11.3522 13.6073 10.3918 13.8481 9.34799Z"
      strokeWidth="1.15385" strokeLinecap="round" strokeLinejoin="round"/>
  </StyledSvgIcon>;
};

export default MoonIcon;