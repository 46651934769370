import { Typography, TypographyProps, withStyles } from '@material-ui/core';
import React from 'react';


const GridSectionTitle = withStyles(({ spacing }) => ({
  root: {
    marginBottom: spacing(0.5)
  }
}))(({ classes, children, ...props }: TypographyProps) => <Typography variant="subtitle1"
                                                                      className={classes?.root} {...props}>{children}</Typography>);

export default GridSectionTitle;