import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';
import SplashScreen from 'components/SplashScreen';
import {
  GetAllInvoicesDocument, GetCustomerDocument,
  GetSubscriptionDocument,
  GetUserDocument,
  GetUserQuery,
  GetUserQueryVariables
} from 'generated/graphql';
import GET_BIOMARKERS from 'queries/getBiomarkers';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FirebaseReducer } from 'react-redux-firebase';
import { IRootState } from 'reducers';
import { getAgeBracket, getGenderBound } from 'utils/biomarkersUtils';
import queryProxy from 'utils/queryProxy';

const AppLoader: React.FC = ({ children }) => {
  let [isLoaded, setIsLoaded] = useState<boolean>();
  const auth = useSelector<IRootState, FirebaseReducer.AuthState>(state => state.firebase.auth);
  const client = useApolloClient();

  useEffect(() => {
    if (!auth.isEmpty) {
      if (auth.isLoaded) {
        client.query<GetUserQuery, GetUserQueryVariables>({
          query: GetUserDocument,
          variables: {
            firebaseUID: auth.uid
          }
        }
        ).then(
          (result) => {
            const user = result.data.getUser;
            queryProxy.isDemo = user.demoUser;

            return Promise.all([
              queryProxy.getActions({
                firebaseUID: auth.uid
              }),
              queryProxy.getTestResults({
                firebaseUID: auth.uid
              }),
              queryProxy.getPerformanceScores({
                firebaseUID: auth.uid
              }),
              client.query({
                query: GET_BIOMARKERS(getGenderBound(user.gender), getAgeBracket(user.dob))
              }),
              {/*client.query({
                query: GetAllInvoicesDocument,
                variables: {
                  customerId: user.stripeCustomerID
                }
              }),
              client.query({
                query: GetSubscriptionDocument,
                variables: {
                  customerId: user.stripeCustomerID
                }
              }),
              client.query({
                query: GetCustomerDocument,
                variables: {
                  customerId: user.stripeCustomerID
                }
              })*/}
            ]);
          }
        ).then(() => {
          setIsLoaded(true);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  if ((auth.isEmpty && isLoaded === undefined && auth.isLoaded) || isLoaded) {
    return <>{children}</>;
  } else {
    return <SplashScreen />;
  }
};

export default AppLoader;
