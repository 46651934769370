import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import { PAGE, showPage } from 'actions/demo';
import DemoMessage from 'components/DemoMessage';
import React from 'react';
import { useDispatch } from 'react-redux';

const DemoActions: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(showPage(PAGE.ACTIONS));
  };

  const button1 = <Button
    variant="contained"
    color="primary"
    fullWidth={isMobile}
    onClick={handleClick}
  >
    Demo Actions
  </Button>;
  const button2 = <Button
    variant="contained"
    color="primary"
    fullWidth={isMobile}
    href="https://www.elevateme.ai/elevateme-health-journey/"
    target="_blank"
  >
    Health journey articles
  </Button>;

  return <DemoMessage
    title="Your ElevateMe Actions"
    body1="Any recommended lifestyle actions will appear here once your results are available."
    body2="For now, check out the demo actions page or read up on how to implement your actions:"
    button1={button1}
    button2={button2}
  />;
};

export default DemoActions;