import { SvgIconProps } from '@material-ui/core/SvgIcon';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { Barbell, CirclesThree, Cookie, DropHalf, Fire, Heartbeat, Pill, ShieldCheck } from 'phosphor-react';
import React from 'react';

export interface BloodGroupIconProps extends SvgIconProps {
  category: string;
}

const BloodGroupIcon: React.FC<BloodGroupIconProps> = ({ category, ...other }) => {
  switch (category) {
    case 'all':
      return <FormatListBulletedIcon {...other}/>;
    case 'Vitamins':
      return <Pill {...other}/>;
    case 'Liver':
      return <ShieldCheck weight="light" {...other} />;
    case 'Cholesterol':
      return <Heartbeat weight="light" {...other} />;
    case 'Diabetes':
      return <Cookie {...other}/>;
    case 'Hormones':
      return <CirclesThree weight="light" {...other} />;
    case 'Iron':
      return <DropHalf weight="light" {...other}/>;
    case 'Proteins':
      return <Barbell weight="light" {...other}/>;
    case 'Inflammation':
      return <Fire weight="light" {...other}/>;
    default:
      return <Pill {...other}/>;
  }
};

export default BloodGroupIcon;