import {
  Box,
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ApexOptions } from 'apexcharts';
import clsx from 'clsx';
import Loading from 'components/Loading';
import StyledPaper from 'components/StyledPaper';
import StyledTooltip from 'components/StyledTooltip/StyledTooltip';
import { format, sub } from 'date-fns';
import withUser, { WithUserProps } from 'hocs/withUser';
import React, { useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// @ts-ignore
import { InfoSquare as InfoSquareIcon } from 'react-iconly';
import { useGetPerformanceScoresQuery } from 'utils/queryProxy';
import { getScoreName, GOOD, NEED_IMPROVING, parseScores, SCORE } from 'utils/scoreUtils';
import { capitalize } from 'utils/string';

const useSelectStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 12,
      background: theme.palette.type === 'light' ? '#E3F3FE' : '#555A68',
      '& $notchedOutline': {
        borderWidth: 0
      },
      '&:hover $notchedOutline': {
        borderWidth: 0
      },
      '&$focused $notchedOutline': {
        borderWidth: 0
      }
    },
    input: {
      lineHeight: 1.5,
      height: '1.5em',
      minHeight: '1.5em',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      fontSize: 12
    },
    focused: {},
    notchedOutline: {}
  })
);

const useStyles = makeStyles((theme) => {
  // const dottedLine = `linear-gradient(to right, ${theme.palette.divider}, ${theme.palette.divider} 50%, transparent 50%, transparent 100%)`;
  // const dottedLine = `linear-gradient(to right, rgb(175, 191, 201), rgb(175, 191, 201) 45%, transparent 45%, transparent 45%), linear-gradient(to right, rgb(175, 191, 201), rgb(175, 191, 201) 45%, transparent 45%, transparent 45%), linear-gradient(to right, rgb(175, 191, 201), rgb(175, 191, 201) 45%, transparent 45%, transparent 45%)`;
  // const dottedLine = `linear-gradient(rgb(175, 191, 201), rgb(175, 191, 201) 50%, transparent 50%, transparent 100%), linear-gradient(rgb(175, 191, 201), rgb(175, 191, 201) 50%, transparent 50%, transparent 50%)`;
  return createStyles({
    root: {},
    tooltip: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1)
    },
    icon: {
      fill: 'none',
      stroke: theme.palette.text.primary,
      height: 16,
      width: 16,
      verticalAlign: 'middle',
      marginRight: theme.spacing(1)
    },
    menu: {
      marginTop: theme.spacing(0.5),
      backgroundColor: theme.palette.type === 'light' ? theme.palette.common.white : theme.palette.divider
    },
    menuItem: {
      fontSize: 12
    },
    grid: {
      position: 'absolute',
      marginTop: 30,
      top: 0,
      left: 37,
      right: 0,
      boxSizing: 'content-box',
      borderBottom: `1px solid ${theme.palette.divider}`,
      // backgroundImage: `${dottedLine}, ${dottedLine}, ${dottedLine}`,
      // backgroundImage: theme.palette.type === 'light' ? 'linear-gradient(to right, rgb(175, 191, 201), rgb(175, 191, 201) 45%, transparent 45%, transparent 45%), linear-gradient(to right, rgb(175, 191, 201), rgb(175, 191, 201) 45%, transparent 45%, transparent 45%), linear-gradient(to right, rgb(175, 191, 201), rgb(175, 191, 201) 45%, transparent 45%, transparent 45%)' : 'linear-gradient(to right, rgb(219, 219, 219), rgb(219, 219, 219) 45%, transparent 45%, transparent 45%), linear-gradient(to right, rgb(219, 219, 219), rgb(219, 219, 219) 45%, transparent 45%, transparent 45%), linear-gradient(to right, rgb(219, 219, 219), rgb(219, 219, 219) 45%, transparent 45%, transparent 45%)',
      backgroundImage: 'linear-gradient(to right, rgb(175, 191, 201), rgb(175, 191, 201) 45%, transparent 45%, transparent 45%), linear-gradient(to right, rgb(175, 191, 201), rgb(175, 191, 201) 45%, transparent 45%, transparent 45%), linear-gradient(to right, rgb(175, 191, 201), rgb(175, 191, 201) 45%, transparent 45%, transparent 45%)',
      backgroundPositionY: `0%, ${100 - GOOD}%, ${100 - NEED_IMPROVING}%`,
      backgroundSize: '16px 1px',
      backgroundRepeat: 'repeat-x',
      [theme.breakpoints.down('sm')]: {
        left: 24
      }
    },
    chartGrid: {
      height: '100%',
      flexWrap: 'nowrap'
    },
    chart: {
      width: '100%',
      height: '100%',
      '& .apexcharts-svg': {
        overflow: 'visible'
      }
    },
    chartBlock: {
      zIndex: 1,
      marginLeft: 25,
      marginRight: 20,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 12,
        marginRight: 10
      }
    },
    chartInfo: {
      marginTop: 30,
      textAlign: 'right'
    },
    chartScore: {
      width: 160,
      // color: 'rgba(156, 177, 197, 0.4)',
      color: theme.palette.type === 'light' ? 'rgb(106, 129, 143)' : 'rgb(219, 219, 219)',

      [theme.breakpoints.down('md')]: {
        width: 75
      },
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        right: 0,
        width: 'auto',
      }
    },
    chartScoreLabel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    chartContainer: {
      position: 'relative',
      height: 'calc(100vh - 100px)',
      [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 180px)'
      }
    },
    labels: {
      lineHeight: 0
    },
    yAxisLabel: {
      position: 'relative'
    }
  });
}
);

enum FILTER_PERIODS {
  '6MONTH' = '6month',
  YEAR = 'year',
  ALL = 'all'
}

const PerformanceTrends: React.FC<WithUserProps> = ({ user }) => {
  const theme = useTheme();
  const classes = useStyles();
  const inputClasses = useSelectStyles();
  const adjustableElements = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null)
  ];
  const [filter, setFilter] = useState(FILTER_PERIODS.ALL);

  const { data: scoresData } = useGetPerformanceScoresQuery({
    variables: {
      firebaseUID: user.firebaseUID
    }
  });

  const setFilterValue = (event: React.ChangeEvent<{ value: unknown }>) => setFilter(event.target.value as FILTER_PERIODS);
  const lastYear = sub(new Date(), { years: 1 }).getTime();
  const last6Month = sub(new Date(), { months: 6 }).getTime();


  useEffect(() => {
    if (scoresData && user.goals) {
      Object.values(SCORE).forEach(score => {
        if (!user.goals.includes(score)) {
          ApexCharts.exec('trends-chart', 'hideSeries', getScoreName(score as SCORE));
        }
      });
    }
  }, [scoresData, user.goals]);

  if (scoresData) {
    const performanceScores = parseScores(scoresData.getPerformanceScores)
      .sort((a, b) => a.testDate - b.testDate);

    let filteredScores = performanceScores;

    switch (filter) {
      case (FILTER_PERIODS['6MONTH']):
        filteredScores = performanceScores.filter(score => score.testDate > last6Month);
        break;
      case (FILTER_PERIODS.YEAR):
        filteredScores = performanceScores.filter(score => score.testDate > lastYear);
        break;
      default:
        filteredScores = performanceScores;
        break;
    }
    const state: { series: ApexAxisChartSeries, options: ApexOptions } = {
      series: Object.values(SCORE).map(score => ({
        name: capitalize(score),
        data: filteredScores.map(ps => ps[score])
      })),
      options: {
        chart: {
          background: 'inherit',
          fontFamily: theme.typography.fontFamily,
          events: {
            updated: (chartContext) => {
              const top = chartContext.w.globals.translateY;
              const height = chartContext.w.globals.gridHeight;
              adjustableElements.forEach(ref => {
                if (ref.current) {
                  ref.current.style.marginTop = `${top}px`;
                  ref.current.style.height = `${height}px`;
                }
              });
            }
          },
          id: 'trends-chart',
          toolbar: {
            show: false
          },
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        // colors: ['#F2D95F', '#DF6977', '#25EB9A', '#E67649', '#55A6BF', '#8642C6', '#41B0F6'],
        colors: ['#2AC1DC', '#A254E7', '#F94284', '#FC9A1F', '#F9D405', '#25EB9A', '#4489FF'],
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 0.9,
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 1,
            opacityFrom: 0.4,
            opacityTo: 0
          }
        },
        grid: {
          show: false
        },
        labels: filteredScores.map(ps => format(ps.testDate, 'd MMM yy')),
        legend: {
          position: 'top'
        },
        markers: {
          shape: 'circle',
          size: 6,
          strokeWidth: 0,
          hover: {
            size: 8
          }
        },
        responsive: [{
          breakpoint: theme.breakpoints.values.sm,
          options: {
            legend: {
              position: 'bottom'
            }
          }
        }],
        stroke: {
          curve: 'smooth',
          width: 2.5,
          dashArray: 0
        },
        theme: {
          mode: theme.palette.type
        },
        tooltip: {
          y: {
            formatter: (v: number) => `${v}%`
          }
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            style: {
              fontWeight: 500,
              fontSize: theme.typography.body2.fontSize as string
            }
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          show: false,
          min: 0,
          max: 100
        }
      }
    };

    return (
      <StyledPaper elevation={0} className={classes.root}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h2">
              Health trends
              <StyledTooltip
                className={classes.tooltip}
                title={<>
                  <div>Track your Performance Scores over time to see your health trends.</div>
                  <br />
                  <div>Toggle the health areas (e.g. Energy) to show or hide on the chart. When you re-test, your
                    new
                    Performance Scores will be added here.
                  </div>
                </>}>
                <InfoSquareIcon set="light" size="small" color={theme.palette.grey[500]} />
              </StyledTooltip>
            </Typography>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" size="small">
              <Select
                id="performance-scores-select"
                value={filter}
                onChange={setFilterValue}
                input={<OutlinedInput classes={inputClasses} />}
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null,
                  elevation: 1,
                  PaperProps: {
                    className: classes.menu
                  }
                }}
              >
                <MenuItem value={FILTER_PERIODS['6MONTH']} className={classes.menuItem}>Last 6 months</MenuItem>
                <MenuItem value={FILTER_PERIODS.YEAR} className={classes.menuItem}>Last year</MenuItem>
                <MenuItem value={FILTER_PERIODS.ALL} className={classes.menuItem}>All time</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.chartContainer}>
          <div className={classes.grid} ref={adjustableElements[0]} />
          <Grid container className={classes.chartGrid}>
            <Grid item className={clsx([classes.chartInfo, classes.labels])} ref={adjustableElements[1]}>
              <Box height={`${100 - GOOD}%`}>
                <span className={classes.yAxisLabel}>100</span>
              </Box>
              <Box height={`${GOOD - NEED_IMPROVING}%`}>
                <span className={classes.yAxisLabel}>{GOOD}</span>
              </Box>
              <Box height={`${NEED_IMPROVING}%`}>
                <span className={classes.yAxisLabel}>{NEED_IMPROVING}</span>
              </Box>
              <span className={classes.yAxisLabel}>0</span>
            </Grid>
            <Grid item xs className={classes.chartBlock}>
              <div className={classes.chart}>
                <ReactApexChart options={state.options} series={state.series} type="area" height="100%" />
              </div>
            </Grid>
            <Grid item className={clsx([classes.chartInfo, classes.chartScore])} ref={adjustableElements[2]}>
              <Box height={`${100 - GOOD}%`} className={classes.chartScoreLabel}><span>Great score</span></Box>
              <Box height={`${GOOD - NEED_IMPROVING}%`}
                className={classes.chartScoreLabel}><span>Good score</span></Box>
              <Box height={`${NEED_IMPROVING}%`} className={classes.chartScoreLabel}><span>Needs improving</span></Box>
            </Grid>
          </Grid>
        </div>
      </StyledPaper>
    );
  } else
    return <StyledPaper elevation={0} className={classes.root}>
      <Loading />
    </StyledPaper>;
};

export default withUser(PerformanceTrends);