import {
  Button,
  Checkbox,
  Chip,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  OutlinedInput
} from '@material-ui/core';
import InfoTooltip from 'components/InfoTooltip';
import { Select } from 'components/styled';
import breakpoints from 'config/theme/breakpoints';
import notificationsContext from 'contexts/notificationsContext';
import { useFormik } from 'formik';
import { useUpdateLifestyleMutation } from 'generated/graphql';
import withUser, { WithUserProps } from 'hocs/withUser';
import validationSchema from 'pages/Profile/LifestyleForm/lifestyleFormValidationSchema';
import { Barbell, Bed, Coffee, ForkKnife, SunDim, ThumbsDown, Pill, Prohibit, CalendarBlank } from 'phosphor-react';
import React, { useContext } from 'react';
import CapsuleLineIcon from 'remixicon-react/CapsuleLineIcon';
import { YES_NO, yesNo } from 'utils/common';
import {
  beverages,
  BEVERAGES,
  DIET,
  diet,
  EXERCISE,
  exercise,
  EXERCISE_FREQUENCY,
  exerciseFrequency,
  FOODS,
  foods,
  sleepDuration,
  smokingHabit,
  sunExposure,
  supplements,
  SUPPLEMENTS
} from 'utils/lifestyle';
import { goals, SCORE } from 'utils/scoreUtils';
import { fbanalytics } from '../../../config/firebase'

const demoUser = process.env.REACT_APP_DEMO_ACCOUNT as string;

const useStyles = makeStyles((theme) =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: -3,
      minHeight: 40,
      alignItems: 'center'
    },
    chip: {
      margin: 3,
      height: 34,
      fontSize: 14,
      borderRadius: 6,
      color: theme.palette.common.white,
      background: 'linear-gradient(96.74deg, #41B0F6 0%, #8AD2FF 94.85%)',
      [breakpoints.down('sm')]: {
        fontSize: 12,
        height: 32
      }
    },
    icon: {
      marginRight: theme.spacing(0.7),
      fontSize: 16,
      verticalAlign: 'bottom'
    },
    actions: {
      paddingTop: theme.spacing(1.5)
    },
    goalsLegend: {
      paddingTop: 0
    }
  })
);

const LifestyleForm: React.FC<WithUserProps> = ({ user }) => {
  const classes = useStyles();
  fbanalytics.logEvent('lifestyle_visit');
  const [updateLifestyle] = useUpdateLifestyleMutation();
  const { notify } = useContext(notificationsContext);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      goals: user.goals,
      lifestyle: {
        exerciseFrequency: user.lifestyle.exerciseFrequency || '',
        currentExercise: user.lifestyle.currentExercise || [] as Array<string>,
        sleepDuration: user.lifestyle.sleepDuration || '',
        sunExposure: user.lifestyle.sunExposure || '',
        smokingHabit: user.lifestyle.smokingHabit || '',
        diet: user.lifestyle.diet,
        foodsAvoided: user.lifestyle.foodsAvoided || [] as Array<string>,
        beverages: user.lifestyle.beverages || [] as Array<string>,
        willingToTakeSupplements: user.lifestyle.willingToTakeSupplements || '',
        currentSupplements: user.lifestyle.currentSupplements || [] as Array<string>
      }
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (user.firebaseUID !== demoUser) {
        updateLifestyle({
          variables: {
            firebaseUID: user.firebaseUID,
            goals: values.goals,
            lifestyle: values.lifestyle
          }
        }).then(() => window.location.reload());
        // }).then(() => notify('Lifestyle updated', 'success'));
      } else {
        notify('This is a demo account and cannot be updated.', 'success');
      }
    }
  });

  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
    formik.setFieldValue(event.target.name as string, event.target.value as any);
  };

  const handleChangeExercise = (event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
    const value = event.target.value as EXERCISE_FREQUENCY;
    formik.setFieldValue('lifestyle.exerciseFrequency', value);

    if (value === EXERCISE_FREQUENCY.NONE) {
      formik.setFieldValue('lifestyle.currentExercise', []);
    }
  };

  const handleChangeSupplements = (event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
    const value = event.target.value as YES_NO;
    formik.setFieldValue('lifestyle.willingToTakeSupplements', value);

    if (value === YES_NO.NO) {
      formik.setFieldValue('lifestyle.currentSupplements', []);
    }
  };

  return <form onSubmit={formik.handleSubmit}>
    <fieldset>
      <legend className={classes.goalsLegend}>
        Goal focus areas
        <InfoTooltip
          title="Select up to three goal focus areas you'd like to target. Our intelligent algorithm, Cato, will tailor your recommended actions toward achieving these goals." />
      </legend>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={formik.touched.goals && Boolean(formik.errors.goals)}>
            <Select
              id="goals"
              name="goals"
              multiple
              value={formik.values.goals}
              onChange={handleChange}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as SCORE[]).map((value) => (
                    <Chip color="primary" key={value} label={goals[value]} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {Object.entries(goals).map(([key, label]) => (
                <MenuItem key={key} value={key}
                  disabled={formik.values.goals.length >= 3 && formik.values.goals.indexOf(key) === -1}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.goals ? formik.values.goals.indexOf(key) > -1 : false}
                  />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.goals && formik.errors.goals}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </fieldset>
    <fieldset>
      <legend>
        Lifestyle
      </legend>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth
            error={formik.touched.lifestyle?.exerciseFrequency && Boolean(formik.errors.lifestyle?.exerciseFrequency)}>
            <InputLabel id="exerciseFrequency-label" shrink={false}>
              <CalendarBlank className={classes.icon} />How often do you exercise?
            </InputLabel>
            <Select
              labelId="exerciseFrequency-label"
              id="exerciseFrequency"
              name="lifestyle.exerciseFrequency"
              value={formik.values.lifestyle.exerciseFrequency}
              onChange={handleChangeExercise}
            >
              {Object.entries(exerciseFrequency).map(([value, label]) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.lifestyle?.exerciseFrequency && formik.errors.lifestyle?.exerciseFrequency}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth disabled={formik.values.lifestyle.exerciseFrequency === EXERCISE_FREQUENCY.NONE}
            error={formik.touched.lifestyle?.currentExercise && Boolean(formik.errors.lifestyle?.currentExercise)}>
            <InputLabel id="currentExercise-label" shrink={false}>
              <Barbell className={classes.icon} weight="light" />Exercise
            </InputLabel>
            <Select
              labelId="currentExercise-label"
              id="currentExercise"
              name="lifestyle.currentExercise"
              multiple
              value={formik.values.lifestyle.currentExercise}
              onChange={handleChange}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as EXERCISE[]).map((value) => (
                    <Chip key={value} label={exercise[value]} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {Object.entries(exercise).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.lifestyle.currentExercise ? formik.values.lifestyle.currentExercise.indexOf(value) > -1 : false} />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.lifestyle?.currentExercise && formik.errors.lifestyle?.currentExercise}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth
            error={formik.touched.lifestyle?.sleepDuration && Boolean(formik.errors.lifestyle?.sleepDuration)}>
            <InputLabel id="sleepDuration-label" shrink={false}>
              <Bed className={classes.icon} weight="light" />How much sleep on an average night?
            </InputLabel>
            <Select
              labelId="sleepDuration-label"
              id="sleepDuration"
              name="lifestyle.sleepDuration"
              value={formik.values.lifestyle.sleepDuration}
              onChange={handleChange}
            >
              {Object.entries(sleepDuration).map(([value, label]) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.lifestyle?.sleepDuration && formik.errors.lifestyle?.sleepDuration}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth
            error={formik.touched.lifestyle?.sunExposure && Boolean(formik.errors.lifestyle?.sunExposure)}>
            <InputLabel id="sunExposure-label" shrink={false}>
              <SunDim className={classes.icon} weight="light" />How much sun?
            </InputLabel>
            <Select
              labelId="sunExposure-label"
              id="sunExposure"
              name="lifestyle.sunExposure"
              value={formik.values.lifestyle.sunExposure}
              onChange={handleChange}
            >
              {Object.entries(sunExposure).map(([value, label]) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.lifestyle?.sunExposure && formik.errors.lifestyle?.sunExposure}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth
            error={formik.touched.lifestyle?.smokingHabit && Boolean(formik.errors.lifestyle?.smokingHabit)}>
            <InputLabel id="smokingHabit-label" shrink={false}>
              <Prohibit className={classes.icon} />Do you smoke?
            </InputLabel>
            <Select
              labelId="smokingHabit-label"
              id="smokingHabit"
              name="lifestyle.smokingHabit"
              value={formik.values.lifestyle.smokingHabit}
              onChange={handleChange}
            >
              {Object.entries(smokingHabit).map(([value, label]) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.lifestyle?.smokingHabit && formik.errors.lifestyle?.smokingHabit}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </fieldset>
    <fieldset>
      <legend>
        Nutrition
      </legend>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={formik.touched.lifestyle?.diet && Boolean(formik.errors.lifestyle?.diet)}>
            <InputLabel id="diet-label" shrink={false}>
              <ForkKnife className={classes.icon} weight="light" />Diet
            </InputLabel>
            <Select
              id="diet"
              labelId="diet-label"
              name="lifestyle.diet"
              multiple
              value={formik.values.lifestyle.diet}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as DIET[]).map((value) => (
                    <Chip key={value} label={diet[value]} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {Object.entries(diet).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.lifestyle.diet ? formik.values.lifestyle.diet.indexOf(value) > -1 : false} />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.lifestyle?.diet && formik.errors.lifestyle?.diet}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth
            error={formik.touched.lifestyle?.foodsAvoided && Boolean(formik.errors.lifestyle?.foodsAvoided)}>
            <InputLabel id="foodsAvoided-label" shrink={false}><ThumbsDown className={classes.icon} />
              Foods you don't eat
            </InputLabel>
            <Select
              id="foodsAvoided"
              labelId="foodsAvoided-label"
              name="lifestyle.foodsAvoided"
              multiple
              value={formik.values.lifestyle.foodsAvoided}
              onChange={handleChange}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as FOODS[]).map((value) => (
                    <Chip key={value} label={foods[value]} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {Object.entries(foods).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.lifestyle.foodsAvoided ? formik.values.lifestyle.foodsAvoided.indexOf(value) > -1 : false} />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.lifestyle?.foodsAvoided && formik.errors.lifestyle?.foodsAvoided}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth
            error={formik.touched.lifestyle?.beverages && Boolean(formik.errors.lifestyle?.beverages)}>
            <InputLabel id="beverages-label" shrink={false}>
              <Coffee className={classes.icon} weight="light" />Beverages you drink
            </InputLabel>
            <Select
              id="beverages"
              labelId="beverages-label"
              name="lifestyle.beverages"
              multiple
              value={formik.values.lifestyle.beverages}
              onChange={handleChange}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as BEVERAGES[]).map((value) => (
                    <Chip key={value} label={beverages[value]} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {Object.entries(beverages).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.lifestyle.beverages ? formik.values.lifestyle.beverages.indexOf(value) > -1 : false} />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.lifestyle?.beverages && formik.errors.lifestyle?.beverages}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </fieldset>
    <fieldset>
      <legend>
        Supplements
      </legend>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth
            error={formik.touched.lifestyle?.willingToTakeSupplements && Boolean(formik.errors.lifestyle?.willingToTakeSupplements)}>
            <InputLabel id="willingToTakeSupplements-label" shrink={false}>
              <Pill className={classes.icon} />Are you willing to take supplements?
            </InputLabel>
            <Select
              labelId="willingToTakeSupplements-label"
              id="willingToTakeSupplements"
              name="lifestyle.willingToTakeSupplements"
              value={formik.values.lifestyle.willingToTakeSupplements}
              onChange={handleChangeSupplements}
            >
              {Object.entries(yesNo).map(([value, label]) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth
            error={formik.touched.lifestyle?.currentSupplements && Boolean(formik.errors.lifestyle?.currentSupplements)}
            disabled={formik.values.lifestyle.willingToTakeSupplements === YES_NO.NO}>
            <InputLabel id="currentSupplements-label" shrink={false}>
              <CapsuleLineIcon className={classes.icon} size={16} />
              Which supplements do you currently take?
            </InputLabel>
            <Select
              id="currentSupplements"
              labelId="currentSupplements-label"
              name="lifestyle.currentSupplements"
              multiple
              value={formik.values.lifestyle.currentSupplements}
              onChange={handleChange}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as SUPPLEMENTS[]).map((value) => (
                    <Chip key={value} label={supplements[value]} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {Object.entries(supplements).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.lifestyle.currentSupplements ? formik.values.lifestyle.currentSupplements.indexOf(value) > -1 : false} />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.lifestyle?.currentSupplements && formik.errors.lifestyle?.currentSupplements}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </fieldset>
    <Grid container justify="center" className={classes.actions}>
      <Grid item>
        <Button
          type="submit"
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      </Grid>
    </Grid>
  </form>;
};

export default withUser(LifestyleForm);