import { createStyles, makeStyles, useTheme } from '@material-ui/core';
import StyledTooltip from 'components/StyledTooltip';
import React from 'react';
// @ts-ignore
import { InfoSquare as InfoSquareIcon } from 'react-iconly';


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(1)
    }
  })
);

export interface InfoTooltipProps {
  title: string
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({ title }) => {
  const classes = useStyles();
  const { palette } = useTheme();

  return <StyledTooltip
    className={classes.root}
    title={title}>
    <InfoSquareIcon set="light" size="small" color={palette.grey[500]} />
  </StyledTooltip>;
};

export default InfoTooltip;