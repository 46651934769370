import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  timestamp: any;
};


export type Action = {
  __typename?: 'Action';
  id: Scalars['ID'];
  body: Scalars['String'];
  username: Scalars['String'];
  createdAt: Scalars['String'];
  testDate: Scalars['DateTime'];
  actionRef: Scalars['String'];
  actionTitle: Scalars['String'];
  actionWhy: Scalars['String'];
  actionUsage: Scalars['String'];
  category: Scalars['String'];
  frequency: Scalars['String'];
  impactRanking: Scalars['Int'];
  performanceAreas: Array<Scalars['String']>;
  biomarkersBenefited: Array<Scalars['String']>;
  product: Product;
  conflicts?: Maybe<Conflicts>;
  medicalReferences: Array<Scalars['String']>;
};

export type Action2 = {
  __typename?: 'Action2';
  id: Scalars['ID'];
  body: Scalars['String'];
  username: Scalars['String'];
  createdAt: Scalars['String'];
  testDate: Scalars['String'];
  actionRef: Scalars['String'];
  actionTitle: Scalars['String'];
  actionWhy: Scalars['String'];
  actionUsage: Scalars['String'];
  category: Scalars['String'];
  frequency: Scalars['String'];
  impactRanking: Scalars['Int'];
  ImpactScore: Scalars['Int'];
  performanceAreas: Array<Scalars['String']>;
  biomarkersBenefited?: Maybe<Array<Maybe<Scalars['String']>>>;
  product: Product;
  conflicts?: Maybe<Conflicts>;
  medicalReferences: Array<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
};

export type BillingPortal = {
  __typename?: 'BillingPortal';
  return_url?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PaymentSession = {
  __typename?: 'PaymentSession';
  return_url?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Biomarker = {
  __typename?: 'Biomarker';
  id: Scalars['ID'];
  biomarkerRef: Scalars['String'];
  name: Scalars['String'];
  unit: Scalars['String'];
  bloodGroup: Scalars['String'];
  description: Scalars['String'];
  tooLow: Scalars['String'];
  tooHigh: Scalars['String'];
  roomToImprove: Scalars['String'];
  optimalLevel: Scalars['String'];
  learnMore: Scalars['String'];
  performanceAreas: Array<Scalars['String']>;
  maleBounds: MaleBounds;
  femaleBounds: FemaleBounds;
  tooLowActions?: Maybe<Array<Maybe<Scalars['String']>>>;
  tooHighActions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Bound = {
  __typename?: 'Bound';
  criticallyLowUB?: Maybe<Scalars['Float']>;
  lowUB?: Maybe<Scalars['Float']>;
  subOptimalUB?: Maybe<Scalars['Float']>;
  optimalUB?: Maybe<Scalars['Float']>;
  supraOptimalUB?: Maybe<Scalars['Float']>;
  highUB?: Maybe<Scalars['Float']>;
  criticallyHighUB?: Maybe<Scalars['Float']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Conflicts = {
  __typename?: 'Conflicts';
  diet?: Maybe<Array<Maybe<Scalars['String']>>>;
  foods?: Maybe<Array<Maybe<Scalars['String']>>>;
  beverages?: Maybe<Array<Maybe<Scalars['String']>>>;
  exercise?: Maybe<Array<Maybe<Scalars['String']>>>;
  sun?: Maybe<Array<Maybe<Scalars['String']>>>;
  smoke?: Maybe<Array<Maybe<Scalars['String']>>>;
  supplements?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Customer = {
  __typename?: 'Customer';
  balance?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  metadata?: Maybe<Metadata>;
};

export type Customer_Shipping = {
  __typename?: 'Customer_Shipping';
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
};


export type FemaleBounds = {
  __typename?: 'FemaleBounds';
  _18?: Maybe<Bound>;
  _30?: Maybe<Bound>;
  _40?: Maybe<Bound>;
  _50?: Maybe<Bound>;
  _60?: Maybe<Bound>;
  _150?: Maybe<Bound>;
};

export type InputDarkMode = {
  id: Scalars['ID'];
  darkMode: Scalars['String'];
};

export type InputLifestyle = {
  diet?: Maybe<Array<Maybe<Scalars['String']>>>;
  foodsAvoided?: Maybe<Array<Maybe<Scalars['String']>>>;
  beverages?: Maybe<Array<Maybe<Scalars['String']>>>;
  willingToTakeSupplements?: Maybe<Scalars['String']>;
  currentlyTakingSupplements?: Maybe<Scalars['String']>;
  currentSupplements?: Maybe<Array<Maybe<Scalars['String']>>>;
  currentExercise?: Maybe<Array<Maybe<Scalars['String']>>>;
  exerciseFrequency?: Maybe<Scalars['String']>;
  sleepDuration?: Maybe<Scalars['String']>;
  sunExposure?: Maybe<Scalars['String']>;
  smokingHabit?: Maybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  customerId?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  amount_paid?: Maybe<Scalars['Float']>;
  customer_shipping?: Maybe<Customer_Shipping>;
  number?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamp']>;
  subscription?: Maybe<Scalars['String']>;
};

export type Charge = {
  __typename?: 'Charge';
  customerId?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  amount_paid?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['timestamp']>;
};

export type Lifestyle = {
  __typename?: 'Lifestyle';
  diet: Array<Scalars['String']>;
  foodsAvoided?: Maybe<Array<Maybe<Scalars['String']>>>;
  beverages?: Maybe<Array<Maybe<Scalars['String']>>>;
  willingToTakeSupplements?: Maybe<Scalars['String']>;
  currentlyTakingSupplements?: Maybe<Scalars['String']>;
  currentSupplements?: Maybe<Array<Maybe<Scalars['String']>>>;
  currentExercise?: Maybe<Array<Maybe<Scalars['String']>>>;
  exerciseFrequency?: Maybe<Scalars['String']>;
  sleepDuration?: Maybe<Scalars['String']>;
  sunExposure?: Maybe<Scalars['String']>;
  smokingHabit?: Maybe<Scalars['String']>;
};

export type MaleBounds = {
  __typename?: 'MaleBounds';
  _18?: Maybe<Bound>;
  _30?: Maybe<Bound>;
  _40?: Maybe<Bound>;
  _50?: Maybe<Bound>;
  _60?: Maybe<Bound>;
  _150?: Maybe<Bound>;
};

export type Metadata = {
  __typename?: 'Metadata';
  current_order_status?: Maybe<Scalars['String']>;
  order_received_date?: Maybe<Scalars['timestamp']>;
  kit_dispatched_date?: Maybe<Scalars['timestamp']>;
  lab_processing_date?: Maybe<Scalars['timestamp']>;
  results_ready_date?: Maybe<Scalars['timestamp']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateSettings: User;
  updateLifestyle: User;
  updateProfile: User;
};


export type MutationUpdateSettingsArgs = {
  firebaseUID?: Maybe<Scalars['String']>;
  settings?: Maybe<InputDarkMode>;
};


export type MutationUpdateLifestyleArgs = {
  firebaseUID?: Maybe<Scalars['String']>;
  lifestyle?: Maybe<InputLifestyle>;
  goals?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateProfileArgs = {
  firebaseUID?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
};

export type Plan = {
  __typename?: 'Plan';
  interval?: Maybe<Scalars['String']>;
  interval_count?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type Product = {
  __typename?: 'Product';
  title: Scalars['String'];
  currency: Scalars['String'];
  amount: Scalars['Float'];
  discountPercentage: Scalars['Float'];
  period?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  link: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getUser: User;
  getPerformanceScores: Array<Score>;
  getPerformanceScores2: Array<Score>;
  getTestResults: Array<TestResult>;
  getBiomarkers: Array<Biomarker>;
  getActions2: Array<Action>;
  getActions: Array<Action2>;
  getAllInvoices?: Maybe<Array<Maybe<Invoice>>>;
  getSubscription?: Maybe<Subscription>;
  getCustomer: Customer;
  startBillingSession: BillingPortal;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  getDemoActions: Array<Action>;
  getDemoTestResults: Array<TestResult>;
  getDemoPerformanceScores: Array<Score>;
};


export type QueryGetUserArgs = {
  firebaseUID?: Maybe<Scalars['String']>;
};


export type QueryGetPerformanceScoresArgs = {
  firebaseUID?: Maybe<Scalars['String']>;
};


export type QueryGetPerformanceScores2Args = {
  firebaseUID?: Maybe<Scalars['String']>;
};


export type QueryGetTestResultsArgs = {
  firebaseUID?: Maybe<Scalars['String']>;
};


export type QueryGetActionsArgs = {
  firebaseUID?: Maybe<Scalars['String']>;
};


export type QueryGetAllInvoicesArgs = {
  customerId?: Maybe<Scalars['String']>;
};

export type QueryGetAllChargesArgs = {
  customerId?: Maybe<Scalars['String']>;
};


export type QueryGetSubscriptionArgs = {
  customerId?: Maybe<Scalars['String']>;
};


export type QueryGetCustomerArgs = {
  customerId?: Maybe<Scalars['String']>;
};


export type QueryStartBillingSessionArgs = {
  customerId?: Maybe<Scalars['String']>;
};

export type QueryStartPaymentSessionArgs = {
  customerId?: Maybe<Scalars['String']>;
};

export type Score = {
  __typename?: 'Score';
  id: Scalars['ID'];
  firebaseUID: Scalars['String'];
  testDate: Scalars['DateTime'];
  wellnessScore?: Maybe<Scalars['Int']>;
  sleep: Scalars['Int'];
  energy: Scalars['Int'];
  fitness: Scalars['Int'];
  metabolism: Scalars['Int'];
  cognition: Scalars['Int'];
  mood: Scalars['Int'];
  immunity: Scalars['Int'];
};

export type Settings = {
  __typename?: 'Settings';
  darkMode?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  plan?: Maybe<Plan>;
  current_period_end?: Maybe<Scalars['timestamp']>;
};

export type TestResult = {
  __typename?: 'TestResult';
  id: Scalars['ID'];
  firebaseUID: Scalars['String'];
  testDate: Scalars['DateTime'];
  biomarkerRef1?: Maybe<Scalars['Float']>;
  biomarkerRef2?: Maybe<Scalars['Float']>;
  biomarkerRef3?: Maybe<Scalars['Float']>;
  biomarkerRef4?: Maybe<Scalars['Float']>;
  biomarkerRef5?: Maybe<Scalars['Float']>;
  biomarkerRef6?: Maybe<Scalars['Float']>;
  biomarkerRef7?: Maybe<Scalars['Float']>;
  biomarkerRef8?: Maybe<Scalars['Float']>;
  biomarkerRef9?: Maybe<Scalars['Float']>;
  biomarkerRef10?: Maybe<Scalars['Float']>;
  biomarkerRef11?: Maybe<Scalars['Float']>;
  biomarkerRef12?: Maybe<Scalars['Float']>;
  biomarkerRef13?: Maybe<Scalars['Float']>;
  biomarkerRef14?: Maybe<Scalars['Float']>;
  biomarkerRef15?: Maybe<Scalars['Float']>;
  biomarkerRef16?: Maybe<Scalars['Float']>;
  biomarkerRef17?: Maybe<Scalars['Float']>;
  biomarkerRef18?: Maybe<Scalars['Float']>;
  biomarkerRef19?: Maybe<Scalars['Float']>;
  biomarkerRef20?: Maybe<Scalars['Float']>;
  biomarkerRef21?: Maybe<Scalars['Float']>;
  biomarkerRef22?: Maybe<Scalars['Float']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firebaseUID: Scalars['String'];
  stripeCustomerID: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender: Scalars['String'];
  dob: Scalars['DateTime'];
  phone: Scalars['String'];
  goals: Array<Scalars['String']>;
  lifestyle: Lifestyle;
  address: Address;
  subscriptionStatus: Scalars['String'];
  programCycleLength: Scalars['String'];
  memberSince: Scalars['DateTime'];
  referralCode?: Maybe<Scalars['String']>;
  creditBalance?: Maybe<Scalars['String']>;
  settings: Settings;
  demoUser: Scalars['Boolean'];
};


export type UpdateLifestyleMutationVariables = Exact<{
  firebaseUID?: Maybe<Scalars['String']>;
  lifestyle?: Maybe<InputLifestyle>;
  goals?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type UpdateLifestyleMutation = (
  { __typename?: 'Mutation' }
  & {
    updateLifestyle: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firebaseUID' | 'goals'>
      & {
        lifestyle: (
          { __typename?: 'Lifestyle' }
          & Pick<Lifestyle, 'diet' | 'foodsAvoided' | 'beverages' | 'willingToTakeSupplements' | 'currentSupplements' | 'currentExercise' | 'exerciseFrequency' | 'sleepDuration' | 'sunExposure' | 'smokingHabit'>
        )
      }
    )
  }
);

export type UpdateProfileMutationVariables = Exact<{
  firebaseUID?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & {
    updateProfile: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firebaseUID' | 'firstName' | 'lastName' | 'gender' | 'email' | 'phone' | 'dob'>
    )
  }
);

export type GetActionsQueryVariables = Exact<{
  firebaseUID?: Maybe<Scalars['String']>;
}>;


export type GetActionsQuery = (
  { __typename?: 'Query' }
  & {
    getActions: Array<(
      { __typename?: 'Action2' }
      & Pick<Action2, 'id' | 'testDate' | 'actionRef' | 'actionTitle' | 'actionWhy' | 'actionUsage' | 'frequency' | 'category' | 'impactRanking' | 'performanceAreas' | 'biomarkersBenefited' | 'medicalReferences'>
      & {
        product: (
          { __typename?: 'Product' }
          & Pick<Product, 'title' | 'currency' | 'amount' | 'discountPercentage' | 'description' | 'link'>
        )
      }
    )>
  }
);

export type GetAllInvoicesQueryVariables = Exact<{
  customerId?: Maybe<Scalars['String']>;
}>;

export type GetAllChargesQueryVariables = Exact<{
  customerId?: Maybe<Scalars['String']>;
}>;


export type GetAllInvoicesQuery = (
  { __typename?: 'Query' }
  & {
    getAllInvoices?: Maybe<Array<Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'customerId' | 'customer' | 'currency' | 'amount_paid' | 'number' | 'created' | 'subscription'>
      & {
        customer_shipping?: Maybe<(
          { __typename?: 'Customer_Shipping' }
          & Pick<Customer_Shipping, 'line1' | 'line2' | 'city' | 'state' | 'country' | 'postal_code'>
        )>
      }
    )>>>
  }
);

export type GetAllChargesQuery = (
  { __typename?: 'Query' }
  & {
    getAllCharges?: Maybe<Array<Maybe<(
      { __typename?: 'Charge' }
      & Pick<Charge, 'customerId' | 'customer' | 'currency' | 'amount_paid' | 'created'>

    )>>>
  }
);

export type GetCustomerQueryVariables = Exact<{
  customerId?: Maybe<Scalars['String']>;
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & {
    getCustomer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'balance' | 'currency'>
      & {
        metadata?: Maybe<(
          { __typename?: 'Metadata' }
          & Pick<Metadata, 'current_order_status' | 'order_received_date' | 'kit_dispatched_date' | 'lab_processing_date' | 'results_ready_date'>
        )>
      }
    )
  }
);

export type GetDemoActionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDemoActionsQuery = (
  { __typename?: 'Query' }
  & {
    getDemoActions: Array<(
      { __typename?: 'Action' }
      & Pick<Action, 'id' | 'testDate' | 'actionRef' | 'actionTitle' | 'actionWhy' | 'actionUsage' | 'frequency' | 'category' | 'impactRanking' | 'performanceAreas' | 'biomarkersBenefited' | 'medicalReferences'>
      & {
        product: (
          { __typename?: 'Product' }
          & Pick<Product, 'title' | 'currency' | 'amount' | 'discountPercentage' | 'description' | 'link'>
        )
      }
    )>
  }
);

export type GetDemoPerformanceScoresQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDemoPerformanceScoresQuery = (
  { __typename?: 'Query' }
  & {
    getDemoPerformanceScores: Array<(
      { __typename?: 'Score' }
      & Pick<Score, 'id' | 'testDate' | 'firebaseUID' | 'wellnessScore' | 'sleep' | 'energy' | 'fitness' | 'metabolism' | 'cognition' | 'mood' | 'immunity'>
    )>
  }
);

export type GetDemoTestResultsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDemoTestResultsQuery = (
  { __typename?: 'Query' }
  & {
    getDemoTestResults: Array<(
      { __typename?: 'TestResult' }
      & Pick<TestResult, 'id' | 'testDate' | 'firebaseUID' | 'biomarkerRef1' | 'biomarkerRef10' | 'biomarkerRef11' | 'biomarkerRef12' | 'biomarkerRef13' | 'biomarkerRef14' | 'biomarkerRef15' | 'biomarkerRef16' | 'biomarkerRef17' | 'biomarkerRef18' | 'biomarkerRef19' | 'biomarkerRef2' | 'biomarkerRef20' | 'biomarkerRef21' | 'biomarkerRef22' | 'biomarkerRef3' | 'biomarkerRef4' | 'biomarkerRef5' | 'biomarkerRef6' | 'biomarkerRef7' | 'biomarkerRef8' | 'biomarkerRef9'>
    )>
  }
);

export type GetPerformanceScoresQueryVariables = Exact<{
  firebaseUID?: Maybe<Scalars['String']>;
}>;


export type GetPerformanceScoresQuery = (
  { __typename?: 'Query' }
  & {
    getPerformanceScores: Array<(
      { __typename?: 'Score' }
      & Pick<Score, 'id' | 'testDate' | 'firebaseUID' | 'wellnessScore' | 'sleep' | 'energy' | 'fitness' | 'metabolism' | 'cognition' | 'mood' | 'immunity'>
    )>
  }
);

export type GetSubscriptionQueryVariables = Exact<{
  customerId?: Maybe<Scalars['String']>;
}>;


export type GetSubscriptionQuery = (
  { __typename?: 'Query' }
  & {
    getSubscription?: Maybe<(
      { __typename?: 'Subscription' }
      & Pick<Subscription, 'current_period_end'>
      & {
        plan?: Maybe<(
          { __typename?: 'Plan' }
          & Pick<Plan, 'interval' | 'interval_count' | 'active'>
        )>
      }
    )>
  }
);

export type GetTestResultsQueryVariables = Exact<{
  firebaseUID?: Maybe<Scalars['String']>;
}>;


export type GetTestResultsQuery = (
  { __typename?: 'Query' }
  & {
    getTestResults: Array<(
      { __typename?: 'TestResult' }
      & Pick<TestResult, 'id' | 'testDate' | 'firebaseUID' | 'biomarkerRef1' | 'biomarkerRef10' | 'biomarkerRef11' | 'biomarkerRef12' | 'biomarkerRef13' | 'biomarkerRef14' | 'biomarkerRef15' | 'biomarkerRef16' | 'biomarkerRef17' | 'biomarkerRef18' | 'biomarkerRef19' | 'biomarkerRef2' | 'biomarkerRef20' | 'biomarkerRef21' | 'biomarkerRef22' | 'biomarkerRef3' | 'biomarkerRef4' | 'biomarkerRef5' | 'biomarkerRef6' | 'biomarkerRef7' | 'biomarkerRef8' | 'biomarkerRef9'>
    )>
  }
);

export type GetUserQueryVariables = Exact<{
  firebaseUID?: Maybe<Scalars['String']>;
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & {
    getUser: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firebaseUID' | 'stripeCustomerID' | 'email' | 'firstName' | 'lastName' | 'gender' | 'dob' | 'phone' | 'goals' | 'subscriptionStatus' | 'programCycleLength' | 'memberSince' | 'referralCode' | 'creditBalance' | 'demoUser'>
      & {
        lifestyle: (
          { __typename?: 'Lifestyle' }
          & Pick<Lifestyle, 'diet' | 'foodsAvoided' | 'beverages' | 'willingToTakeSupplements' | 'currentSupplements' | 'currentExercise' | 'exerciseFrequency' | 'sleepDuration' | 'sunExposure' | 'smokingHabit'>
        ), address: (
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'city' | 'postCode'>
        ), settings: (
          { __typename?: 'Settings' }
          & Pick<Settings, 'darkMode'>
        )
      }
    )
  }
);

export type StartBillingSessionQueryVariables = Exact<{
  customerId?: Maybe<Scalars['String']>;
}>;

export type StartPaymentSessionQueryVariables = Exact<{
  customerId?: Maybe<Scalars['String']>;
}>;


export type StartBillingSessionQuery = (
  { __typename?: 'Query' }
  & {
    startBillingSession: (
      { __typename?: 'BillingPortal' }
      & Pick<BillingPortal, 'return_url' | 'url'>
    )
  }
);

export type StartPaymentSessionQuery = (
  { __typename?: 'Query' }
  & {
    startPaymentSession: (
      { __typename?: 'PaymentSession' }
      & Pick<PaymentSession, 'return_url' | 'url'>
    )
  }
);


export const UpdateLifestyleDocument = gql`
    mutation UpdateLifestyle($firebaseUID: String, $lifestyle: InputLifestyle, $goals: [String]) {
  updateLifestyle(firebaseUID: $firebaseUID, lifestyle: $lifestyle, goals: $goals) {
    id
    firebaseUID
    lifestyle {
      diet
      foodsAvoided
      beverages
      willingToTakeSupplements
      currentSupplements
      currentExercise
      exerciseFrequency
      sleepDuration
      sunExposure
      smokingHabit
    }
    goals
  }
}
    `;
export type UpdateLifestyleMutationFn = Apollo.MutationFunction<UpdateLifestyleMutation, UpdateLifestyleMutationVariables>;

/**
 * __useUpdateLifestyleMutation__
 *
 * To run a mutation, you first call `useUpdateLifestyleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLifestyleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLifestyleMutation, { data, loading, error }] = useUpdateLifestyleMutation({
 *   variables: {
 *      firebaseUID: // value for 'firebaseUID'
 *      lifestyle: // value for 'lifestyle'
 *      goals: // value for 'goals'
 *   },
 * });
 */
export function useUpdateLifestyleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLifestyleMutation, UpdateLifestyleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLifestyleMutation, UpdateLifestyleMutationVariables>(UpdateLifestyleDocument, options);
}
export type UpdateLifestyleMutationHookResult = ReturnType<typeof useUpdateLifestyleMutation>;
export type UpdateLifestyleMutationResult = Apollo.MutationResult<UpdateLifestyleMutation>;
export type UpdateLifestyleMutationOptions = Apollo.BaseMutationOptions<UpdateLifestyleMutation, UpdateLifestyleMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($firebaseUID: String, $firstName: String, $lastName: String, $gender: String, $email: String, $phone: String, $dob: DateTime) {
  updateProfile(
    firebaseUID: $firebaseUID
    firstName: $firstName
    lastName: $lastName
    gender: $gender
    email: $email
    phone: $phone
    dob: $dob
  ) {
    id
    firebaseUID
    firstName
    lastName
    gender
    email
    phone
    dob
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      firebaseUID: // value for 'firebaseUID'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      dob: // value for 'dob'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const GetActionsDocument = gql`
    query GetActions($firebaseUID: String) {
  getActions(firebaseUID: $firebaseUID) {
    id
    testDate
    actionRef
    actionTitle
    actionWhy
    actionUsage
    frequency
    category
    impactRanking
    performanceAreas
    biomarkersBenefited
    product {
      title
      currency
      amount
      discountPercentage
      description
      link
    }
    medicalReferences
  }
}
    `;

/**
 * __useGetActionsQuery__
 *
 * To run a query within a React component, call `useGetActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionsQuery({
 *   variables: {
 *      firebaseUID: // value for 'firebaseUID'
 *   },
 * });
 */
export function useGetActionsQuery(baseOptions?: Apollo.QueryHookOptions<GetActionsQuery, GetActionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetActionsQuery, GetActionsQueryVariables>(GetActionsDocument, options);
}
export function useGetActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionsQuery, GetActionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetActionsQuery, GetActionsQueryVariables>(GetActionsDocument, options);
}
export type GetActionsQueryHookResult = ReturnType<typeof useGetActionsQuery>;
export type GetActionsLazyQueryHookResult = ReturnType<typeof useGetActionsLazyQuery>;
export type GetActionsQueryResult = Apollo.QueryResult<GetActionsQuery, GetActionsQueryVariables>;
export const GetAllInvoicesDocument = gql`
    query GetAllInvoices($customerId: String) {
  getAllInvoices(customerId: $customerId) {
    customerId
    customer
    currency
    amount_paid
    customer_shipping {
      line1
      line2
      city
      state
      country
      postal_code
    }
    number
    created
    subscription
  }
}
    `;

/**
 * __useGetAllInvoicesQuery__
 *
 * To run a query within a React component, call `useGetAllInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvoicesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetAllInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>(GetAllInvoicesDocument, options);
}
export function useGetAllInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>(GetAllInvoicesDocument, options);
}
export type GetAllInvoicesQueryHookResult = ReturnType<typeof useGetAllInvoicesQuery>;
export type GetAllInvoicesLazyQueryHookResult = ReturnType<typeof useGetAllInvoicesLazyQuery>;
export type GetAllInvoicesQueryResult = Apollo.QueryResult<GetAllInvoicesQuery, GetAllInvoicesQueryVariables>;
export const GetCustomerDocument = gql`
    query GetCustomer($customerId: String) {
  getCustomer(customerId: $customerId) {
    balance
    currency
    metadata {
      current_order_status
      order_received_date
      kit_dispatched_date
      lab_processing_date
      results_ready_date
    }
  }
}
    `;


export function useGetAllChargesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllChargesQuery, GetAllChargesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllChargesQuery, GetAllChargesQueryVariables>(GetAllChargesDocument, options);
}
export function useGetAllChargesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllChargesQuery, GetAllChargesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllChargesQuery, GetAllChargesQueryVariables>(GetAllChargesDocument, options);
}
export type GetAllChargesQueryHookResult = ReturnType<typeof useGetAllInvoicesQuery>;
export type GetAllChargesLazyQueryHookResult = ReturnType<typeof useGetAllInvoicesLazyQuery>;
export type GetAllChargesQueryResult = Apollo.QueryResult<GetAllChargesQuery, GetAllChargesQueryVariables>;

export const GetAllChargesDocument = gql`
    query GetAllCharges($customerId: String) {
  getAllCharges(customerId: $customerId) {
    customerId
    customer
    currency
    amount_paid
    created
  }
}
    `;
/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
}
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetDemoActionsDocument = gql`
    query GetDemoActions {
  getDemoActions {
    id
    testDate
    actionRef
    actionTitle
    actionWhy
    actionUsage
    frequency
    category
    impactRanking
    performanceAreas
    biomarkersBenefited
    product {
      title
      currency
      amount
      discountPercentage
      description
      link
    }
    medicalReferences
  }
}
    `;

/**
 * __useGetDemoActionsQuery__
 *
 * To run a query within a React component, call `useGetDemoActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDemoActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDemoActionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDemoActionsQuery(baseOptions?: Apollo.QueryHookOptions<GetDemoActionsQuery, GetDemoActionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDemoActionsQuery, GetDemoActionsQueryVariables>(GetDemoActionsDocument, options);
}
export function useGetDemoActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDemoActionsQuery, GetDemoActionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDemoActionsQuery, GetDemoActionsQueryVariables>(GetDemoActionsDocument, options);
}
export type GetDemoActionsQueryHookResult = ReturnType<typeof useGetDemoActionsQuery>;
export type GetDemoActionsLazyQueryHookResult = ReturnType<typeof useGetDemoActionsLazyQuery>;
export type GetDemoActionsQueryResult = Apollo.QueryResult<GetDemoActionsQuery, GetDemoActionsQueryVariables>;
export const GetDemoPerformanceScoresDocument = gql`
    query GetDemoPerformanceScores {
  getDemoPerformanceScores {
    id
    testDate
    firebaseUID
    wellnessScore
    sleep
    energy
    fitness
    metabolism
    cognition
    mood
    immunity
  }
}
    `;

/**
 * __useGetDemoPerformanceScoresQuery__
 *
 * To run a query within a React component, call `useGetDemoPerformanceScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDemoPerformanceScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDemoPerformanceScoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDemoPerformanceScoresQuery(baseOptions?: Apollo.QueryHookOptions<GetDemoPerformanceScoresQuery, GetDemoPerformanceScoresQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDemoPerformanceScoresQuery, GetDemoPerformanceScoresQueryVariables>(GetDemoPerformanceScoresDocument, options);
}
export function useGetDemoPerformanceScoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDemoPerformanceScoresQuery, GetDemoPerformanceScoresQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDemoPerformanceScoresQuery, GetDemoPerformanceScoresQueryVariables>(GetDemoPerformanceScoresDocument, options);
}
export type GetDemoPerformanceScoresQueryHookResult = ReturnType<typeof useGetDemoPerformanceScoresQuery>;
export type GetDemoPerformanceScoresLazyQueryHookResult = ReturnType<typeof useGetDemoPerformanceScoresLazyQuery>;
export type GetDemoPerformanceScoresQueryResult = Apollo.QueryResult<GetDemoPerformanceScoresQuery, GetDemoPerformanceScoresQueryVariables>;
export const GetDemoTestResultsDocument = gql`
    query GetDemoTestResults {
  getDemoTestResults {
    id
    testDate
    firebaseUID
    biomarkerRef1
    biomarkerRef10
    biomarkerRef11
    biomarkerRef12
    biomarkerRef13
    biomarkerRef14
    biomarkerRef15
    biomarkerRef16
    biomarkerRef17
    biomarkerRef18
    biomarkerRef19
    biomarkerRef2
    biomarkerRef20
    biomarkerRef21
    biomarkerRef22
    biomarkerRef3
    biomarkerRef4
    biomarkerRef5
    biomarkerRef6
    biomarkerRef7
    biomarkerRef8
    biomarkerRef9
  }
}
    `;

/**
 * __useGetDemoTestResultsQuery__
 *
 * To run a query within a React component, call `useGetDemoTestResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDemoTestResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDemoTestResultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDemoTestResultsQuery(baseOptions?: Apollo.QueryHookOptions<GetDemoTestResultsQuery, GetDemoTestResultsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDemoTestResultsQuery, GetDemoTestResultsQueryVariables>(GetDemoTestResultsDocument, options);
}
export function useGetDemoTestResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDemoTestResultsQuery, GetDemoTestResultsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDemoTestResultsQuery, GetDemoTestResultsQueryVariables>(GetDemoTestResultsDocument, options);
}
export type GetDemoTestResultsQueryHookResult = ReturnType<typeof useGetDemoTestResultsQuery>;
export type GetDemoTestResultsLazyQueryHookResult = ReturnType<typeof useGetDemoTestResultsLazyQuery>;
export type GetDemoTestResultsQueryResult = Apollo.QueryResult<GetDemoTestResultsQuery, GetDemoTestResultsQueryVariables>;
export const GetPerformanceScoresDocument = gql`
    query GetPerformanceScores($firebaseUID: String) {
  getPerformanceScores(firebaseUID: $firebaseUID) {
    id
    testDate
    firebaseUID
    wellnessScore
    sleep
    energy
    fitness
    metabolism
    cognition
    mood
    immunity
  }
}
    `;

/**
 * __useGetPerformanceScoresQuery__
 *
 * To run a query within a React component, call `useGetPerformanceScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerformanceScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerformanceScoresQuery({
 *   variables: {
 *      firebaseUID: // value for 'firebaseUID'
 *   },
 * });
 */
export function useGetPerformanceScoresQuery(baseOptions?: Apollo.QueryHookOptions<GetPerformanceScoresQuery, GetPerformanceScoresQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPerformanceScoresQuery, GetPerformanceScoresQueryVariables>(GetPerformanceScoresDocument, options);
}
export function useGetPerformanceScoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPerformanceScoresQuery, GetPerformanceScoresQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPerformanceScoresQuery, GetPerformanceScoresQueryVariables>(GetPerformanceScoresDocument, options);
}
export type GetPerformanceScoresQueryHookResult = ReturnType<typeof useGetPerformanceScoresQuery>;
export type GetPerformanceScoresLazyQueryHookResult = ReturnType<typeof useGetPerformanceScoresLazyQuery>;
export type GetPerformanceScoresQueryResult = Apollo.QueryResult<GetPerformanceScoresQuery, GetPerformanceScoresQueryVariables>;
export const GetSubscriptionDocument = gql`
    query GetSubscription($customerId: String) {
  getSubscription(customerId: $customerId) {
    plan {
      interval
      interval_count
      active
    }
    current_period_end
  }
}
    `;

/**
 * __useGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
}
export function useGetSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
}
export type GetSubscriptionQueryHookResult = ReturnType<typeof useGetSubscriptionQuery>;
export type GetSubscriptionLazyQueryHookResult = ReturnType<typeof useGetSubscriptionLazyQuery>;
export type GetSubscriptionQueryResult = Apollo.QueryResult<GetSubscriptionQuery, GetSubscriptionQueryVariables>;
export const GetTestResultsDocument = gql`
    query GetTestResults($firebaseUID: String) {
  getTestResults(firebaseUID: $firebaseUID) {
    id
    testDate
    firebaseUID
    biomarkerRef1
    biomarkerRef10
    biomarkerRef11
    biomarkerRef12
    biomarkerRef13
    biomarkerRef14
    biomarkerRef15
    biomarkerRef16
    biomarkerRef17
    biomarkerRef18
    biomarkerRef19
    biomarkerRef2
    biomarkerRef20
    biomarkerRef21
    biomarkerRef22
    biomarkerRef3
    biomarkerRef4
    biomarkerRef5
    biomarkerRef6
    biomarkerRef7
    biomarkerRef8
    biomarkerRef9
  }
}
    `;

/**
 * __useGetTestResultsQuery__
 *
 * To run a query within a React component, call `useGetTestResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestResultsQuery({
 *   variables: {
 *      firebaseUID: // value for 'firebaseUID'
 *   },
 * });
 */
export function useGetTestResultsQuery(baseOptions?: Apollo.QueryHookOptions<GetTestResultsQuery, GetTestResultsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTestResultsQuery, GetTestResultsQueryVariables>(GetTestResultsDocument, options);
}
export function useGetTestResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestResultsQuery, GetTestResultsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTestResultsQuery, GetTestResultsQueryVariables>(GetTestResultsDocument, options);
}
export type GetTestResultsQueryHookResult = ReturnType<typeof useGetTestResultsQuery>;
export type GetTestResultsLazyQueryHookResult = ReturnType<typeof useGetTestResultsLazyQuery>;
export type GetTestResultsQueryResult = Apollo.QueryResult<GetTestResultsQuery, GetTestResultsQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($firebaseUID: String) {
  getUser(firebaseUID: $firebaseUID) {
    id
    firebaseUID
    stripeCustomerID
    email
    firstName
    lastName
    gender
    dob
    phone
    goals
    lifestyle {
      diet
      foodsAvoided
      beverages
      willingToTakeSupplements
      currentSupplements
      currentExercise
      exerciseFrequency
      sleepDuration
      sunExposure
      smokingHabit
    }
    address {
      addressLine1
      addressLine2
      city
      postCode
    }
    subscriptionStatus
    programCycleLength
    memberSince
    referralCode
    creditBalance
    settings {
      darkMode
    }
    demoUser
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      firebaseUID: // value for 'firebaseUID'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const StartBillingSessionDocument = gql`
    query StartBillingSession($customerId: String) {
  startBillingSession(customerId: $customerId) {
    return_url
    url
  }
}
    `;
export const StartPaymentSessionDocument = gql`
     query StartPaymentSession($customerId: String) {
  startPaymentSession(customerId: $customerId) {
    return_url
    url
  }
}
    `;

/**
 * __useStartBillingSessionQuery__
 *
 * To run a query within a React component, call `useStartBillingSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartBillingSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartBillingSessionQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useStartBillingSessionQuery(baseOptions?: Apollo.QueryHookOptions<StartBillingSessionQuery, StartBillingSessionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StartBillingSessionQuery, StartBillingSessionQueryVariables>(StartBillingSessionDocument, options);
}
export function useStartBillingSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StartBillingSessionQuery, StartBillingSessionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StartBillingSessionQuery, StartBillingSessionQueryVariables>(StartBillingSessionDocument, options);
}
export type StartBillingSessionQueryHookResult = ReturnType<typeof useStartBillingSessionQuery>;
export type StartBillingSessionLazyQueryHookResult = ReturnType<typeof useStartBillingSessionLazyQuery>;
export type StartBillingSessionQueryResult = Apollo.QueryResult<StartBillingSessionQuery, StartBillingSessionQueryVariables>;



export function useStartPaymentSessionQuery(baseOptions?: Apollo.QueryHookOptions<StartPaymentSessionQuery, StartPaymentSessionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StartPaymentSessionQuery, StartPaymentSessionQueryVariables>(StartPaymentSessionDocument, options);
}
export function useStartPaymentSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StartPaymentSessionQuery, StartPaymentSessionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StartPaymentSessionQuery, StartPaymentSessionQueryVariables>(StartPaymentSessionDocument, options);
}
export type StartPaymentSessionQueryHookResult = ReturnType<typeof useStartPaymentSessionQuery>;
export type StartPaymentSessionLazyQueryHookResult = ReturnType<typeof useStartPaymentSessionLazyQuery>;
export type StartPaymentSessionQueryResult = Apollo.QueryResult<StartPaymentSessionQuery, StartPaymentSessionQueryVariables>;