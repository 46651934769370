import { Button, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import Loading from 'components/Loading';
import StyledPaper from 'components/StyledPaper';
import UserAvatar from 'components/UserAvatar';
import { ROUTE } from 'config/routes';
import { format } from 'date-fns';
import { useGetUserQuery } from 'generated/graphql';
import { TAB } from 'pages/Profile/Profile';
import { CalendarBlank, User } from 'phosphor-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { FirebaseReducer } from 'react-redux-firebase';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { IRootState } from 'reducers';
import { capitalize } from 'utils/string';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: 144
    },
    container: {
      height: 'calc(100% + 16px)'
    },
    icon: {
      height: 16,
      width: 16,
      marginRight: theme.spacing(1),
      verticalAlign: 'middle',
      color: theme.palette.grey[500]
    },
    username: {
      marginBottom: theme.spacing(1)
    },
    userInfo: {
      fontWeight: 'normal',
      marginBottom: 4
    },
    button: {
      width: 126,
      height: 38
    }
  })
);

const ProfileCard: React.FC = () => {
  const classes = useStyles();
  const auth = useSelector<IRootState, FirebaseReducer.AuthState>(state => state.firebase.auth);

  const { data: userData } = useGetUserQuery({
    variables: {
      firebaseUID: auth.uid
    }
  });

  if (userData) {
    const user = userData.getUser;

    return (
      <StyledPaper elevation={0} className={classes.root}>
        <Grid container direction="row" alignContent="center" alignItems="center" spacing={2}
              className={classes.container}>
          <Grid item>
            <UserAvatar firstName={user.firstName} lastName={user.lastName} uid={user.firebaseUID} size="medium"/>
          </Grid>
          <Grid item xs container>
            <Grid item>
              <Typography variant="h3" component="h2"
                          className={classes.username}>{user.firstName} {user.lastName}</Typography>
            </Grid>
            <Grid item container direction="row" justify="space-between" alignItems="flex-end">
              <Grid item xs>
                <div className={classes.userInfo}>
                  <CalendarBlank className={classes.icon}/>{format(new Date(user.dob), 'dd MMM yyyy')}
                </div>
                <div className={classes.userInfo}><User className={classes.icon}/>{capitalize(user.gender)}</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              component={RouterLink}
              to={generatePath(ROUTE.PROFILE_TAB, { tab: TAB.PROFILE })}
            >
              Profile details
            </Button>
          </Grid>
        </Grid>
      </StyledPaper>
    );
  } else return (
    <StyledPaper elevation={0} className={classes.root}>
      <Loading/>
    </StyledPaper>
  );
};

export default ProfileCard;