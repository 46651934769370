import { Box, createStyles, Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import { ApexOptions } from 'apexcharts';
import { format } from 'date-fns';
import { TestResult, useGetTestResultsQuery } from 'generated/graphql';
import Biomarker from 'models/Biomarker';
import React, { useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getIntervalPosition, getQualityColor, ResultInterval } from 'utils/biomarkersUtils';
import { parse } from 'utils/testDateUtils';

const useStyles = makeStyles<Theme, Array<number>>(theme => {
  const dottedLine = `linear-gradient(to right, ${theme.palette.divider}, ${theme.palette.divider} 50%, transparent 50%, transparent 100%)`;

  return createStyles({
    root: {
      position: 'relative',
      height: 'calc(100vh - 297px)',
      maxHeight: 500,
      [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 282px)'
      },
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 362px)'
      }
    },
    grid: {
      position: 'absolute',
      top: 0,
      left: 40,
      right: 25,
      marginTop: 30,
      boxSizing: 'content-box',
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundImage: (props) => props.map(() => dottedLine).join(', '),
      backgroundPositionY: (props) => props.map((pos) => `${100 - pos}%`).join(', '),
      backgroundSize: '16px 1px',
      backgroundRepeat: 'repeat-x',
      [theme.breakpoints.down('sm')]: {
        right: 10
      }
    },
    chartGrid: {
      height: '100%',
      flexWrap: 'nowrap'
    },
    chart: {
      width: '100%',
      height: '100%',
      '& .apexcharts-svg': {
        overflow: 'visible'
      }
    },
    chartBlock: {
      marginLeft: 25,
      marginRight: 20,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 12,
        marginRight: 10
      }
    },
    chartInfo: {
      marginTop: 30,
      width: 40,
      flexWrap: 'nowrap'
    },
    bar: {
      width: 8,
      marginBottom: -2,
      marginTop: -2
    },
    labels: {
      marginRight: 5,
      lineHeight: 0,
      textAlign: 'right'
    },
    yLabel: {
      position: 'relative'
    },
    yZeroContainer: {
      height: 0
    },
    interval: {
      marginBottom: 2,
      marginTop: 2,
      borderRadius: 2
    }
  });
});

export interface TestResultChartProps {
  intervals: Array<ResultInterval>
  testResult: TestResult,
  biomarker: Biomarker
}

const TestResultChart: React.FC<TestResultChartProps> = ({ intervals, testResult, biomarker }) => {
  const theme = useTheme();
  const intervalPositions = intervals.map(i => getIntervalPosition(i, intervals));
  const classes = useStyles(intervalPositions);
  const gridRef = useRef<HTMLDivElement>(null);
  const yAxisRef = useRef<HTMLDivElement>(null);

  const adjustPositions = (chartContext: any) => {
    const top = chartContext.w.globals.translateY;
    const height = chartContext.w.globals.gridHeight;
    if (gridRef.current) {
      gridRef.current.style.marginTop = `${top}px`;
      gridRef.current.style.height = `${height}px`;
    }
    if (yAxisRef.current) {
      yAxisRef.current.style.marginTop = `${top}px`;
      yAxisRef.current.style.height = `${height}px`;
    }
  };

  const { data: testResultData } = useGetTestResultsQuery({
    variables: {
      firebaseUID: testResult.firebaseUID
    }
  });

  if (testResultData) {
    const testResults = parse(testResultData.getTestResults).sort((a, b) => a.testDate - b.testDate);

    const chartState: { series: ApexAxisChartSeries, options: ApexOptions } = {
      series: [{
        name: biomarker.name,
        data: testResults.map(result => result[biomarker.biomarkerRef])
      }],
      options: {
        chart: {
          background: 'inherit',
          fontFamily: theme.typography.fontFamily,
          events: {
            updated: adjustPositions,
            mounted: adjustPositions
          },
          id: `test-result-${biomarker.id}-chart`,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        colors: ['#41B0F6'],
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 0.9,
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 1,
            opacityFrom: 0.4,
            opacityTo: 0
          }
        },
        grid: {
          show: false
        },
        labels: testResults.map(result => format(result.testDate, 'd MMM yy')),
        legend: {
          show: false
        },
        markers: {
          shape: 'circle',
          size: 6,
          strokeWidth: 0,
          hover: {
            size: 8
          }
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
          dashArray: 0
        },
        theme: {
          mode: theme.palette.type
        },
        tooltip: {
          y: {
            formatter: (v: number) => `${v} ${biomarker.unit}`
          }
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            style: {
              fontWeight: 500,
              fontSize: theme.typography.body2.fontSize as string
            }
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          show: false,
          min: 0,
          max: intervals[intervals.length - 1].upper
        }
      }
    };

    return <div className={classes.root}>
      <div className={classes.grid} ref={gridRef}/>
      <Grid container className={classes.chartGrid}>
        <Grid item container className={classes.chartInfo} ref={yAxisRef} justify="flex-end">
          <Grid item container direction="column-reverse" className={classes.labels}>
            <Grid item className={classes.yZeroContainer}>
              <Box component="span" className={classes.yLabel}>0</Box>
            </Grid>
            {intervals.map(interval =>
              <Grid item key={interval.quality} style={{
                flexGrow: interval.upper - interval.lower
              }}>
                <Box component="span" className={classes.yLabel}>{interval.upper}</Box>
              </Grid>
            )}
          </Grid>
          <Grid item container className={classes.bar} direction="column-reverse">
            {intervals.map(interval =>
              <Grid item key={interval.quality} className={classes.interval} style={{
                flexGrow: interval.upper - interval.lower,
                backgroundColor: getQualityColor(interval.quality)
              }}/>
            )}
          </Grid>
        </Grid>
        <Grid item xs className={classes.chartBlock}>
          <div className={classes.chart}>
            <ReactApexChart options={chartState.options} series={chartState.series} type="area" height="100%"/>
          </div>
        </Grid>
      </Grid>
    </div>;
  }

  return null;
};

export default TestResultChart;