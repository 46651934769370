import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export interface DemoMessageProps {
  title: string
  body1: string
  body2: string
  button1: JSX.Element,
  button2: JSX.Element
}

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    maxWidth: 520,
    textAlign: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5)
    }
  }
}));

const DemoMessage: React.FC<DemoMessageProps> = ({ title, body1, body2, button1, button2 }) => {
  const classes = useStyles();

  return <Grid
    container
    direction="column"
    alignItems="center"
    spacing={3}
    className={classes.root}>
    <Grid item>
      <Typography variant="h2">{title}</Typography>
    </Grid>
    <Grid item>
      <Typography variant="h4" component="div">{body1}</Typography>
    </Grid>
    <Grid item>
      <Typography variant="h4" component="div">{body2}</Typography>
    </Grid>
    <Grid item container spacing={2} justify="space-around">
      <Grid item xs={12} sm="auto">
        {button1}
      </Grid>
      <Grid item xs={12} sm="auto">
        {button2}
      </Grid>
    </Grid>
  </Grid>;
};

export default DemoMessage;