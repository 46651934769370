import { Box, BoxProps, useTheme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 500,
    display: 'inline-flex',
    alignItems: 'center',
    padding: '7px 20px',
    borderRadius: 6,
    '& svg': {
      fontSize: '1em',
      marginRight: theme.spacing(0.5)
    }
  }
}));

export interface ColoredBadgeProps extends BoxProps {
  color: 'error' | 'warning' | 'success' | 'orange',
  icon?: React.ReactNode
}

const ColoredBadge: React.FC<ColoredBadgeProps> = ({ color, icon, children, ...other }) => {
  const theme = useTheme();
  const classes = useStyles();

  return <Box
    component="span"
    className={classes.root}
    color={theme.palette[color].contrastText}
    bgcolor={theme.palette[color].main}
    {...other}
  >{icon}{children}</Box>;
};

export default ColoredBadge;