import firebase from 'firebase/app';
import React, { useState } from 'react';

export interface CachedLinks {
  [key: string]: string | undefined | null
}

export interface IFirebaseStorageContext {
  values: CachedLinks,
  getRef: typeof getRef,
  put: (ref: string, data: Blob | Uint8Array | ArrayBuffer) => Promise<void>,
  getDownloadURL: (ref: string) => Promise<void>
  deleteRef: (ref: string) => Promise<void>
}

const resolvers = new Map<string, Promise<string>>();

export const getDownloadURL = (ref: string) => {
  if (!resolvers.has(ref)) {
    resolvers.set(ref, firebase.storage().ref(ref).getDownloadURL() as Promise<string>);
  }
  return resolvers.get(ref) as Promise<string>;
};
const put = (ref: string, data: Blob | Uint8Array | ArrayBuffer) => firebase.storage().ref(ref).put(data);
const deleteRef = (ref: string) => firebase.storage().ref(ref).delete();
const getRef = (ref: string) => firebase.storage().ref(ref);

const FirebaseStorageContext = React.createContext<IFirebaseStorageContext>({} as IFirebaseStorageContext);

export const FirebaseStorageProvider: React.FC = ({ children }) => {
  const [cachedLinks, setCachedLinks] = useState<CachedLinks>({});

  const _getDownloadURL = (ref: string) => getDownloadURL(ref).then(
    link => setCachedLinks({ ...cachedLinks, [ref]: link }),
    () => setCachedLinks({ ...cachedLinks, [ref]: null })
  );

  const _put = (ref: string, data: Blob | Uint8Array | ArrayBuffer) => put(ref, data)
    .then(task => {
      const resolver = task.ref.getDownloadURL() as Promise<string>;
      resolvers.set(ref, resolver);
      _getDownloadURL(ref);
    });

  const _deleteRef = (ref: string) => deleteRef(ref)
    .then(() => {
      resolvers.delete(ref);
      setCachedLinks({ ...cachedLinks, [ref]: null });
    });

  return <FirebaseStorageContext.Provider
    value={{
      values: cachedLinks,
      deleteRef: _deleteRef,
      getRef,
      getDownloadURL: _getDownloadURL,
      put: _put
    }}>{children}</FirebaseStorageContext.Provider>;
};

export default FirebaseStorageContext;