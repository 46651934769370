import { CardContent as MuiCardContent, withStyles } from '@material-ui/core';

const CardContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  }
}))(MuiCardContent);

export default CardContent;