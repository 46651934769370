import { makeStyles } from '@material-ui/core/styles';
import { PAGE } from 'actions/demo';
import withUser, { WithUserProps } from 'hocs/withUser';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#FF8A8A',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 600,
    color: theme.palette.common.black,
    textAlign: 'center',
    fontSize: 13,
    padding: '3px',
    [theme.breakpoints.up('md')]: {
      marginLeft: -40,
      marginRight: -20,
      marginTop: -30,
      marginBottom: 30
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: -20,
      marginTop: -20,
      marginBottom: 20
    }
  }
}));

interface DemoBannerProps {
  page: PAGE;
}

const DemoBanner: React.FC<DemoBannerProps & WithUserProps> = ({ user, page }) => {
  const classes = useStyles();
  const isVisible = useSelector((state: IRootState) => state.demo[page]);

  if (user.demoUser && isVisible) {
    return <div className={classes.root}>
      Demo account data - not your results
    </div>;
  } else
    return null;
};

export default withUser(DemoBanner);