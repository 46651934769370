import React, { FC, useContext, useState } from 'react';
import { Snackbar as Snack } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import notificationsContext, { NotificationsContext } from 'contexts/notificationsContext';

type Props = {
  message: string | React.ReactNode;
  severity?: Color;
};

const Snackbar: FC<Props> = ({message, severity = 'error'}) => {
  const [show, setSnackbar] = useState(true);
  const context = useContext<NotificationsContext>(notificationsContext);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
    context.clear();
  };
  return (
    <Snack open={show} autoHideDuration={10000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snack>
  );
};

export default Snackbar;
