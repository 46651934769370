import { firebaseReducer, FirebaseReducer } from 'react-redux-firebase';
import demo, { DemoState } from 'reducers/demo';
import userPreferences, { UserPreferencesState } from 'reducers/userPreferences';
import { combineReducers } from 'redux';

export type IRootState = {
  demo: DemoState
  firebase: FirebaseReducer.Reducer
  userPreferences: UserPreferencesState
};

const rootReducer = combineReducers<IRootState>({
  demo,
  firebase: firebaseReducer,
  userPreferences
});

export default rootReducer;
