import { Score } from 'generated/graphql';
import { capitalize } from 'utils/string';

export enum SCORE {
  COGNITION = 'cognition',
  ENERGY = 'energy',
  FITNESS = 'fitness',
  IMMUNITY = 'immunity',
  METABOLISM = 'metabolism',
  MOOD = 'mood',
  SLEEP = 'sleep'
}

export const goals = {
  [SCORE.SLEEP]: 'Alertness & Sleep Quality',
  [SCORE.ENERGY]: 'Energy',
  [SCORE.FITNESS]: 'Fitness and Strength',
  [SCORE.METABOLISM]: 'Weight Management',
  [SCORE.COGNITION]: 'Mental Clarity & Cognition',
  [SCORE.MOOD]: 'Mood',
  [SCORE.IMMUNITY]: 'Immunity'
};

export const getScoreName = (category: SCORE) => capitalize(category);
export const findScoreByDate = (scores: Array<Score>, date: number) => scores.find(ps => ps.testDate === date);
export const parseScores = (scores: Array<Score>): Array<Score> => scores.map((ps) => ({
  ...ps,
  testDate: Date.parse(ps.testDate || '')
})).sort((a, b) => b.testDate - a.testDate);

export const NEED_IMPROVING = 70;
export const GOOD = 90;