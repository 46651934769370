import { AccordionSummary, withStyles } from '@material-ui/core';

const ElevateAccordionSummary = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  expanded: {},
  disabled: {},
  content: {
    margin: '12px 0',
    [theme.breakpoints.down('md')]: {
      margin: 0
    },
    '&$expanded': {
      margin: '12px 0',
      [theme.breakpoints.down('md')]: {
        margin: 0
      }
    }
  }
}))(AccordionSummary);

export default ElevateAccordionSummary;