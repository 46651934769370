import { Button, createStyles, Grid, Link, makeStyles, Paper, Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator
} from '@material-ui/lab';
import InfoTooltip from 'components/InfoTooltip';
import { useGetCustomerQuery } from 'generated/graphql';
import withUser, { WithUserProps } from 'hocs/withUser';
import React, { useState } from 'react';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import formatCurrency from 'utils/formatCurrency';
import { fbanalytics } from '../../../config/firebase'

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      paddingBottom: theme.spacing(1.5)
    },
    timelinePaper: {
      padding: theme.spacing(3, 4),
      minHeight: 221,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2)
      }
    },
    timeline: {
      margin: 0,
      padding: 0
    },
    timelineItem: {
      minHeight: 44,
      '&:last-child': {
        minHeight: 22
      },
      '&:before': {
        display: 'none'
      }
    },
    timelineDot: {
      width: 22,
      height: 22,
      margin: 0,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      border: 'none',
      background: 'linear-gradient(96.74deg, #41B0F6 0%, #8AD2FF 94.85%)',
      boxShadow: 'none'
    },
    timelineConnector: {
      width: 1,
      backgroundColor: '#41B0F6'
    },
    timelineContent: {
      padding: '2px 16px'
    },
    accountCreditContainer: {
      background: 'url(/assets/account-credit-bg.svg)',
      backgroundSize: 'cover',
      height: 221,
      width: 341,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        height: '100%',
        width: 'auto'
      },
      [theme.breakpoints.down('xs')]: {
        height: 204,
        width: 'auto'
      }
    },
    accountCredit: {
      fontSize: 50,
      fontWeight: 700
    },
    accountCreditHint: {
      fontSize: 14,
      fontWeight: 600
    },
    socialPaperContainer: {
      minWidth: '10%'
    },
    socialLink: {
      '&:hover': {
        textDecoration: 'none'
      }
    },
    socialPaper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      color: theme.palette.grey[500],
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5)
    },
    socialLogo: {
      width: 45,
      height: 45,
      marginBottom: theme.spacing(1)
    },
    referralLinkContainer: {
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      flexWrap: 'nowrap',
      marginTop: theme.spacing(1),
      borderRadius: 10,
      border: `1px solid ${theme.palette.divider}`
    },
    referralLink: {
      overflow: 'hidden',
      alignSelf: 'center'
    },
    referralLinkEl: {
      display: 'block',
      maxWidth: '100%',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    referralLinkButton: {
      height: 38,
      minWidth: 110
    }
  })
);

const getTwitterLink = (code: string) => `twitter://post?message=Check%20out%20ElevateMe!%20Use%20my%20referral%20code%20to%20get%2020%%20off%20your%20first%20health%20test:%20%20${code}%20%20Visit%20www.elevateme.ai`;
const getWhatsappLink = (code: string) => `https://api.whatsapp.com/send?text=Check%20out%20ElevateMe!%20Use%20my%20referral%20code%20to%20get%2020%%20off%20your%20first%20health%20test:%20%20${code}%20%20Visit%20www.elevateme.ai`;
const getIMessageLink = (code: string) => `sms:&body=Check%20out%20ElevateMe!%20Use%20my%20referral%20code%20to%20get%2020%%20off%20your%20first%20health%20test:%20%20${code}%20%20Visit%20www.elevateme.ai`;
const getReferralLink = (code: string) => `${code}`;

const Referrals: React.FC<WithUserProps> = ({ user }) => {
  const classes = useStyles();
  fbanalytics.logEvent('referrals_visit');
  const [copyText, setCopyText] = useState('Copy code');
  const { data } = useGetCustomerQuery({
    variables: {
      customerId: user.stripeCustomerID
    }
  });

  const customer = data?.getCustomer;

  const showCopied = () => {
    setCopyText('Copied');
    setTimeout(() => setCopyText('Copy code'), 2000);
  };

  return <>
    <Typography variant="h3" component="h2" className={classes.title}>
      How it works
      <InfoTooltip
        title="Share your referral code with friends and family to earn credit towards your next ElevateMe health test. " />
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} lg>
        <Paper elevation={0} variant="outlined" className={classes.timelinePaper}>
          <Timeline className={classes.timeline}>
            <TimelineItem className={classes.timelineItem}>
              <TimelineSeparator>
                <TimelineDot className={classes.timelineDot}>1</TimelineDot>
                <TimelineConnector className={classes.timelineConnector} />
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>Introduce Elevate to your friends</TimelineContent>
            </TimelineItem>
            <TimelineItem className={classes.timelineItem}>
              <TimelineSeparator>
                <TimelineDot className={classes.timelineDot}>2</TimelineDot>
                <TimelineConnector className={classes.timelineConnector} />
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                Refer them using one of the sharing options below
              </TimelineContent>
            </TimelineItem>
            <TimelineItem className={classes.timelineItem}>
              <TimelineSeparator>
                <TimelineDot className={classes.timelineDot}>3</TimelineDot>
                <TimelineConnector className={classes.timelineConnector} />
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                Each friend will get 20% off their first order
              </TimelineContent>
            </TimelineItem>
            <TimelineItem className={classes.timelineItem}>
              <TimelineSeparator>
                <TimelineDot className={classes.timelineDot}>4</TimelineDot>
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                You will earn 20% of their order value as credit towards your next test
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} lg="auto">
        <div className={classes.accountCreditContainer}>
          <span className={classes.accountCredit}>{formatCurrency(Math.abs(customer?.balance || 0) / 100)}</span>
          <span className={classes.accountCreditHint}>Your account credit</span>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={2} lg="auto" className={classes.socialPaperContainer}>
            <Link href={getWhatsappLink(user.referralCode as string)} target="_blank" className={classes.socialLink}>
              <Paper elevation={0} variant="outlined" className={classes.socialPaper}>
                <img src="/assets/whatsapp.svg" alt="whatsapp" className={classes.socialLogo} />
                <span>Whatsapp</span>
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={4} sm={2} lg="auto" className={classes.socialPaperContainer}>
            <Link href={getTwitterLink(user.referralCode as string)} target="_blank" className={classes.socialLink}>
              <Paper elevation={0} variant="outlined" className={classes.socialPaper}>
                <img src="/assets/twitter.svg" alt="twitter" className={classes.socialLogo} />
                <span>Twitter</span>
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={4} sm={2} lg="auto" className={classes.socialPaperContainer}>
            <Link href={getIMessageLink(user.referralCode as string)} target="_blank" className={classes.socialLink}>
              <Paper elevation={0} variant="outlined" className={classes.socialPaper}>
                <img src="/assets/wechat.svg" alt="iMessage" className={classes.socialLogo} />
                <span>iMessage</span>
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} lg container justify="center" alignContent="center">
            <Grid item xs={12}>
              <span>Or share your code</span>
            </Grid>
            <Grid item xs={12}>
              <CopyToClipboard text={getReferralLink(user?.referralCode as string)}>
                <Grid container alignContent="center" className={classes.referralLinkContainer}>
                  <Grid item xs className={classes.referralLink}>
                    {/* <Link href={getReferralLink(user.referralCode as string)} target="_blank"
                      className={classes.referralLinkEl}>
                      {getReferralLink(user.referralCode as string)}
                    </Link> */}
                    <Link href="#"
                      className={classes.referralLinkEl}>
                      {getReferralLink(user.referralCode as string)}
                    </Link>
                    {/* <span>{user.referralCode}</span> */}
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" className={classes.referralLinkButton}
                      onClick={showCopied}>
                      {copyText}
                    </Button>
                  </Grid>
                </Grid>
              </CopyToClipboard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>;
};

export default withUser(Referrals);