import * as yup from 'yup';
import 'yup-phone';

const validationSchema = yup.object({
  currentPassword: yup.string().required('Current password is a required field'),
  newPassword: yup
    .string()
    .notOneOf([yup.ref('currentPassword')], 'Password cannot be the same as the current password')
    .required('New password is a required field'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords do not match')
    .required('Confirm new password is a required field')
});

export default validationSchema;