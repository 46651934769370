import { SvgIconProps } from '@material-ui/core/SvgIcon';
import StyledSvgIcon from 'icons/StyledSvgIcon';
import React from 'react';

const BarbellIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox="0 0 16 16" {...props}>
    <path
      d="M5 3.5H3.5C3.22386 3.5 3 3.72386 3 4V12C3 12.2761 3.22386 12.5 3.5 12.5H5C5.27614 12.5 5.5 12.2761 5.5 12V4C5.5 3.72386 5.27614 3.5 5 3.5Z"
      strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M12.5 3.5H11C10.7239 3.5 10.5 3.72386 10.5 4V12C10.5 12.2761 10.7239 12.5 11 12.5H12.5C12.7761 12.5 13 12.2761 13 12V4C13 3.72386 12.7761 3.5 12.5 3.5Z"
      strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M13 5H14.5C14.6326 5 14.7598 5.05268 14.8536 5.14645C14.9473 5.24021 15 5.36739 15 5.5V10.5C15 10.6326 14.9473 10.7598 14.8536 10.8536C14.7598 10.9473 14.6326 11 14.5 11H13"
      strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M3 11H1.5C1.36739 11 1.24021 10.9473 1.14645 10.8536C1.05268 10.7598 1 10.6326 1 10.5V5.5C1 5.36739 1.05268 5.24021 1.14645 5.14645C1.24021 5.05268 1.36739 5 1.5 5H3"
      strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.5 8H10.5" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </StyledSvgIcon>
);

export default BarbellIcon;