import { SvgIconProps } from '@material-ui/core/SvgIcon';
import UIcon from 'icons/uicons/UIcon';
import React from 'react';

const ClockIcon = (props: SvgIconProps) => (
  <UIcon viewBox="0 0 24 24" {...props}>
    <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z"/>
    <path
      d="M12,6a1,1,0,0,0-1,1v4.325L7.629,13.437a1,1,0,0,0,1.062,1.7l3.84-2.4A1,1,0,0,0,13,11.879V7A1,1,0,0,0,12,6Z"/>
  </UIcon>
);

export default ClockIcon;