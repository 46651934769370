import { makeStyles, Paper } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper';
import clsx from 'clsx';
import React from 'react';

type Padding = number | string | Array<number | string>;
export type IStyledPaperProps = {
  padding?: Padding;
  container?: boolean
} & PaperProps;

const useStyles = (paddingProp: Padding) => {
  const [xsPadding, defaultPadding] = Array.isArray(paddingProp)
    ? paddingProp
    : [paddingProp, paddingProp];

  return makeStyles((theme) => {
    return {
      root: {
        padding: defaultPadding,
        [theme.breakpoints.down('xs')]: {
          padding: xsPadding
        }
      },
      container: {
        [theme.breakpoints.down('xs')]: {
          borderRadius: 0
        }
      }
    };
  })();
};

const StyledPaper: React.FC<IStyledPaperProps> = ({
                                                    children,
                                                    padding = [15, '15px 20px'],
                                                    container = false,
                                                    ...props
                                                  }) => {
  const classes = useStyles(padding);

  return (
    <Paper
      classes={{ root: clsx([classes.root, container && classes.container]) }}
      {...props}
    >
      {children}
    </Paper>
  );
};

export default StyledPaper;
