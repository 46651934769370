import { createStyles } from '@material-ui/core';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import { makeStyles } from '@material-ui/core/styles';
import { TabContext } from '@material-ui/lab';
import PageTitle from 'components/PageTitle';
import { Tab, TabPanel, Tabs } from 'components/styled';
import StyledPaper from 'components/StyledPaper';
import ProfileForm from 'pages/Profile/ProfileForm';
import Referrals from 'pages/Profile/Referrals';
import Subscriptions from 'pages/Profile/Subscriptions';
import { ArrowsClockwise, Bicycle, Users, User } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LifestyleForm from './LifestyleForm';

export enum TAB {
  LIFESTYLE = 'lifestyle',
  PROFILE = 'profile',
  SUBSCRIPTION = 'subscription',
  REFERRALS = 'referrals'
}

const useStyles = makeStyles((theme) =>
  createStyles({
    tabIcon: {
      fontSize: 'inherit',
      marginRight: theme.spacing(1)
    },
    tabPanel: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(1)}px 0`
      }
    },
    root: {
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    gradientLeft: {
      position: 'absolute',
      height: 44,
      width: 48,
      marginLeft: 0,
      marginTop: -50,
      background: theme.palette.type === 'light' ? 'linear-gradient(90deg, rgba(255, 255, 255, 0.84) 21.85%, rgba(255, 255, 255, 0) 100%)' :
        'linear-gradient(90deg, rgba(54, 53, 64, 0.84) 21.85%, rgba(54, 53, 64, 0) 100%)'
    },
    gradientRight: {
      position: 'absolute',
      height: 44,
      width: 48,
      right: 0,
      marginTop: -50,
      background: theme.palette.type === 'light' ? 'linear-gradient(270deg, rgba(255, 255, 255, 0.84) 21.85%, rgba(255, 255, 255, 0) 100%)' :
        'linear-gradient(270deg, rgba(54, 53, 64, 0.84) 21.85%, rgba(54, 53, 64, 0) 100%)'
    }
  })
);

const Profile: React.FC<RouteComponentProps<{ tab?: TAB }>> = ({ match: { params: { tab } } }) => {
  const classes = useStyles();
  const [activeTab, setTab] = useState<TAB>(tab || TAB.LIFESTYLE);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: TAB) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (tab && tab !== activeTab) {
      setTab(tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (<>
    <PageTitle title="Profile" />
    <TabContext value={activeTab}>
      <Tabs
        value={activeTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="inherit"
        variant="scrollable"
      >
        <Tab label="Lifestyle Profile" value={TAB.LIFESTYLE} icon={<Bicycle className={classes.tabIcon}/>}/>
        <Tab label="Account" value={TAB.PROFILE} icon={<User className={classes.tabIcon}/>}/>
        <Tab label="Billing" value={TAB.SUBSCRIPTION} icon={<ArrowsClockwise weight="light" className={classes.tabIcon}/>}/>
        <Tab label="Referrals" value={TAB.REFERRALS} icon={<Users className={classes.tabIcon}/>}/>
      </Tabs>
      <HiddenCss smUp>
        <div className={classes.gradientLeft}/>
        <div className={classes.gradientRight}/>
      </HiddenCss>
      <StyledPaper elevation={0} container className={classes.root}>
        <TabPanel value={TAB.LIFESTYLE} className={classes.tabPanel}>
          <LifestyleForm/>
        </TabPanel>
        <TabPanel value={TAB.PROFILE} className={classes.tabPanel}>
          <ProfileForm/>
        </TabPanel>
        <TabPanel value={TAB.SUBSCRIPTION} className={classes.tabPanel}>
          <Subscriptions/>
        </TabPanel>
        <TabPanel value={TAB.REFERRALS} className={classes.tabPanel}>
          <Referrals/>
        </TabPanel>
      </StyledPaper>
    </TabContext>
  </>);
};

export default Profile;