import { Box, Grid, IconButton, Link } from '@material-ui/core';
import HiddenJs from '@material-ui/core/Hidden/HiddenJs';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import ColoredBadge from 'components/ColoredBadge';
import ElevateAccordion from 'components/ElevateAccordion';
import ElevateAccordionDetails from 'components/ElevateAccordionDetails';
import ElevateAccordionSummary from 'components/ElevateAccordionSummary';
import ElevateToggleButton from 'components/ElevateToggleButton';
import ElevateToggleButtonGroup from 'components/ElevateToggleButtonGroup';
import GridSection from 'components/GridSection';
import PerformanceIcon from 'components/PerformanceIcon';
import { MOBILE_M } from 'config/theme/breakpoints';
import CurrentScoreContext from 'contexts/currentScore';
import { Bound, TestResult } from 'generated/graphql';
import Biomarker from 'models/Biomarker';
import TestResultBar from 'pages/Insights/TestResultBar';
import TestResultChart from 'pages/Insights/TestResultChart';
import React, { useContext } from 'react';
import { getIntervals, getQualityColor, getQualityText, getValueQuality, RESULT_QUALITY } from 'utils/biomarkersUtils';
import { getScoreName, GOOD, NEED_IMPROVING, SCORE } from 'utils/scoreUtils';

const useStyles = makeStyles((theme) => ({
  defaultOrder: {
    order: 1
  },
  expandContainer: {
    order: 1,
    flexGrow: 0
  },
  barOrder: {
    order: 1,
    [theme.breakpoints.down('md')]: {
      order: 2
    }
  },
  titleSection: {
    fontSize: 16
  },
  qualitySection: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down(MOBILE_M)]: {
      width: '100%',
      display: 'block',
      paddingLeft: theme.spacing(0),
    }
  },
  qualityIndicator: {
    fontSize: 8,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5)
    }
  },
  toggleButton: {
    textAlign: 'center'
  },
  summary: {
    padding: 16
  },
  expanded: {},
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    }),
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&$expanded': {
      transform: 'rotate(180deg)'
    }
  }
}));

const getBadgeColor = (score: number): 'orange' | 'warning' | 'success' => {
  if (score < NEED_IMPROVING) return 'orange';
  if (score < GOOD) return 'warning';
  else return 'success';
};

const getScoreText = (biomarker: Biomarker, quality?: RESULT_QUALITY) => {
  switch (quality) {
    case RESULT_QUALITY.TOO_LOW:
    case RESULT_QUALITY.LOW:
      return biomarker.tooLow;
    case RESULT_QUALITY.SUBOPTIMAL:
    case RESULT_QUALITY.SUPRAOPTIMAL:
      return biomarker.roomToImprove;
    case RESULT_QUALITY.HIGH:
    case RESULT_QUALITY.TOO_HIGH:
      return biomarker.tooHigh;
    default:
      return biomarker.optimalLevel;
  }
};

export interface TestResultsListItemProps {
  biomarker: Biomarker,
  testResult: TestResult
}

const TestResultsListItem: React.FC<TestResultsListItemProps> = ({ biomarker, testResult }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [details, setDetails] = React.useState('insight');

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleDetails = (event: React.MouseEvent<HTMLElement>, newMode: string) => {
    setDetails(newMode);
  };

  const { score } = useContext(CurrentScoreContext);

  if (testResult && biomarker) {
    const value = testResult[biomarker.biomarkerRef] as number;
    const intervals = getIntervals(biomarker.bound as Bound, biomarker.maxValue);
    const quality = getValueQuality(intervals, value);

    return <Box pt={1} pb={1}>
      <ElevateAccordion elevation={0} variant="outlined" expanded={expanded} onChange={toggleAccordion}>
        <ElevateAccordionSummary className={classes.summary}>
          <Grid container alignItems="center">
            <Grid item xs lg={4} xl={3} className={clsx(classes.defaultOrder, classes.titleSection)}>
              {biomarker.name}
              <span className={classes.qualitySection} style={{
                color: getQualityColor(quality)
              }}>
                <FiberManualRecordIcon className={classes.qualityIndicator} />
                {getQualityText(quality)}
              </span>
            </Grid>
            <HiddenJs lgUp>
              <Grid item xs="auto" className={classes.defaultOrder} />
            </HiddenJs>
            <Grid item xs={12} lg className={classes.barOrder}>
              <TestResultBar intervals={intervals} value={value}
                unit={biomarker.unit} />
            </Grid>
            <Grid item container xs={1} lg alignItems="center" justify="flex-end" className={classes.expandContainer}>
              <Grid item>
                <IconButton
                  className={clsx(classes.expandIcon, {
                    [classes.expanded]: expanded
                  })}
                  edge="end"
                  component="div"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </ElevateAccordionSummary>
        <ElevateAccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.toggleButton}>
              <ElevateToggleButtonGroup value={details} onChange={handleDetails} exclusive size="small">
                <ElevateToggleButton value="insight" aria-label="insight">
                  Insight
                </ElevateToggleButton>
                <ElevateToggleButton value="trend" aria-label="dark">
                  Trend
                </ElevateToggleButton>
              </ElevateToggleButtonGroup>
            </Grid>
            {details === 'insight' ? (<>
              <GridSection item sm={6} xs={12} title="What your score indicates">
                {getScoreText(biomarker, quality)}
              </GridSection>
              <GridSection item sm={6} xs={12} title={`What is ${biomarker.name}?`}>
                {biomarker.description}
              </GridSection>
              <GridSection item sm={6} xs={12} title="Health areas affected">
                {score && biomarker.performanceAreas.map((area: string) => (
                  <ColoredBadge
                    mr={1}
                    mb={1}
                    color={getBadgeColor(score[area as unknown as SCORE])}
                    key={area}
                    icon={<PerformanceIcon category={area as unknown as SCORE} />}>
                    {getScoreName(area as unknown as SCORE)}
                  </ColoredBadge>
                ))}
              </GridSection>
              <GridSection item sm={6} xs={12} title="Learn more">
                <Link href={biomarker.learnMore} target="_blank">{biomarker.name} blood test article</Link>
              </GridSection>
            </>) : <Grid item xs={12}>
              <TestResultChart intervals={intervals} testResult={testResult} biomarker={biomarker} />
            </Grid>}
          </Grid>
        </ElevateAccordionDetails>
      </ElevateAccordion>
    </Box>;
  }

  return null;
};

export default TestResultsListItem;