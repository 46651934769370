import { CssBaseline, ThemeProvider } from '@material-ui/core';
import NotificationsManager from 'components/NotificationsManager';
import Router from 'components/Router';
import { darkTheme, lightTheme } from 'config/theme';
import CurrentScoreContext from 'contexts/currentScore';
import { FirebaseStorageProvider } from 'contexts/FirebaseStorage';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const App: React.FC = () => {
  const darkMode = useSelector((state: IRootState) => state.userPreferences.darkMode);
  const [score, setScore] = useState(null);

  const theme = darkMode ? darkTheme : lightTheme;
  return (
    <ThemeProvider theme={theme}>
      <FirebaseStorageProvider>
        <CurrentScoreContext.Provider value={{
          score,
          setScore
        }}>
          <NotificationsManager>
            <CssBaseline/>
            <Router/>
          </NotificationsManager>
        </CurrentScoreContext.Provider>
      </FirebaseStorageProvider>
    </ThemeProvider>
  );
};

export default App;
