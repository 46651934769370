import { protectedRoutes } from 'config/routes';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import AuthenticatedLayout from './AuthenticatedLayout';
import NotAuthenticatedLayout from './NotAuthenticatedLayout';

type Props = {};

enum LAYOUT {
  AUTHENTICATED = 'AUTHENTICATED',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED'
}


const Layout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  let layout = LAYOUT.NOT_AUTHENTICATED;
  const protectedRoute = protectedRoutes.find((route) => {
      return matchPath(location.pathname, route);
    }
  );

  if (protectedRoute) {
    layout = LAYOUT.AUTHENTICATED;
  }

  switch (layout) {
    case LAYOUT.AUTHENTICATED:
      return <AuthenticatedLayout>{children}</AuthenticatedLayout>;
    case LAYOUT.NOT_AUTHENTICATED:
      return <NotAuthenticatedLayout>{children}</NotAuthenticatedLayout>;
    default:
      return <>{children}</>;
  }
};

export default Layout;
