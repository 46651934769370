import React from 'react';
import { useSelector } from 'react-redux';
import { FirebaseReducer, isEmpty, isLoaded } from 'react-redux-firebase';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { IRootState } from 'reducers';

export interface IProtectedRouteProps extends RouteProps {
}

export const ProtectedRouteComponent = ({
                                          component: Component,
                                          ...rest
                                        }: IProtectedRouteProps) => {
  const auth = useSelector<IRootState, FirebaseReducer.AuthState>(state => state.firebase.auth);

  const renderRedirect = (props: RouteProps) => {
    return isLoaded(auth) && !isEmpty(auth) ? (
      // @ts-ignore
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location }
        }}
      />
    );
  };

  if (!Component) throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);

  return <Route {...rest} render={renderRedirect}/>;
};

export default ProtectedRouteComponent;
