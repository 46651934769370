import { Tabs as MuiTabs, withStyles } from '@material-ui/core';

const Tabs = withStyles((theme) => ({
  root: {
    borderBottom: `2px solid ${theme.palette.divider}`
  },
  indicator: {
    height: 4,
    borderRadius: 2
  }
}))(MuiTabs);

export default Tabs;