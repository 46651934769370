import { Box, createStyles, Grid, Hidden, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import HiddenJs from '@material-ui/core/Hidden/HiddenJs';
import BorderLinearProgress from 'components/BorderLinearProgress/BorderLinearProgress';
import Loading from 'components/Loading';
import PerformanceIcon from 'components/PerformanceIcon';
import StyledPaper from 'components/StyledPaper';
import StyledTooltip from 'components/StyledTooltip/StyledTooltip';
import CurrentScoreContext from 'contexts/currentScore';
import ScoreButton from 'pages/Home/components/ScoreButton';
import React, { useContext, useEffect, useState } from 'react';
// @ts-ignore
import { InfoSquare as InfoSquareIcon } from 'react-iconly';
import { getScoreName, GOOD, NEED_IMPROVING, SCORE } from 'utils/scoreUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    title: {
      marginBottom: theme.spacing(2)
    },
    container: {
      height: '100%'
    },
    tooltip: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1)
    },
    icon: {
      height: 16,
      width: 16,
      verticalAlign: 'middle',
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginRight: theme.spacing(0.4)
      }
    },
    scoreGrid: {
      marginTop: 16,
      marginBottom: 16,
      // backgroundImage: 'linear-gradient(rgba(156, 177, 197, 0.2), rgba(156, 177, 197, 0.2) 75%, transparent 75%, transparent 100%), linear-gradient(rgba(156, 177, 197, 0.2), rgba(156, 177, 197, 0.2) 75%, transparent 75%, transparent 100%)',
      //backgroundImage: theme.palette.type === 'light' ? 'linear-gradient(rgb(175, 191, 201), rgb(175, 191, 201) 50%, transparent 50%, transparent 100%), linear-gradient(rgb(175, 191, 201), rgb(175, 191, 201) 50%, transparent 50%, transparent 50%)' : 'linear-gradient(rgb(219, 219, 219), rgb(219, 219, 219) 50%, transparent 50%, transparent 50%), linear-gradient(rgb(219, 219, 219), rgb(219, 219, 219) 50%, transparent 50%, transparent 50%)',
      backgroundImage: 'linear-gradient(rgb(175, 191, 201), rgb(175, 191, 201) 50%, transparent 50%, transparent 100%), linear-gradient(rgb(175, 191, 201), rgb(175, 191, 201) 50%, transparent 50%, transparent 50%)',
      backgroundPositionX: `${NEED_IMPROVING}%, ${GOOD}%`,
      backgroundSize: '1px 10px',
      backgroundRepeat: 'repeat-y',
      [theme.breakpoints.down('md')]: {
        marginTop: 14,
        marginBottom: 12
      }
    },
    scoreValue: {
      textAlign: 'center'
    },
    legend: {
      textAlign: 'center',
      color: theme.palette.grey[500],
      fontSize: 11,
      marginTop: 4,
      [theme.breakpoints.down('sm')]: {
        fontSize: 9
      }
    }
  })
);

const getBarColor = (score: number): string => {
  if (score < NEED_IMPROVING) return '#FA851B';
  if (score < GOOD) return '#FFD126';
  else return '#25EB9A';
};

const getElementOrder = (score: number): number => 100 - score;

const PerformanceScores: React.FC = () => {
  const { score } = useContext(CurrentScoreContext);
  const [scoreValues, setScoreValues] = useState([0, 0, 0, 0, 0, 0, 0]);
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (score) {
      setTimeout(() => setScoreValues([
        score.cognition,
        score.energy,
        score.immunity,
        score.fitness,
        score.sleep,
        score.metabolism,
        score.mood
      ]), 0);
    }
  }, [score]);

  if (score) {
    const scoreCategories = Object.values(SCORE);

    return (
      <StyledPaper elevation={0} className={classes.root}>
        <Grid container direction="column" className={classes.container}>
          <Grid item>
            <Typography variant="h3" component="h2" className={classes.title}>
              Health scores
              <StyledTooltip
                className={classes.tooltip}
                title="Each score combines relevant blood results and lifestyle behaviours for that health area. The more optimal your results, the greater the score.">
                <InfoSquareIcon set="light" size="small" color={theme.palette.grey[500]} />
              </StyledTooltip>
            </Typography>
          </Grid>
          <Grid item xs container>
            <Grid container item xs={4} lg={3}
              justify="space-around"
              direction="column">
              {scoreCategories.map(category => (
                <Grid item style={{ order: getElementOrder(score[category]) }} key={category}>
                  <Hidden only="xs">
                    <PerformanceIcon category={category} className={classes.icon} />
                  </Hidden>
                  {getScoreName(category)}
                </Grid>
              ))}
            </Grid>
            <Grid container item xs={5} sm justify="space-between" direction="column" className={classes.scoreGrid}>
              {scoreValues.sort((a, b) => b - a).map((score, i) => (
                <Grid item key={`score_${i}`}>
                  <BorderLinearProgress variant="determinate" value={score}
                    barColor={getBarColor(score)} />
                </Grid>))}
            </Grid>
            <Grid container item xs={3} sm={2} justify="space-around" direction="column">
              <Grid container item justify="flex-end" alignItems="center"
                style={{ order: getElementOrder(score.cognition) }}>
                <Grid item xs className={classes.scoreValue}>{score.cognition}%</Grid>
                <Grid item>
                  <ScoreButton items={['Cognition insights', 'Cognition actions']} menuId="cognition" />
                </Grid>
              </Grid>
              <Grid container item justify="flex-end" alignItems="center"
                style={{ order: getElementOrder(score.energy) }}>
                <Grid item xs className={classes.scoreValue}>{score.energy}%</Grid>
                <Grid item>
                  <ScoreButton items={['Energy insights', 'Energy actions']} menuId="energy" />
                </Grid>
              </Grid>
              <Grid container item justify="flex-end" alignItems="center"
                style={{ order: getElementOrder(score.immunity) }}>
                <Grid item xs className={classes.scoreValue}>{score.immunity}%</Grid>
                <Grid item>
                  <ScoreButton items={['Immunity insights', 'Immunity actions']} menuId="immunity" />
                </Grid>
              </Grid>
              <Grid container item justify="flex-end" alignItems="center"
                style={{ order: getElementOrder(score.fitness) }}>
                <Grid item xs className={classes.scoreValue}>{score.fitness}%</Grid>
                <Grid item>
                  <ScoreButton items={['Fitness insights', 'Fitness actions']} menuId="fitness" />
                </Grid>
              </Grid>
              <Grid container item justify="flex-end" alignItems="center"
                style={{ order: getElementOrder(score.sleep) }}>
                <Grid item xs className={classes.scoreValue}>{score.sleep}%</Grid>
                <Grid item>
                  <ScoreButton items={['Sleep insights', 'Sleep actions']} menuId="sleep" />
                </Grid>
              </Grid>
              <Grid container item justify="flex-end" alignItems="center"
                style={{ order: getElementOrder(score.metabolism) }}>
                <Grid item xs className={classes.scoreValue}>{score.metabolism}%</Grid>
                <Grid item>
                  <ScoreButton items={['Metabolism insights', 'Metabolism actions']} menuId="metabolism" />
                </Grid>
              </Grid>
              <Grid container item justify="flex-end" alignItems="center"
                style={{ order: getElementOrder(score.mood) }}>
                <Grid item xs className={classes.scoreValue}>{score.mood}%</Grid>
                <Grid item>
                  <ScoreButton items={['Mood insights', 'Mood actions']} menuId="mood" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <HiddenJs xsDown>
              <Grid container>
                <Grid item xs={4} lg={3} />
                <Grid item xs={5} sm container className={classes.legend}>
                  <Box width={`${NEED_IMPROVING}%`}>Needs<br />improving</Box>
                  <Box width={`${GOOD - NEED_IMPROVING}%`}>Good<br />score</Box>
                  <Box width={`${100 - GOOD}%`}>Great<br />score</Box>
                </Grid>
                <Grid item xs={3} sm={2} />
              </Grid>
            </HiddenJs>
          </Grid>
        </Grid>
      </StyledPaper>
    );
  } else
    return <StyledPaper elevation={0} className={classes.root}>
      <Loading />
    </StyledPaper>;
};

export default PerformanceScores;