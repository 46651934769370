import { fade, withStyles } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';

const ElevateToggleButton = withStyles((theme) => ({
  root: {
    border: 'none',
    textTransform: 'none',
    '&$selected': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.type === 'light' ? 'white' : '#505461',
      '&:hover': {
        backgroundColor: theme.palette.type === 'light' ? 'white' : '#505461'
      }
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.type === 'light' ? '#fff' : '#505461', 0.36)
    }
  },
  selected: {},
  sizeSmall: {
    fontSize: 14
  },
  label: {
    lineHeight: 1.5
  }
}))(ToggleButton);

export default ElevateToggleButton;