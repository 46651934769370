const formatCurrency = (
  currencyCount: string | number,
  minimumDigits = 0,
  currencyType = 'gbp'
): string => {
  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: minimumDigits
  }).format(Number(currencyCount || 0));
};

export default formatCurrency;
