import { Backdrop, Box, createStyles, Grid, makeStyles, Slide, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@keyframes blink': {
      from: {
        opacity: 1
      },
      to: {
        opacity: 0.3
      }
    },
    root: {
      height: '100%'
    },
    logo: {
      animationName: '$blink',
      animationDuration: '1000ms',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
      animationTimingFunction: 'ease-in-out',
      animationPlayState: 'running',
      marginBottom: theme.spacing(2)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.background.default
    },
    sliderContainer: {
      width: 300,
      height: 21,
      textAlign: 'center',
      overflow: 'hidden'
    }
  })
);

const SplashScreen: React.FC = () => {
  const classes = useStyles();
  const [slide1, setSlide1] = useState(true);
  const [slide2, setSlide2] = useState(false);
  const [slide3, setSlide3] = useState(false);

  useEffect(() => {
    setSlide3(false);
    setSlide1(true);
    const timeout1 = setTimeout(() => {
      setSlide1(false);
      setSlide2(true);
    }, 2000); // 2000
    const timeout2 = setTimeout(() => {
      setSlide2(false);
      setSlide3(true);
    }, 4000); // 4000

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);

  return (
    <Backdrop className={classes.backdrop} open>
      <Grid container direction="column" alignContent="center" alignItems="center" justify="center"
        className={classes.root}>
        <Grid item>
          <Box className={classes.logo}>
            <img src="/assets/logo.svg" alt="logo" width="100" />
          </Box>
        </Grid>
        <Grid item className={classes.sliderContainer}>
          <Slide direction="up" in={slide1} mountOnEnter unmountOnExit>
            <Typography>Fetching your health data</Typography>
          </Slide>
          <Slide direction="up" in={slide2} mountOnEnter unmountOnExit>
            <Typography>Adding some Cato magic</Typography>
          </Slide>
          <Slide direction="up" in={slide3} mountOnEnter unmountOnExit>
            <Typography>Making it look pretty</Typography>
          </Slide>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default SplashScreen;