import { Tooltip, withStyles } from '@material-ui/core';
import { darkShadow, lightShadow } from 'config/theme/theme';

const ElevateTooltip = withStyles((theme) => ({
  tooltip: {
    borderRadius: 10,
    backgroundColor: theme.palette.type === 'light' ? 'white' : '#555A68',
    color: 'inherit',
    padding: theme.spacing(2),
    fontSize: 12,
    boxShadow: theme.palette.type === 'light' ? lightShadow : darkShadow
  }
}))(Tooltip);

export default ElevateTooltip;