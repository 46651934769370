import { Box, Grid, Typography, Button } from '@material-ui/core';
import React from 'react';
import { ROUTE } from 'config/routes';
import { generatePath, Link as RouterLink } from 'react-router-dom';

const OrderSuccess: React.FC = () => {
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item>
        <Box textAlign="center">
          <Box pb={2} pt={4}>
            <Typography variant="h1">Your order was successful.</Typography>
          </Box>
          <Box pb={2}>
            <Typography variant="body1">You’ll now be sent everything you need to collect your blood sample at home.</Typography>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            component={RouterLink}
            to={generatePath(ROUTE.HOME)}
          >
            Return to dashboard
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderSuccess;
