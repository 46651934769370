import { Button, Grid, InputAdornment, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
// @ts-ignore
import { Message as MessageIcon } from 'react-iconly';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 'auto',
    marginBottom: theme.spacing(2)
  },
  form: {
    width: '100%'
  },
  input: {
    maxWidth: 500,
    width: '100%'
  },
  forgotLink: {
    marginRight: 0,
    marginLeft: 'auto'
  },
  submit: {
    fontSize: 18,
    width: 261,
    height: 56
  }
}));

export interface IResetPasswordFormProps {
  handleReset: (email: string) => Promise<any>;
  hideReset: Function;
}

const ResetPasswordForm: React.FC<IResetPasswordFormProps> = ({ handleReset, hideReset }) => {
  const classes = useStyles();
  const [isEmailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [resetError, setResetError] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(event.currentTarget);
    handleReset(formData.get('email') as string)
      .then(() => {
        setEmailHasBeenSent(true);
        setTimeout(() => {
          setEmailHasBeenSent(false);
        }, 5000);
      }).catch(() => {
      setResetError(true);
      setTimeout(() => {
        setResetError(false);
      }, 5000);
    });
    event.preventDefault();
  };

  const handleResetClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    hideReset();
    event.preventDefault();
  };

  return (
    <Grid container alignItems="center" direction="column" justify="center">
      <Grid item className={classes.title}>
        <Typography component="h1" variant="h1">
          Reset password
        </Typography>
      </Grid>
      <Grid item xs className={classes.input}>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            placeholder="Email"
            name="email"
            type="email"
            InputProps={{
              startAdornment: <InputAdornment position="start">
                <MessageIcon primaryColor="#9CB1C5" set="light"/>
              </InputAdornment>
            }}
            autoFocus
          />
          <Grid container alignItems="center" justify="center" direction="column" spacing={2}>
            {resetError ? (
              <Grid item xs>
                <Alert severity="error">The email address you have entered is not recognised.</Alert>
              </Grid>
            ) : null}
            {isEmailHasBeenSent ? (
              <Grid item xs>
                <Alert severity="success">Please check your inbox for your password reset link.</Alert>
              </Grid>
            ) : null}
            <Grid item className={classes.forgotLink}>
              <Typography>
                <Link href="#" onClick={handleResetClick}>Back to login</Link>
              </Typography>
            </Grid>
            <Grid item xs>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordForm;