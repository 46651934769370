import { Button, createStyles, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import notificationsContext from 'contexts/notificationsContext';
import withUser, { WithUserProps } from 'hocs/withUser';
import React, { useContext } from 'react';

const useStyles = makeStyles(theme => createStyles({
  hint: {
    color: theme.palette.grey[500]
  },
  buttonContainer: {
    marginTop: theme.spacing(1.5)
  }
}));

const deleteHook = process.env.REACT_APP_DELETE_ACCOUNT_HOOK as string;
const demoUser = process.env.REACT_APP_DEMO_ACCOUNT as string;

const ProfileFormDelete: React.FC<WithUserProps> = ({ user }) => {
  const classes = useStyles();
  const { notify } = useContext(notificationsContext);

  const handleClick = () => {
    if (user.firebaseUID !== demoUser) {
      fetch(deleteHook, {
        method: 'POST',
        body: JSON.stringify({
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName
        })
      }).then(() => notify('Your request has been submitted. You’ll receive an email once your account has been deleted.', 'success'))
    } else {
      notify('This is a demo account and cannot be deleted.', 'success');
    }
  }

  return <Grid container spacing={1} direction="column">
    <Grid item>
      <Typography variant="h2">Delete account</Typography>
    </Grid>
    <Grid item>
      <Typography className={classes.hint}>By deleting your account you will lose all your data</Typography>
    </Grid>
    <Grid item className={classes.buttonContainer}>
      <Button variant="contained" color="secondary" onClick={handleClick}>Delete account</Button>
    </Grid>
  </Grid>;
};

export default withUser(ProfileFormDelete);