import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Select } from 'components/styled';
import notificationsContext from 'contexts/notificationsContext';
import { sub } from 'date-fns';
import { FormikValues, useFormik } from 'formik';
import { useUpdateProfileMutation } from 'generated/graphql';
import useUser from 'hooks/useUser';
import ProfileFormAvatar from 'pages/Profile/ProfileForm/ProfileFormAvatar';
import ProfileFormDelete from 'pages/Profile/ProfileForm/ProfileFormDelete/ProfileFormDelete';
import ProfileFormPassword from 'pages/Profile/ProfileForm/ProfileFormPassword';
import validationSchema from 'pages/Profile/ProfileForm/profileFormValidationSchema';
import { Calendar, DeviceMobile, EnvelopeSimple, User } from 'phosphor-react';
import React, { useContext } from 'react';
import { errorMsg, hasError } from 'utils/formikHelper';
import { fbanalytics } from '../../../config/firebase'

const demoUser = process.env.REACT_APP_DEMO_ACCOUNT as string;

const useStyles = makeStyles(theme => createStyles({
  icon: {
    marginRight: theme.spacing(0.7),
    verticalAlign: 'middle'
  },
  profileAvatarContainer: {
    marginBottom: 5
  }
}));

const sexValues = { 'male': 'Male', 'female': 'Female' };

const ProfileForm: React.FC = () => {
  const classes = useStyles();
  fbanalytics.logEvent('account_visit');
  const user = useUser();
  const [updateProfile] = useUpdateProfileMutation();
  const { notify } = useContext(notificationsContext);

  const formik = useFormik<FormikValues>({
    enableReinitialize: true,
    initialValues: {
      email: user?.email || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      gender: user?.gender || '',
      dob: user?.dob ? new Date(user?.dob) : new Date(),
      phone: user?.phone || ''
    },
    validationSchema,
    onSubmit: (values) => {
      if (user?.firebaseUID !== demoUser) {
        updateProfile({
          variables: {
            firebaseUID: user?.firebaseUID,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            gender: values.gender,
            dob: values.dob
          }
        }).then(() => notify('Profile updated', 'success'));
      } else {
        notify('This is a demo account and cannot be updated.', 'success');
      }
    }
  });

  const maxDate = sub(new Date(), { years: 18 });

  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
    formik.setFieldValue(event.target.name as string, event.target.value as any);
  };

  const handleDateChange = (date: any) => {
    formik.setFieldValue('dob', date);
  };

  return (
    <Grid container>
      <Grid item xs={12} container spacing={2} className={classes.profileAvatarContainer}>
        <Grid item xs={12}>
          <ProfileFormAvatar />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form onSubmit={formik.handleSubmit}>
            <fieldset>
              <legend>
                Profile
              </legend>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth error={hasError(formik, 'firstName')}>
                    <InputLabel shrink={false}><User className={classes.icon} />First name</InputLabel>
                    <OutlinedInput name="firstName" value={formik.values.firstName} onChange={handleChange} />
                    <FormHelperText>{errorMsg(formik, 'firstName')}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth error={hasError(formik, 'lastName')}>
                    <InputLabel shrink={false}><User className={classes.icon} />Last name</InputLabel>
                    <OutlinedInput name="lastName" value={formik.values.lastName} onChange={handleChange} />
                    <FormHelperText>{errorMsg(formik, 'lastName')}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth error={hasError(formik, 'gender')}>
                    <InputLabel shrink={false}><User className={classes.icon} />Sex</InputLabel>
                    <Select
                      name="gender"
                      value={formik.values.gender}
                      onChange={handleChange}
                    >
                      {Object.entries(sexValues).map(([value, label]) => (
                        <MenuItem key={value} value={value}>{label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errorMsg(formik, 'gender')}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth error={hasError(formik, 'dob')}>
                    <InputLabel shrink={false}><Calendar className={classes.icon} />Date of birth</InputLabel>
                    <DatePicker
                      name="dob"
                      inputVariant="outlined"
                      maxDate={maxDate}
                      format="dd-MM-yyyy"
                      placeholder="DD-MM-YYYY"
                      value={formik.values.dob}
                      onChange={handleDateChange}
                    />
                    <FormHelperText>{errorMsg(formik, 'dob')}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth error={hasError(formik, 'email')} disabled>
                    <InputLabel shrink={false}><EnvelopeSimple className={classes.icon} />Email</InputLabel>
                    <OutlinedInput name="email" value={formik.values.email} onChange={handleChange} />
                    <FormHelperText>{errorMsg(formik, 'email')}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth error={hasError(formik, 'phone')}>
                    <InputLabel shrink={false}><DeviceMobile className={classes.icon} />Mobile number</InputLabel>
                    <OutlinedInput name="phone" value={formik.values.phone} onChange={handleChange} />
                    <FormHelperText>{errorMsg(formik, 'phone')}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} container justify="center">
                  <Grid item>
                    <Button color="primary" variant="contained" type="submit">Update details</Button>
                  </Grid>
                </Grid>
              </Grid>
            </fieldset>
          </form>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        <ProfileFormPassword />
      </Grid>
      <Grid item xs={12}>
        <ProfileFormDelete />
      </Grid>
    </Grid>
  );
};

export default ProfileForm;