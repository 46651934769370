import { Tab as MuiTab, withStyles } from '@material-ui/core';

const Tab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontSize: 16,
    paddingTop: 6,
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
  },
  labelIcon: {
    minHeight: 48,
    '& $wrapper > *:first-child': {
      fontSize: 'inherit',
      marginRight: theme.spacing(1),
      marginBottom: 0,
    },
  },
  wrapper: {
    flexDirection: 'row'
  }
}))(MuiTab);

export default Tab;