import { deCapitalize } from 'utils/string';

export enum DIET {
  VEGETARIAN = 'vegetarian',
  VEGAN = 'vegan',
  PESCATARIAN = 'pescatarian',
  GLUTEN = 'gluten',
  PALEO = 'paleo',
  CALORIE_CONTROLLED = 'calorieControlled',
  CARB_FAT = 'carbFat',
  LOW_CARB = 'lowCarb'
}

export const diet = {
  [DIET.VEGETARIAN]: 'Vegetarian',
  [DIET.VEGAN]: 'Vegan',
  [DIET.PESCATARIAN]: 'Pescatarian',
  [DIET.GLUTEN]: 'Gluten-free (coeliac)',
  [DIET.PALEO]: 'Paleo',
  [DIET.CALORIE_CONTROLLED]: 'Calorie-controlled',
  [DIET.CARB_FAT]: 'Low carb & high fat',
  [DIET.LOW_CARB]: 'Low-carb'
}

export enum FOODS {
  DAIRY = 'dairy',
  GRAINS = 'grains',
  SOY = 'soy',
  FISH = 'fish',
  SHELLFISH = 'shellfish',
  POULTRY = 'poultry',
  RED_MEATS = 'redMeats',
  PORK = 'pork',
  EGGS = 'eggs',
  FRUIT = 'fruit',
  VEGETABLES = 'vegetables',
  NUTS = 'nuts',
  SEEDS = 'seeds'
}

export const foods = {
  [FOODS.DAIRY]: 'Dairy',
  [FOODS.GRAINS]: 'Grains',
  [FOODS.SOY]: 'Soy',
  [FOODS.FISH]: 'Fish',
  [FOODS.SHELLFISH]: 'Shellfish',
  [FOODS.POULTRY]: 'Poultry',
  [FOODS.RED_MEATS]: 'Red Meats',
  [FOODS.PORK]: 'Pork',
  [FOODS.EGGS]: 'Eggs',
  [FOODS.FRUIT]: 'Fruit',
  [FOODS.VEGETABLES]: 'Vegetables',
  [FOODS.NUTS]: 'Nuts',
  [FOODS.SEEDS]: 'Seeds'
}

export enum BEVERAGES {
  COFFEE_TEA = 'coffeeTea',
  HERBAL_TEAS = 'herbalTeas',
  ALCOHOL = 'alcohol',
  FRUIT_JUICE = 'fruitJuice',
  FIZZY_DRINKS = 'fizzyDrinks'
}

export const beverages = {
  [BEVERAGES.COFFEE_TEA]: 'Coffee / Tea',
  [BEVERAGES.HERBAL_TEAS]: 'Herbal Teas (Camomile, Green, Ginger)',
  [BEVERAGES.ALCOHOL]: 'Alcohol',
  [BEVERAGES.FRUIT_JUICE]: 'Fruit Juice',
  [BEVERAGES.FIZZY_DRINKS]: 'Fizzy Drinks'
}

export enum SUPPLEMENTS {
  VITAMIN_D = 'vitaminD',
  VITAMIN_C = 'vitaminC',
  VITAMIN_B12 = 'vitaminB12',
  VITAMIN_B_COMPLEX = 'vitaminBComplex',
  MULTI_VITAMIN = 'multiVitamin',
  MAGNESIUM = 'magnesium',
  PROBIOTICS = 'probiotics',
  IRON = 'iron',
  FISH_OIL = 'fishOil',
  CALCIUM = 'calcium',
  CREATINE = 'creatine',
}

export const supplements = {
  [SUPPLEMENTS.VITAMIN_D]: 'Vitamin D',
  [SUPPLEMENTS.VITAMIN_C]: 'Vitamin C',
  [SUPPLEMENTS.VITAMIN_B12]: 'Vitamin B12',
  [SUPPLEMENTS.VITAMIN_B_COMPLEX]: 'Vitamin B Complex',
  [SUPPLEMENTS.MULTI_VITAMIN]: 'Multi-vitamin',
  [SUPPLEMENTS.MAGNESIUM]: 'Magnesium',
  [SUPPLEMENTS.PROBIOTICS]: 'Probiotics',
  [SUPPLEMENTS.IRON]: 'Iron',
  [SUPPLEMENTS.FISH_OIL]: 'Fish Oil (this includes Krill Oil & Omega 3)',
  [SUPPLEMENTS.CALCIUM]: 'Calcium',
  [SUPPLEMENTS.CREATINE]: 'Creatine'
}

export enum EXERCISE {
  FITNESS_TRAINING = 'fitnessTraining',
  WEIGHT_TRAINING = 'weightTraining',
  WORKOUT_CLASSES = 'workoutClasses',
  FLEXIBILITY = 'flexibility',
  SPORTS = 'sports'
}

export const exercise = {
  [EXERCISE.FITNESS_TRAINING]: 'Fitness training  (e.g. running or cycling...)',
  [EXERCISE.WEIGHT_TRAINING]: 'Weight training',
  [EXERCISE.WORKOUT_CLASSES]: 'Workout classes  (e.g. HIIT or spin...)',
  [EXERCISE.FLEXIBILITY]: 'Flexibility  (e.g. yoga or pilates...)',
  [EXERCISE.SPORTS]: 'Sports  (e.g. football or tennis...)'
}

export enum EXERCISE_FREQUENCY {
  NONE = 'none',
  ONCE = 'once',
  TWICE = 'twice',
  THRICE = 'thrice',
  MORE_THAN_FOUR = 'moreThanFour'
}

export const exerciseFrequency = {
  [EXERCISE_FREQUENCY.NONE]: 'None',
  [EXERCISE_FREQUENCY.ONCE]: 'Once a week',
  [EXERCISE_FREQUENCY.TWICE]: 'Twice a week',
  [EXERCISE_FREQUENCY.THRICE]: '3 - 4 times a week',
  [EXERCISE_FREQUENCY.MORE_THAN_FOUR]: 'More than 4 times a week'
}

export enum SLEEP_DURATION {
  LESS_6_HOURS = 'less6hours',
  '6HOURS' = '6hours',
  '7HOURS' = '7hours',
  '8HOURS' = '8hours',
  '9HOURS' = '9hours',
  MORE_9_HOURS = 'more9hours'
}

export const sleepDuration = {
  [SLEEP_DURATION.LESS_6_HOURS]: 'Less than 6 hours',
  [SLEEP_DURATION['6HOURS']]: '6 hours',
  [SLEEP_DURATION['7HOURS']]: '7 hours',
  [SLEEP_DURATION['8HOURS']]: '8 hours',
  [SLEEP_DURATION['9HOURS']]: '9 hours',
  [SLEEP_DURATION.MORE_9_HOURS]: 'More than 9 hours'
}

export enum SUN_EXPOSURE {
  LESS_THAN = 'lessThan',
  MORE_THAN = 'moreThan'
}

export const sunExposure = {
  [SUN_EXPOSURE.LESS_THAN]: 'Less than 20 minutes',
  [SUN_EXPOSURE.MORE_THAN]: 'More than 20 minutes'
}

export enum SMOKING_HABIT {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  A_FEW = 'aFew',
  NEVER = 'never'
}

export const smokingHabit = {
  [SMOKING_HABIT.DAILY]: 'Around a packet a day',
  [SMOKING_HABIT.WEEKLY]: 'A bit across the week',
  [SMOKING_HABIT.A_FEW]: 'Only a few across the month (social smoker)',
  [SMOKING_HABIT.NEVER]: 'Never'
}

export const readableSleepDuration = (value: SLEEP_DURATION) => deCapitalize(sleepDuration[value]);
export const readableSunExposure = (value: SUN_EXPOSURE) => deCapitalize(sunExposure[value]);
export const readableExerciseFrequency = (value: EXERCISE_FREQUENCY) => deCapitalize(exerciseFrequency[value]);