import { Grid, Typography } from '@material-ui/core';
import CurrentTestBar from 'pages/Profile/Subscriptions/CurrentTestBar';
import React from 'react';

const CurrentTest: React.FC = () => {
  return <Grid container alignItems="center" spacing={1}>
    <Grid item xs={12} md="auto">
      <Typography variant="subtitle2">Current test</Typography>
    </Grid>
    <Grid item xs>
      <CurrentTestBar />
    </Grid>
  </Grid>;
};

export default CurrentTest;