import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    backdropFilter: 'blur(6px)',
    [theme.breakpoints.up('md')]: {
      marginLeft: 256
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 80
    }
  },
  overlay: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2
  },
  container: {
    display: 'flex',
    backgroundColor: '#292830',
    opacity: 0.8,
    color: 'white'
  }
}));

const DemoOverlay: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={clsx(classes.root, classes.overlay)}>
    <div className={clsx(classes.container, classes.overlay)}>{children}</div>
  </div>;
};

export default DemoOverlay;