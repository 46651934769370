import { SvgIconProps } from '@material-ui/core/SvgIcon';
import StyledSvgIcon from 'icons/StyledSvgIcon';
import React from 'react';

const SunIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox="0 0 16 16" {...props}>
    <path
      d="M8 11.75C10.0711 11.75 11.75 10.0711 11.75 8C11.75 5.92893 10.0711 4.25 8 4.25C5.92893 4.25 4.25 5.92893 4.25 8C4.25 10.0711 5.92893 11.75 8 11.75Z"
      strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8 2.25V1" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.93405 3.93412L3.05017 3.05023" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.25 8H1" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.93405 12.0659L3.05017 12.9497" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8 13.75V15" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.0658 12.0659L12.9497 12.9497" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.75 8H15" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.0658 3.93412L12.9497 3.05023" strokeLinecap="round" strokeLinejoin="round"/>
  </StyledSvgIcon>
);

export default SunIcon;