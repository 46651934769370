import { Bound, TestResult } from 'generated/graphql';
import Biomarker from 'models/Biomarker';
import Bounds from 'models/Bounds';

export enum RESULT_QUALITY {
  TOO_LOW,
  LOW,
  SUBOPTIMAL,
  OPTIMAL,
  SUPRAOPTIMAL,
  HIGH,
  TOO_HIGH
}

export interface ResultInterval {
  lower: number,
  upper: number,
  quality: RESULT_QUALITY
}

export const getGenderBound = (gender: string) => {
  return gender === 'male' ? 'maleBounds' : 'femaleBounds';
}

export const getAgeBracket = (dob: string): string => {
  const age = Math.abs(new Date(Date.now() - Date.parse(dob)).getUTCFullYear() - 1970);
  if (age < 18) return '_18';
  if (age < 30) return '_30';
  if (age < 40) return '_40';
  if (age < 50) return '_50';
  if (age < 60) return '_60';
  return '_150';
}

export const getIntervals = (bound: Bound, value: number) => {
  const intervals: Array<ResultInterval> = [];
  let currentLower = 0;

  if (bound.criticallyLowUB) {
    intervals.push({
      lower: currentLower,
      upper: bound.criticallyLowUB,
      quality: RESULT_QUALITY.TOO_LOW
    });
    currentLower = bound.criticallyLowUB;
  }

  if (bound.lowUB) {
    intervals.push({
      lower: currentLower,
      upper: bound.lowUB,
      quality: RESULT_QUALITY.LOW
    });
    currentLower = bound.lowUB;
  }

  if (bound.subOptimalUB) {
    intervals.push({
      lower: currentLower,
      upper: bound.subOptimalUB,
      quality: RESULT_QUALITY.SUBOPTIMAL
    });
    currentLower = bound.subOptimalUB;
  }

  if (bound.optimalUB) {
    intervals.push({
      lower: currentLower,
      upper: bound.optimalUB,
      quality: RESULT_QUALITY.OPTIMAL
    });
    currentLower = bound.optimalUB;
  }

  if (bound.supraOptimalUB) {
    intervals.push({
      lower: currentLower,
      upper: bound.supraOptimalUB,
      quality: RESULT_QUALITY.SUPRAOPTIMAL
    });
    currentLower = bound.supraOptimalUB;
  }

  if (bound.highUB) {
    intervals.push({
      lower: currentLower,
      upper: bound.highUB,
      quality: RESULT_QUALITY.HIGH
    });
    currentLower = bound.highUB;
  }

  if (bound.criticallyHighUB) {
    intervals.push({
      lower: currentLower,
      upper: bound.criticallyHighUB,
      quality: RESULT_QUALITY.TOO_HIGH
    });
  }

  if (intervals[intervals.length - 1].upper < value) intervals[intervals.length - 1].upper = value;

  return intervals;
};

export const getIntervalPercentage = (interval: ResultInterval, intervals: ResultInterval[]) =>
  (interval.upper - interval.lower) / intervals[intervals.length - 1].upper * 100;
export const getIntervalPosition = (interval: ResultInterval, intervals: ResultInterval[]) =>
  interval.upper / intervals[intervals.length - 1].upper * 100;

export const getValueInterval = (intervals: Array<ResultInterval>, value: number) => intervals.find(i => i.lower < value && value <= i.upper);
export const getValueQuality = (intervals: Array<ResultInterval>, value: number) => intervals.find(i => i.lower < value && value <= i.upper)?.quality;

export const filterByTestResult = (biomarkers: Array<Biomarker>, testResult?: TestResult): Array<Biomarker> =>
  testResult ? biomarkers.slice().filter(biomarker => testResult[biomarker.biomarkerRef as keyof TestResult]) : biomarkers;

export const extractBounds = (biomarkers: Array<Biomarker>, genderBound: 'maleBounds' | 'femaleBounds', ageBracket: string): Array<Biomarker> =>
  biomarkers.map(biomarker => ({
    ...biomarker,
    bound: biomarker[genderBound][ageBracket as keyof Bounds]
  }));

export const filterByPerformanceAreas = (biomarkers: Array<Biomarker>, area: string) =>
  biomarkers.filter(biomarker => biomarker.performanceAreas.includes(area));

export const filterByBloodGroup = (biomarkers: Array<Biomarker>, bloodGroup: string) =>
  biomarkers.filter(biomarker => biomarker.bloodGroup === bloodGroup);

export const getQualityText = (quality?: RESULT_QUALITY): string => {
  switch (quality) {
    case RESULT_QUALITY.TOO_LOW:
      return 'Very Low';
    case RESULT_QUALITY.LOW:
      return 'Low';
    case RESULT_QUALITY.SUBOPTIMAL:
    case RESULT_QUALITY.SUPRAOPTIMAL:
      return 'Room to improve';
    case RESULT_QUALITY.OPTIMAL:
      return 'Optimal';
    case RESULT_QUALITY.HIGH:
      return 'High';
    case RESULT_QUALITY.TOO_HIGH:
      return 'Very High';
    default:
      return '';
  }
}

export const getQualityColor = (quality?: RESULT_QUALITY): string => {
  switch (quality) {
    case RESULT_QUALITY.TOO_LOW:
    case RESULT_QUALITY.TOO_HIGH:
      // return '#FA4323';
      return '#f55b3f';
    case RESULT_QUALITY.LOW:
    case RESULT_QUALITY.HIGH:
      // return '#FA851B';
      // return '#f78823';
      return '#ff993b';
    case RESULT_QUALITY.SUBOPTIMAL:
    case RESULT_QUALITY.SUPRAOPTIMAL:
      // return '#FFD126';
      // return '#ffd026';
      return '#ffd746';
    case RESULT_QUALITY.OPTIMAL:
    default:
      return '#25EB9A';
  }
}

export const getQualityColorString = (quality?: RESULT_QUALITY) => {
  switch (quality) {
    case RESULT_QUALITY.TOO_LOW:
    case RESULT_QUALITY.TOO_HIGH:
      return 'orange';
    case RESULT_QUALITY.LOW:
    case RESULT_QUALITY.HIGH:
      return 'orange';
    case RESULT_QUALITY.SUBOPTIMAL:
    case RESULT_QUALITY.SUPRAOPTIMAL:
      return 'warning';
    // return '#FFD388';
    case RESULT_QUALITY.OPTIMAL:
    default:
      return 'success';
  }
}