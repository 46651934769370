import { createStyles, makeStyles, Paper, withStyles } from '@material-ui/core';
import { ToggleButtonGroup } from '@material-ui/lab';
import { ToggleButtonGroupProps } from '@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      borderRadius: 50,
      // backgroundColor: theme.palette.type === 'light' ? theme.palette.secondary.main : '#363540'
      backgroundColor: theme.palette.type === 'light' ? theme.palette.secondary.main : '#363540'
    },
    divider: {
      margin: theme.spacing(1, 0.5)
    }
  })
);

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: 50
    },
    '&:first-child': {
      borderRadius: 50
    }
  }
}))(ToggleButtonGroup);

const ElevateToggleButtonGroup: React.FC<ToggleButtonGroupProps> = (props) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.paper}>
      <StyledToggleButtonGroup {...props}>
        {props.children}
      </StyledToggleButtonGroup>
    </Paper>
  );
};

export default ElevateToggleButtonGroup;