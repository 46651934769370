import { Action, GetActionsQuery, User } from 'generated/graphql';
import { YES_NO } from 'utils/common';
import {
  DIET,
  diet,
  EXERCISE,
  exercise,
  EXERCISE_FREQUENCY,
  foods,
  FOODS,
  readableExerciseFrequency,
  readableSleepDuration,
  readableSunExposure,
  SLEEP_DURATION,
  SUN_EXPOSURE,
  SUPPLEMENTS,
  supplements
} from 'utils/lifestyle';
import { deCapitalize, readableJoin, removeParentheses } from 'utils/string';

export enum ACTION {
  ALL = 'All',
  NUTRITION = 'Nutrition',
  LIFESTYLE = 'Lifestyle',
  SUPPLEMENTS = 'Supplements'
}

export const sortActions = (actions: GetActionsQuery): Array<Action> =>
  (actions.getActions as Array<Action>).slice().sort((a, b) => b.impactRanking - a.impactRanking);

export const getDescription = (action: ACTION, user: User) => {
  let description = '';

  switch (action) {
    case ACTION.ALL:
      description = `You've told us that your goal areas are ${readableJoin(user.goals)}.`;
      break;
    case ACTION.LIFESTYLE:
      if (user.lifestyle.exerciseFrequency === EXERCISE_FREQUENCY.NONE) {
        description = `You've told us that you currently don't exercise during the week. You sleep on average ${readableSleepDuration(user.lifestyle.sleepDuration as SLEEP_DURATION)} a night and get ${readableSunExposure(user.lifestyle.sunExposure as SUN_EXPOSURE)} of sunshine a day.`;
      } else if (user.lifestyle.currentExercise?.length === 0 ) {
        description = `You've told us that you currently do exercise roughly ${readableExerciseFrequency(user.lifestyle.exerciseFrequency as EXERCISE_FREQUENCY)}. You sleep on average ${readableSleepDuration(user.lifestyle.sleepDuration as SLEEP_DURATION)} a night and get ${readableSunExposure(user.lifestyle.sunExposure as SUN_EXPOSURE)} of sunshine a day.`;
      } else {
        const exerciseStr = (user.lifestyle.currentExercise as Array<EXERCISE>).map(e => removeParentheses(deCapitalize(exercise[e])))
        description = `You've told us that you currently do ${readableJoin(exerciseStr)} roughly ${readableExerciseFrequency(user.lifestyle.exerciseFrequency as EXERCISE_FREQUENCY)}. You sleep on average ${readableSleepDuration(user.lifestyle.sleepDuration as SLEEP_DURATION)} a night and get ${readableSunExposure(user.lifestyle.sunExposure as SUN_EXPOSURE)} of sunshine a day.`
      }
      break;
    case ACTION.NUTRITION:
      if (user.lifestyle.diet.length === 0) {
        description = `You've told us that you don't follow a specific diet and there are no foods you don't eat.`
      } else if (user.lifestyle.foodsAvoided?.length === 0) {
        description = `You've told us that you follow a ${readableJoin(user.lifestyle.diet.map(value => removeParentheses(diet[value as DIET].toLowerCase())))} diet and there are no foods you don't eat.`;
      } else if (user.lifestyle.foodsAvoided) {
        description = `You've told us that you follow a ${readableJoin(user.lifestyle.diet.map(value => removeParentheses(diet[value as DIET].toLowerCase())))} diet and that you don't like eating ${readableJoin(user.lifestyle.foodsAvoided.map(value => foods[value as FOODS].toLowerCase()))}.`;
      }
      break;
    case ACTION.SUPPLEMENTS:
      if (user.lifestyle.willingToTakeSupplements === YES_NO.NO) {
        description = `You've told us that you're not willing to take supplements.`;
      } else if (user.lifestyle.currentSupplements && user.lifestyle.currentSupplements.length !== 0) {
        description = `You've told us that you currently take ${readableJoin(user.lifestyle.currentSupplements.map(value => removeParentheses(deCapitalize(supplements[value as SUPPLEMENTS]))))} supplements.`;
      }
      break;
    default:
      description = '';
      break;
  }

  return description;
}
