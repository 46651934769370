import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAvatar } from 'hooks/useStorageLink';
import React from 'react';

type Size = 'small' | 'medium' | 'free';

export interface IUserAvatarProps extends AvatarProps {
  firstName?: string;
  lastName?: string;
  uid: string;
  size?: Size;
}

const useStyles = makeStyles({
  small: {
    width: 40,
    height: 40,
    fontSize: 20,
    fontWeight: 500
  },
  medium: {
    width: 72,
    height: 72,
    fontSize: 36,
    fontWeight: 500
  },
  free: {
    width: '100%',
    height: '100%'
  }
});

const UserAvatar: React.FC<IUserAvatarProps> = ({ firstName, lastName, uid, size = 'free', ...props }) => {
  const classes: Record<Size, string> = useStyles();
  const imgSrc = useAvatar(uid);

  return (
    <Avatar
      classes={{ root: classes[size] }}
      alt={`${firstName} ${lastName}`}
      src={imgSrc}
      {...props}
    >
      {!props.src && firstName && lastName && `${firstName[0]}${lastName[0]}`}
    </Avatar>
  );
};

export default UserAvatar;
