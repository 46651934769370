import { createStyles, makeStyles, OutlinedInput, Select as MuiSelect, SelectProps } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    menu: {
      marginTop: theme.spacing(0.5),
      backgroundColor: theme.palette.type === 'light' ? theme.palette.common.white : theme.palette.divider
    }
  })
);

const useInputStyles = makeStyles((theme) =>
  createStyles({
    root: {}
  })
);

const Select: React.FC<SelectProps> = (props) => {
  const inputClasses = useInputStyles();
  const classes = useStyles();

  return <MuiSelect
    input={<OutlinedInput classes={inputClasses}/>}
    IconComponent={ExpandMoreIcon}
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      getContentAnchorEl: null,
      PaperProps: {
        className: classes.menu
      }
    }}
    {...props}
  >
    {props.children}
  </MuiSelect>;
};

export default Select;