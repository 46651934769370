import { SvgIconProps } from '@material-ui/core/SvgIcon';
import UIcon from 'icons/uicons/UIcon';
import React from 'react';

const RefreshIcon = (props: SvgIconProps) => (
  <UIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M23,12a.982.982,0,0,0-.988.878A9.986,9.986,0,0,1,4.924,19H7.669a1,1,0,1,0,0-2h-4a2,2,0,0,0-2,2v4a1,1,0,0,0,2,0V20.562A11.977,11.977,0,0,0,24,13.1,1.007,1.007,0,0,0,23,12Z"/>
    <path
      d="M21.331,0a1,1,0,0,0-1,1V3.438A11.977,11.977,0,0,0,0,10.9,1.007,1.007,0,0,0,1,12a.982.982,0,0,0,.988-.878A9.986,9.986,0,0,1,19.076,5H16.331a1,1,0,0,0,0,2h4a2,2,0,0,0,2-2V1A1,1,0,0,0,21.331,0Z"/>
  </UIcon>
);

export default RefreshIcon;