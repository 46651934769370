import { CircularProgress, createStyles, Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%'
    }
  })
);

const Loading: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container alignContent="center" alignItems="center" justify="center" classes={classes}>
      <Grid item>
        <CircularProgress/>
      </Grid>
    </Grid>
  );
};

export default Loading;