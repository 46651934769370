import { useQuery } from '@apollo/react-hooks';
import { Box, Button, Collapse, Grid, IconButton, Link, Typography } from '@material-ui/core';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import HiddenJs from '@material-ui/core/Hidden/HiddenJs';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import ActionIcon from 'components/ActionIcon';
import ColoredBadge from 'components/ColoredBadge';
import ElevateAccordion from 'components/ElevateAccordion';
import ElevateAccordionDetails from 'components/ElevateAccordionDetails';
import ElevateAccordionSummary from 'components/ElevateAccordionSummary';
import GridSection from 'components/GridSection';
import GridSectionTitle from 'components/GridSectionTitle';
import PerformanceIcon from 'components/PerformanceIcon';
import CurrentScoreContext from 'contexts/currentScore';
import { Action, Bound, Product, TestResult } from 'generated/graphql';
import withUser, { WithUserProps } from 'hocs/withUser';
import { useFirebaseLink } from 'hooks/useStorageLink';
import ClockIcon from 'icons/uicons/Clock';
import ShoppingBagIcon from 'icons/uicons/ShoppingBag';
import Biomarker from 'models/Biomarker';
import ImpactRating from 'pages/Actions/components/ImpactRating';
import GET_BIOMARKERS from 'queries/getBiomarkers';
import React, { MouseEvent, useContext } from 'react';
import { ACTION } from 'utils/actionsUtils';
import {
  extractBounds,
  filterByTestResult,
  getAgeBracket,
  getGenderBound,
  getIntervals,
  getQualityColorString,
  getValueQuality,
  RESULT_QUALITY
} from 'utils/biomarkersUtils';
import formatCurrency from 'utils/formatCurrency';
import { useGetTestResultsQuery } from 'utils/queryProxy';
import { getScoreName, GOOD, NEED_IMPROVING, SCORE } from 'utils/scoreUtils';
import { findByDate, parse } from 'utils/testDateUtils';

const useActionImage = (actionRef: string) => useFirebaseLink(`actions/${actionRef}.jpeg`);
const useProductImage = (actionRef: string) => useFirebaseLink(`actions/products/${actionRef}.jpeg`);

const useStyles = makeStyles((theme) => ({
  actionCategory: {
    color: 'white',
    borderRadius: 6,
    fontSize: 12,
    lineHeight: '28px',
    backgroundColor: '#41B0F6',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      top: 0,
      left: 0,
      position: 'absolute',
      width: 112
    }
  },
  actionCategoryIcon: {
    fontSize: 14,
    marginRight: theme.spacing(0.5)
  },
  actionImage: {
    marginRight: theme.spacing(1),
    width: 112,
    height: 104,
    borderRadius: 6,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    [theme.breakpoints.down('md')]: {
      height: 0,
      marginRight: 0,
      width: '100%',
      paddingTop: '40%',
      borderRadius: theme.shape.borderRadius,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0
    }
  },
  actionTitle: {
    padding: theme.spacing(1),
    marginRight: 5,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      fontSize: 16,
      padding: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  },
  actionIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1)
  },
  actionSection: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2)
    }
  },
  actionExpandContainer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius
  },
  actionExpandLink: {
    display: 'inline-flex',
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem'
    }
  },
  actionPriceContainer: {
    fontSize: 12
  },
  description: {
    color: theme.palette.grey[500]
  },
  discountFont: {
    fontSize: 10
  },
  actionDiscount: {
    textDecorationLine: 'line-through',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.4)
  },
  productTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(0.5)
  },
  productImage: {
    marginRight: theme.spacing(2),
    width: 290,
    height: 190,
    borderRadius: 6,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    [theme.breakpoints.down('xs')]: {
      height: 0,
      marginRight: 0,
      marginBottom: theme.spacing(2),
      width: '100%',
      paddingTop: '60%'
    }
  },
  productDescription: {
    marginBottom: theme.spacing(1.5)
  },
  productPrice: {
    fontWeight: 500,
    marginBottom: theme.spacing(1.5)
  },
  productDiscount: {
    color: theme.palette.grey[500],
    textDecorationLine: 'line-through',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0.4)
  },
  productMedicalLink: {
    display: 'block',
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  expanded: {},
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    }),
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&$expanded': {
      transform: 'rotate(180deg)'
    }
  }
}));

const getProductPrice = (product: Product): string => formatCurrency(product.amount, 2);
const getProductDiscount = (product: Product): string => formatCurrency(Math.round(product.amount / (1 - product.discountPercentage / 100)), 2);
const getBadgeColor = (score: number): 'orange' | 'warning' | 'success' => {
  if (score < NEED_IMPROVING) return 'orange';
  if (score < GOOD) return 'warning';
  else return 'success';
};

export interface ActionListItemProps {
  action: Action
}

const ActionsListItem: React.FC<ActionListItemProps & WithUserProps> = ({ action, user }) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const { data: biomarkersData } = useQuery(GET_BIOMARKERS(getGenderBound(user.gender), getAgeBracket(user.dob)));
  const { data: testResultData } = useGetTestResultsQuery({
    variables: {
      firebaseUID: user.firebaseUID
    }
  });

  let biomarkers: Array<Biomarker> = [], testResult: TestResult;

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleChangeLinks = (e: MouseEvent) => {
    e.preventDefault();
    setChecked((prev) => !prev);
  };

  const { score } = useContext(CurrentScoreContext);
  const imgSrc = useActionImage(action.actionRef);
  const productSrc = useProductImage(action.actionRef);

  if (score && biomarkersData.getBiomarkers && testResultData?.getTestResults) {
    biomarkers = biomarkersData.getBiomarkers as Array<Biomarker>;
    testResult = findByDate(parse(testResultData.getTestResults) as Array<TestResult>, score?.testDate) as TestResult;
    biomarkers = extractBounds(filterByTestResult(biomarkers, testResult), getGenderBound(user.gender), getAgeBracket(user.dob));
  }

  const getBiomarkerQuality = (name: string) => {
    const biomarker = biomarkers.find(marker => marker.name === name);
    if (biomarker && testResultData) {
      const value = testResult[biomarker.biomarkerRef] as number;
      const maxValue = Math.max(...testResultData.getTestResults.map(tr => tr[biomarker.biomarkerRef]));
      const intervals = getIntervals(biomarker.bound as Bound, maxValue);
      return getValueQuality(intervals, value);
    } else {
      return RESULT_QUALITY.OPTIMAL;
    }
  };

  return <Box pt={1} pb={1}>
    <ElevateAccordion elevation={0} variant="outlined" expanded={expanded} onChange={toggleAccordion}>
      <ElevateAccordionSummary>
        <Grid container alignItems="center">
          <Grid item container xs={12} lg alignItems="center">
            <Grid item className={classes.actionImage} style={{ backgroundImage: `url(${imgSrc})` }}>
              <div className={classes.actionCategory}>
                <ActionIcon category={action.category as unknown as ACTION} className={classes.actionCategoryIcon} />
                {action.category}
              </div>
            </Grid>
            <Grid xs={12} md item className={classes.actionTitle}>{action.actionTitle}</Grid>
          </Grid>
          <Grid item xs={6} lg={2} className={classes.actionSection}>
            <HiddenCss lgUp>
              <GridSectionTitle>Frequency</GridSectionTitle>
            </HiddenCss>
            <Box display="inline-flex" alignItems="center">
              <HiddenCss mdDown>
                <ClockIcon className={classes.actionIcon} />
              </HiddenCss>
              <Box component="span" pr={1}>{action.frequency}</Box>
            </Box>
          </Grid>
          <Grid item xs={6} lg={2} className={classes.actionSection}>
            <HiddenCss lgUp>
              <GridSectionTitle>Impact score</GridSectionTitle>
            </HiddenCss>
            <ImpactRating rating={action.impactRanking} />
          </Grid>
          {!expanded && (
            <HiddenJs lgUp>
              <Grid item xs={12} className={classes.actionExpandContainer}>
                <Link className={classes.actionExpandLink}>
                  More information <ChevronRightIcon />
                </Link>
              </Grid>
            </HiddenJs>
          )}
          <HiddenJs mdDown>
            <Grid item container lg={2} alignItems="center">
              {action.product.title && (<>
                <Grid item>
                  <ShoppingBagIcon className={classes.actionIcon} />
                </Grid>
                <Grid item sm container direction="column" spacing={1} className={classes.actionPriceContainer}>
                  <Grid item>{action.product.title}</Grid>
                  <Grid item className={classes.description}>
                    {getProductPrice(action.product)}
                    {!!action.product.discountPercentage && (
                      <>
                        <span
                          className={clsx(classes.actionDiscount, classes.discountFont)}>{getProductDiscount(action.product)}</span>
                        <span className={classes.discountFont}>(-{action.product.discountPercentage}%)</span>
                      </>
                    )}
                  </Grid>
                  <Grid item><Link href={action.product.link} target="_blank">See more</Link></Grid>
                </Grid>
              </>)}
            </Grid>
            <Grid item>
              <IconButton
                className={clsx(classes.expandIcon, {
                  [classes.expanded]: expanded
                })}
                edge="end"
                component="div"
                onClick={toggleAccordion}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
          </HiddenJs>
        </Grid>
      </ElevateAccordionSummary>
      <ElevateAccordionDetails>
        <Grid container spacing={4}>
          <GridSection item sm={6} xs={12} title="What are the benefits?">
            {action.actionWhy}
          </GridSection>
          <GridSection item sm={6} xs={12} title="How to do it">
            {action.actionUsage}
          </GridSection>
          <GridSection item sm={6} xs={12} title="Health areas benefited">
            {score && action.performanceAreas.map((area: string) => (
              <ColoredBadge
                mr={1}
                mb={1}
                color={getBadgeColor(score[area as unknown as SCORE])}
                key={area}
                icon={<PerformanceIcon category={area as unknown as SCORE} />}
              >
                {getScoreName(area as unknown as SCORE)}
              </ColoredBadge>
            ))}
          </GridSection>
          <GridSection item sm={6} xs={12} title="Blood tests benefited">
            {action.biomarkersBenefited.map((biomarker: string) => (
              <ColoredBadge
                mr={1}
                mb={1}
                color={getQualityColorString(getBiomarkerQuality(biomarker))}
                key={biomarker}>
                {biomarker}
              </ColoredBadge>
            ))}
          </GridSection>
          {action.product.title &&
            (<Grid item xs={12} container>
              <Grid item xs={12}>
                <GridSectionTitle>Recommended for you</GridSectionTitle>
              </Grid>
              <Grid item className={classes.productImage} style={{ backgroundImage: `url(${productSrc})` }} />
              <Grid item xs container alignContent="flex-start">
                <Grid item xs={12}><Typography className={classes.productTitle}>{action.product.title}</Typography></Grid>
                <Grid item xs={12}>
                  <Typography className={classes.productPrice}>{getProductPrice(action.product)}
                    {!!action.product.discountPercentage && (
                      <>
                        <span className={classes.productDiscount}>{getProductDiscount(action.product)}</span>
                        <span className={classes.description}>(-{action.product.discountPercentage}%)</span>
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.productDescription}>
                  <Typography className={classes.description}>{action.product.description}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    href={action.product.link} target="_blank"
                  >
                    View more
                  </Button>
                </Grid>
              </Grid>
            </Grid>)}
          <Grid item xs={12}>
            <GridSectionTitle>Medical references</GridSectionTitle>
            <Box pb={1}>
              <Typography><Link href="#" onClick={handleChangeLinks}>Show medical references</Link></Typography>
            </Box>
            <Collapse in={checked}>
              {action.medicalReferences.map((link, i) => (
                <Typography key={`${action.id}-${link}-${i}`}>
                  <Link href={link} target="_blank" className={classes.productMedicalLink}>{link}</Link>
                </Typography>
              ))}
            </Collapse>
          </Grid>
          {expanded && (
            <HiddenJs lgUp>
              <Grid item xs={12} className={classes.actionExpandContainer}>
                <Link onClick={toggleAccordion} className={classes.actionExpandLink}>
                  Hide information <ExpandLessIcon /></Link>
              </Grid>
            </HiddenJs>
          )}
        </Grid>
      </ElevateAccordionDetails>

    </ElevateAccordion>
  </Box>;
};

export default withUser(ActionsListItem);