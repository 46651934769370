import { createStyles, makeStyles, Menu, MenuItem } from '@material-ui/core';
import UserAvatar from 'components/UserAvatar';
import { ROUTE } from 'config/routes';
import withUser, { WithUserProps } from 'hocs/withUser';
import { TAB } from 'pages/Profile/Profile';
import React, { useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Bicycle, Users, User } from 'phosphor-react';

const useStyles = makeStyles((theme) =>
  createStyles({
    tabIcon: {
      fontSize: 'inherit',
      marginRight: theme.spacing(1)
    },
    root: {
      cursor: 'pointer'
    }
  })
);

const ProfileMenu: React.FC<WithUserProps> = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openProfile = (tab: TAB) => () => {
    handleClose();
    history.push(generatePath(ROUTE.PROFILE_TAB, { tab }));
  };

  return <>
    <UserAvatar firstName={user.firstName} lastName={user.lastName} uid={user.firebaseUID} size="small"
      aria-controls="profile-menu"
      aria-haspopup="true" onClick={handleClick} className={classes.root} />
    <Menu
      id="profile-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}>
      <MenuItem onClick={openProfile(TAB.LIFESTYLE)}><Bicycle className={classes.tabIcon} />Update lifestyle</MenuItem>
      <MenuItem onClick={openProfile(TAB.PROFILE)}><User className={classes.tabIcon} />My profile</MenuItem>
      <MenuItem onClick={openProfile(TAB.REFERRALS)}><Users className={classes.tabIcon} />Refer a friend</MenuItem>

    </Menu>
  </>;
};

export default withUser(ProfileMenu);