import * as Apollo from '@apollo/client';
import client from 'config/apollo';
import {
  GetActionsDocument,
  GetActionsQuery,
  GetActionsQueryVariables,
  GetDemoActionsDocument,
  GetDemoPerformanceScoresDocument,
  GetDemoTestResultsDocument,
  GetPerformanceScoresDocument,
  GetPerformanceScoresQuery,
  GetPerformanceScoresQueryVariables,
  GetTestResultsDocument,
  GetTestResultsQuery,
  GetTestResultsQueryVariables
} from 'generated/graphql';

class QueryProxy {
  private _isDemo: boolean;

  constructor() {
    this._isDemo = false;
  }

  get isDemo() {
    return this._isDemo;
  }

  set isDemo(isDemo) {
    this._isDemo = isDemo
  }

  getActions(options: GetActionsQueryVariables) {
    return client.query<GetActionsQuery>({
      query: this._isDemo ? GetDemoActionsDocument : GetActionsDocument,
      variables: this._isDemo ? undefined : options
    });
  }

  getPerformanceScores(options: GetPerformanceScoresQueryVariables) {
    return client.query<GetPerformanceScoresQuery>({
      query: this._isDemo ? GetDemoPerformanceScoresDocument : GetPerformanceScoresDocument,
      variables: this._isDemo ? undefined : options
    });
  }

  getTestResults(options: GetTestResultsQueryVariables) {
    return client.query<GetTestResultsQuery>({
      query: this._isDemo ? GetDemoTestResultsDocument : GetTestResultsDocument,
      variables: this._isDemo ? undefined : options
    });
  }
}


const queryProxy = new QueryProxy();

const fieldHandler = (originalField: string, newField: string) => {
  return {
    get: function (target: any, name: string) {
      if (name in target) return target[name];
      if (name === originalField) return target[newField];
      return undefined;
    }
  };
}

export function useGetActionsQuery(baseOptions?: Apollo.QueryHookOptions<GetActionsQuery, GetActionsQueryVariables>) {
  const options = { ...baseOptions }
  const result = Apollo.useQuery<GetActionsQuery, GetActionsQueryVariables>(queryProxy.isDemo ? GetDemoActionsDocument : GetActionsDocument, options);

  if (queryProxy.isDemo && result.data) {
    result.data = new Proxy(result.data, fieldHandler('getActions', 'getDemoActions'));
  }

  return result;
}

export function useGetPerformanceScoresQuery(baseOptions?: Apollo.QueryHookOptions<GetPerformanceScoresQuery, GetPerformanceScoresQueryVariables>) {
  const options = { ...baseOptions }
  const result = Apollo.useQuery<GetPerformanceScoresQuery, GetPerformanceScoresQueryVariables>(queryProxy.isDemo ? GetDemoPerformanceScoresDocument : GetPerformanceScoresDocument, options);

  if (queryProxy.isDemo && result.data) {
    result.data = new Proxy(result.data, fieldHandler('getPerformanceScores', 'getDemoPerformanceScores'));
  }

  return result;
}

export function useGetTestResultsQuery(baseOptions?: Apollo.QueryHookOptions<GetTestResultsQuery, GetTestResultsQueryVariables>) {
  const options = { ...baseOptions }
  const result = Apollo.useQuery<GetTestResultsQuery, GetTestResultsQueryVariables>(queryProxy.isDemo ? GetDemoTestResultsDocument : GetTestResultsDocument, options);

  if (queryProxy.isDemo && result.data) {
    result.data = new Proxy(result.data, fieldHandler('getTestResults', 'getDemoTestResults'));
  }

  return result;
}

export default queryProxy;