import { Box, Button, createStyles, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import { TabContext, TabPanel } from '@material-ui/lab';
import PerformanceIcon from 'components/PerformanceIcon';
import { Tab, Tabs } from 'components/styled';
import StyledTooltip from 'components/StyledTooltip';
import { ROUTE } from 'config/routes';
import CurrentScoreContext from 'contexts/currentScore';
import { TestResult } from 'generated/graphql';
import Biomarker from 'models/Biomarker';
import ScoreBadge from 'pages/Insights/ScoreBadge';
import TestResultsListItem from 'pages/Insights/TestResultsListItem';
import React, { useContext } from 'react';
import useUser from 'hooks/useUser';
// @ts-ignore
import { InfoSquare as InfoSquareIcon } from 'react-iconly';
import { Link as RouterLink } from 'react-router-dom';
import { filterByPerformanceAreas } from 'utils/biomarkersUtils';
import { getScoreName, SCORE } from 'utils/scoreUtils';
import {
  // Subscription,
  // useGetSubscriptionQuery
} from 'generated/graphql';

const useStyles = makeStyles((theme) =>
  createStyles({
    tooltip: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1)
    },
    title: {
      marginBottom: theme.spacing(2)
    },
    tab: {
      fontSize: 14
    },
    tabPanel: {
      padding: 0
    },
    actionsContainer: {
      marginBottom: theme.spacing(1)
    },
    actionsLinkContainer: {
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        lineHeight: '36px'
      }
    },
    actionsLink: {
      [theme.breakpoints.down('xs')]: {
        lineHeight: '36px'
      }
    },
    gradientLeft: {
      position: 'absolute',
      height: 44,
      width: 48,
      marginLeft: 0,
      marginTop: -50,
      background: theme.palette.type === 'light' ? 'linear-gradient(90deg, rgba(255, 255, 255, 0.84) 21.85%, rgba(255, 255, 255, 0) 100%)' :
        'linear-gradient(90deg, rgba(54, 53, 64, 0.84) 21.85%, rgba(54, 53, 64, 0) 100%)'
    },
    gradientRight: {
      position: 'absolute',
      height: 44,
      width: 48,
      right: 15,
      marginTop: -50,
      background: theme.palette.type === 'light' ? 'linear-gradient(270deg, rgba(255, 255, 255, 0.84) 21.85%, rgba(255, 255, 255, 0) 100%)' :
        'linear-gradient(270deg, rgba(54, 53, 64, 0.84) 21.85%, rgba(54, 53, 64, 0) 100%)'
    }
  })
);

export interface PerformanceAreasProps {
  biomarkers: Array<Biomarker>;
  testResult: TestResult;
  area?: string;
}

const PerformanceAreas: React.FC<PerformanceAreasProps> = ({ biomarkers, testResult, area }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [tab, setTab] = React.useState(area || 'all');
  const { score } = useContext(CurrentScoreContext);
  const user = useUser();
  // const { data } = useGetSubscriptionQuery({
  //   variables: {
  //     customerId: user?.stripeCustomerID
  //   }
  // });
  // const subscription = data?.getSubscription as Subscription;
  let isUndefined = false;
  if (user?.goals?.length === 0) {
    isUndefined = true;
  } else if (user?.lifestyle?.sleepDuration?.length === undefined) {
    isUndefined = true;
  } else if (user?.lifestyle?.exerciseFrequency?.length === undefined) {
    isUndefined = true;
  } else if (user?.lifestyle?.sunExposure?.length === undefined) {
    isUndefined = true;
  } else if (user?.lifestyle?.smokingHabit?.length === undefined) {
    isUndefined = true;
  } else if (user?.lifestyle?.willingToTakeSupplements?.length === undefined) {
    isUndefined = true;
  }

  // const [isShown, setIsShown] = useState(false);

  // const [display, setDisplay] = React.useState("");
  // const styles = {
  //   display: display
  // };

  const scoreCategories = Object.values(SCORE);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
  };

  return (
    <>
      <Typography variant="h3" component="h2" className={classes.title}>
        Blood test results
        <StyledTooltip
          className={classes.tooltip}
          title={<>
            <div> Your blood test results are ordered with least optimal at the top.</div>
            <br />
            <div>
              Choose a tab to filter for blood groups or specific health areas. Click on the result to show
              insights
              and trends for that test.
            </div>
          </>}>
          <InfoSquareIcon set="light" size="small" color={theme.palette.grey[500]} />
        </StyledTooltip>
      </Typography>
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="All" value="all" className={classes.tab}
            icon={<PerformanceIcon category="all" />} />
          {scoreCategories.map(category =>
            <Tab label={getScoreName(category)} value={category} key={category} className={classes.tab}
              icon={<PerformanceIcon category={category} />} />
          )}
        </Tabs>
        <HiddenCss smUp>
          <div className={classes.gradientLeft} />
          <div className={classes.gradientRight} />
        </HiddenCss>
        <Box mt={3}>
          <TabPanel value="all" className={classes.tabPanel}>
            <Grid container justify="space-between" alignItems="center" className={classes.actionsContainer}
              spacing={2}>
              <Grid item xs={12} sm>
                {score && <ScoreBadge label="Wellness score:" value={score.wellnessScore || 0} />}
              </Grid>
              <Grid item xs={12} sm={5} className={classes.actionsLinkContainer}>
                {(isUndefined) ?
                  <>

                  </> :
                  <Button component={RouterLink} to={ROUTE.ACTIONS} className={classes.actionsLink} disabled={isUndefined ? true : false}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth={isMobile}>
                    See All actions
                  </Button>
                }
              </Grid>
            </Grid>
            {biomarkers.map(biomarker => <TestResultsListItem key={biomarker.id} biomarker={biomarker}
              testResult={testResult} />)}
          </TabPanel>
          {scoreCategories.map(category =>
            <TabPanel value={category} key={category} className={classes.tabPanel}>
              <Grid container justify="space-between" alignItems="center" className={classes.actionsContainer}
                spacing={2}>
                <Grid item xs={12} sm>
                  {score && <ScoreBadge label={`${getScoreName(category)} score:`} value={score[category]}
                    icon={<PerformanceIcon category={category} />} />}
                </Grid>

                <Grid item xs={12} sm={5} className={classes.actionsLinkContainer}>
                  {(isUndefined) ?
                    <>

                    </> :
                    <Button
                      component={RouterLink}
                      to={{
                        pathname: ROUTE.ACTIONS,
                        search: `?area=${category}`
                      }}
                      className={classes.actionsLink}
                      disabled={isUndefined ? true : false}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth={isMobile}>
                      See {getScoreName(category)} actions
                    </Button>
                  }
                </Grid>

              </Grid>
              {
                filterByPerformanceAreas(biomarkers, category).map(biomarker => <TestResultsListItem key={biomarker.id}
                  biomarker={biomarker}
                  testResult={testResult} />)
              }
            </TabPanel>
          )}
        </Box>
      </TabContext >
    </>
  );
};

export default PerformanceAreas;