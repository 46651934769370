import { Palette } from '@material-ui/core/styles/createPalette';
import createTypography, { Typography, TypographyOptions } from '@material-ui/core/styles/createTypography';
import breakpoints from './breakpoints';


export const typographyOptions = (): TypographyOptions => ({
  fontFamily: 'Poppins, Verdana, sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  [breakpoints.down('xs')]: {
    fontSize: 12
  },
  h1: {
    fontWeight: 700,
    fontSize: 28,
    lineHeight: 1.5,
    [breakpoints.down('xs')]: {
      fontSize: 24
    }
  },
  h2: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.5,
    [breakpoints.down('sm')]: {
      fontSize: 20
    }
  },
  h3: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 1.5,
    [breakpoints.down('sm')]: {
      fontSize: 18
    }
  },
  h4: {
    fontSize: 16
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 500
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 600,
    [breakpoints.down('md')]: {
      fontSize: 14
    }
  },
  button: {
    fontSize: 13
  },
  body1: {
    fontSize: 13
  },
  body2: {
    fontSize: 14,
    fontWeight: 500
  }
});

const typography = (palette: Palette): Typography => createTypography(palette, typographyOptions);

export default typography;
