import { createStyles } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Customer_Shipping } from 'generated/graphql';
import React from 'react';

export interface AddressTooltipProps {
  address: Customer_Shipping
}

const useStyles = makeStyles(theme => createStyles({
  label: {
    fontWeight: 600
  }
}));

const AddressTooltip: React.FC<AddressTooltipProps> = ({ address }) => {
  const classes = useStyles();
  return address.line1 ? (<>
    <div>
      <span className={classes.label}>Address line 1:</span> {address.line1}
    </div>
    <div>
      <span className={classes.label}>Address line 2:</span> {address.line2}
    </div>
    <div>
      <span className={classes.label}>Postal code:</span> {address.postal_code}
    </div>
    <div>
      <span className={classes.label}>City:</span> {address.city}
    </div>
    <div>
      <span className={classes.label}>Country:</span> {address.country}
    </div>
  </>) : null;
};

export default AddressTooltip;