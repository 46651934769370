import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import breakpoints from './breakpoints';
import { darkPalette, lightPalette } from './palette';
import typography, { typographyOptions } from './typography';

// export const grey = '#9CB1C5';
export const grey = '#6A818F';
export const lightShadow = '0px 47px 80px rgba(156, 177, 197, 0.12), 0px 14.1691px 24.1177px rgba(156, 177, 197, 0.1), 0px 5.88513px 10.0172px rgba(156, 177, 197, 0.04), 0px -2px 3.62304px rgba(156, 177, 197, 0.06)';
export const darkShadow = '0px 47px 80px rgba(0, 0, 0, 0.12), 0px 14.1691px 24.1177px rgba(0, 0, 0, 0.1), 0px 5.88513px 10.0172px rgba(0, 0, 0, 0.04), 0px -2px 3.62304px rgba(0, 0, 0, 0.06)';

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    orange: Palette['primary'];
  }
  interface PaletteOptions {
    orange?: PaletteOptions['primary'];
  }
}

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xs: true;
    mobileM: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;

  }
}

const theme: ThemeOptions = {
  direction: 'ltr',
  spacing: 10,
  breakpoints,
  typography: typographyOptions,
  mixins: {
    toolbar: {
      minHeight: 80,
      [`${breakpoints.up('xs')} and (orientation: landscape)`]: {
        minHeight: 80
      },
      [breakpoints.up('sm')]: {
        minHeight: 80
      }
    }
  },
  shape: {
    borderRadius: 15
  },

  overrides: {
    MuiButton: {
      root: {
        padding: '8px 20px',
        textTransform: 'none',
        borderRadius: 1000,
        '&:hover': {
          boxShadow: '0 2px 8px 0 #fff',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: '0 2px 8px 0 #41B0F6',
        },
      },
      containedPrimary: {
        color: 'white',
        background: 'linear-gradient(96.74deg, #41B0F6 0%, #41B0F6 100%)',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: '0 2px 8px 0 #41B0F6',
        },
      }
    },
    MuiCssBaseline: {
      '@global': {
        fieldset: {
          margin: 0,
          padding: 0,
          paddingBottom: 15,
          border: 'none'
        },
        legend: {
          display: 'inline-flex',
          alignItems: 'center',
          padding: '15px 0',
          fontSize: 22,
          fontWeight: 700,
          [breakpoints.down('sm')]: {
            fontSize: 18
          }
        }
      }
    },
    MuiDrawer: {
      paper: {
        padding: 0
      }
    },
    MuiInputBase: {
      input: {
        padding: '8px 15px',
        fontSize: 14,
        lineHeight: '34px',
        minHeight: 34,
        '&:-webkit-autofill': {
          WebkitBoxShadow: 'inset 0 0 0 50px #fff',
          borderRadius: 'inherit'
        },
        '&:-webkit-autofill::first-line': {
          fontSize: 14,
          fontFamily: 'Poppins, Verdana, sans-serif'
        },
        [breakpoints.down('sm')]: {
          fontSize: 12,
          '&:-webkit-autofill::first-line': {
            fontSize: 12,
            fontFamily: 'Poppins, Verdana, sans-serif'
          }
        }
      }
    },
    MuiInputLabel: {
      root: {
        [breakpoints.down('sm')]: {
          fontSize: 12
        }
      },
      formControl: {
        transform: 'none',
        position: 'initial',
        paddingBottom: 10
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: 'inherit',
          fontWeight: 600
        }
      },
      selected: {}
    },
    MuiListItemText: {
      primary: {
        fontSize: 'inherit',
        fontWeight: 'inherit'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
        '&:hover $notchedOutline': {
          borderColor: grey
        },
        '&$focused $notchedOutline': {
          borderColor: grey,
          borderWidth: 1
        }
      },
      input: {
        padding: '8px 15px'
      },
      notchedOutline: {
        borderColor: 'rgba(156, 177, 197, 0.23)'
      },
      adornedEnd: {
        paddingRight: 0
      }
    },
    MuiPopover: {
      root: {
        borderRadius: 6
      }
    },
    MuiSelect: {
      select: {
        paddingRight: 30
      },
      icon: {
        fontSize: '1.3rem',
        right: 10,
        top: 'calc(50% - 11px)'
      }
    }
  },
  props: {
    MuiMenu: {
      elevation: 1
    },
    MuiButtonBase: {
      disableRipple: true
    },
  }
};
const lightTypography = typography(lightPalette);
export const lightTheme = createMuiTheme({
  ...theme,
  typography: lightTypography,
  palette: lightPalette,
  overrides: {
    ...theme.overrides,
    MuiOutlinedInput: {
      ...theme.overrides?.MuiOutlinedInput,
      input: {
        ...theme.overrides?.MuiOutlinedInput?.input,
        '&:-webkit-autofill': {
          WebkitBoxShadow: 'inset 0 0 0 50px #fff',
          borderRadius: 0
        }
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: lightShadow
      }
    },
    MuiSelect: {
      ...theme.overrides?.MuiSelect,
      icon: {
        ...theme.overrides?.MuiSelect?.icon,
        color: lightPalette.grey[500]
      }
    }
  }
});

const darkTypography = typography(darkPalette);
export const darkTheme = createMuiTheme({
  ...theme,
  typography: darkTypography,
  palette: darkPalette,
  overrides: {
    ...theme.overrides,
    MuiOutlinedInput: {
      ...theme.overrides?.MuiOutlinedInput,
      input: {
        ...theme.overrides?.MuiOutlinedInput?.input,
        '&:-webkit-autofill': {
          WebkitBoxShadow: `inset 0 0 0 50px ${darkPalette.grey[900]}`,
          borderRadius: 0
        }
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: darkShadow
      }
    },
    MuiSelect: {
      ...theme.overrides?.MuiSelect,
      icon: {
        ...theme.overrides?.MuiSelect?.icon,
        color: darkPalette.grey[500]
      }
    }
  }
});
