import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import notificationsContext from 'contexts/notificationsContext';
import firebase from 'firebase/app';
import { FormikValues, useFormik } from 'formik';
import withUser, { WithUserProps } from 'hocs/withUser';
import React, { useContext, useState } from 'react';
// @ts-ignore
import { Hide, Password, Show } from 'react-iconly';
import { useFirebase } from 'react-redux-firebase';
import { errorMsg, hasError } from 'utils/formikHelper';
import validationSchema from './profileFormPasswordValidationSchema';

const demoUser = process.env.REACT_APP_DEMO_ACCOUNT as string;

const useStyles = makeStyles(theme => createStyles({
  icon: {
    width: 16,
    height: 16,
    fontSize: 16,
    marginRight: theme.spacing(0.7),
    verticalAlign: 'middle'
  }
}));

const ProfileFormPassword: React.FC<WithUserProps> = ({ user }) => {
  const extendedFirebase = useFirebase();
  const classes = useStyles();
  const { notify } = useContext(notificationsContext);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordVisible(!isPasswordVisible);
  };
  const handleClickShowNewPassword = () => {
    setNewPasswordVisible(!isNewPasswordVisible);
  };
  const handleClickShowConfirmPassword = () => {
    setConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const formik = useFormik<FormikValues>({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema,
    onSubmit: (values) => {
      const currentUser = extendedFirebase.auth().currentUser;

      if (currentUser) {
        const credential = firebase.auth.EmailAuthProvider.credential(
          user?.email as string,
          values.currentPassword
        );
        console.log("Firebase UID is: " + user?.firebaseUID);
        if (user?.firebaseUID !== demoUser) {
          currentUser
            .reauthenticateWithCredential(credential)
            .then(
              () => currentUser.updatePassword(values.newPassword)
                .then(() => notify('Password has been changed', 'success'))
                .catch(error => {
                  if (error.code === 'auth/weak-password') {
                    formik.setFieldError('newPassword', 'Password must be at least 6 characters');
                  } else {
                    formik.setFieldError('newPassword', error.message);
                  }
                }),
              error => {
                if (error.code === 'auth/wrong-password')
                  formik.setFieldError('currentPassword', 'The current password is incorrect');
                else
                  formik.setFieldError('currentPassword', error.message);
              });

        } else {
          notify('This is a demo account and cannot be updated.', 'success');
        }
      }
    }
  });

  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
    formik.setFieldValue(event.target.name as string, event.target.value as any);
  };

  const submitDemo = () => {
    notify('This is a demo account and cannot be changed.', 'success');
  };

  return <form onSubmit={formik.handleSubmit}>
    <fieldset>
      <legend>
        Update password
      </legend>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth error={hasError(formik, 'currentPassword')}>
            <InputLabel shrink={false}><Password className={classes.icon} />Current password</InputLabel>
            <OutlinedInput name="currentPassword" value={formik.values.currentPassword} onChange={handleChange}
              type={isPasswordVisible ? 'text' : 'password'}
              endAdornment={<InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  size="small"
                >
                  {isPasswordVisible ? <Show primaryColor="#9CB1C5" set="light" /> :
                    <Hide primaryColor="#9CB1C5" set="light" />}
                </IconButton>
              </InputAdornment>} />
            <FormHelperText>{errorMsg(formik, 'currentPassword')}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth error={hasError(formik, 'newPassword')}>
            <InputLabel shrink={false}><Password className={classes.icon} />New password</InputLabel>
            <OutlinedInput name="newPassword" value={formik.values.newPassword} onChange={handleChange}
              type={isNewPasswordVisible ? 'text' : 'password'}
              endAdornment={<InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownPassword}
                  size="small"
                >
                  {isNewPasswordVisible ? <Show primaryColor="#9CB1C5" set="light" /> :
                    <Hide primaryColor="#9CB1C5" set="light" />}
                </IconButton>
              </InputAdornment>} />
            <FormHelperText>{errorMsg(formik, 'newPassword')}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth error={hasError(formik, 'confirmNewPassword')}>
            <InputLabel shrink={false}><Password className={classes.icon} />Confirm new password</InputLabel>
            <OutlinedInput name="confirmNewPassword" value={formik.values.confirmNewPassword} onChange={handleChange}
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              endAdornment={<InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  size="small"
                >
                  {isConfirmPasswordVisible ? <Show primaryColor="#9CB1C5" set="light" /> :
                    <Hide primaryColor="#9CB1C5" set="light" />}
                </IconButton>
              </InputAdornment>} />
            <FormHelperText>{errorMsg(formik, 'confirmNewPassword')}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} container justify="center">
          <Grid item>
            {user.firebaseUID !== demoUser
              ? <Button color="primary" variant="contained" type="submit">Update password</Button>
              : <Button color="primary" variant="contained" onClick={submitDemo}>Update password</Button>
            }
          </Grid>
        </Grid>
      </Grid>
    </fieldset>
  </form>;
};

export default withUser(ProfileFormPassword);