import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';
import { GetUserDocument, GetUserQuery, User } from 'generated/graphql';
import { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';

export type useUserType = () => User | null;

const useUser: useUserType = () => {
  const [user, setUser] = useState<User | null>(null)
  const currentUser = useFirebase().auth().currentUser;
  const client = useApolloClient();

  if (currentUser) {
    client.query<GetUserQuery>({
      query: GetUserDocument,
      variables: {
        firebaseUID: currentUser.uid
      }
    }).then(({ data }) => setUser(data.getUser));
  }

  return user;
}

export default useUser;