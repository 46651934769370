import * as yup from 'yup';
import 'yup-phone';

const validationSchema = yup.object({
  email: yup.string().required('Email is a required field').email('his must be a valid email address'),
  firstName: yup.string().required('First name is a required field'),
  lastName: yup.string().required('Last name is a required field'),
  gender: yup.string().required(),
  // phone: yup.string().phone('UK', true, 'This must be a valid UK phone number').required(),
  phone: yup.string().required(),
  dob: yup.string().required()

});

export default validationSchema;