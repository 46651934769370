import { Box, createStyles, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import clsx from 'clsx';
import FilterMenu from 'components/FilterMenu';
import { FiltersOptions } from 'components/FilterMenu/FilterMenu';
import Loading from 'components/Loading';
import StyledTooltip from 'components/StyledTooltip/StyledTooltip';
import { Action } from 'generated/graphql';
import useURLSearchParams from 'hooks/useURLSearchParams';
import { FunnelSimple } from 'phosphor-react';
import { allPass, anyPass, filter } from 'ramda';
import React, { useEffect, useState } from 'react';
// @ts-ignore
import { InfoSquare as InfoSquareIcon } from 'react-iconly';
import { SCORE } from 'utils/scoreUtils';
import { capitalize } from 'utils/string';
import ActionsListItem from '../ActionsListItem';

export interface ActionsListProps {
  actions?: Array<Action>;
  title: string
  tooltip?: any;
  description?: string;
}

const filtersConfig: Array<FiltersOptions> = [
  {
    key: 'area',
    label: 'Health area',
    filters: Object.values(SCORE).map(score => ({ key: score, label: capitalize(score) }))
  },
  {
    key: 'frequency',
    label: 'Frequency',
    filters: [
      {
        key: 'daily',
        label: 'Daily'
      },
      {
        key: '3times',
        label: 'Thrice a week'
      },
      {
        key: '2times',
        label: 'Twice a week'
      },
      {
        key: 'weekly',
        label: 'Once a week'
      }
    ]
  },
  {
    key: 'impactRanking',
    label: 'Impact score',
    filters: [
      {
        key: 'veryHigh',
        label: 'Very high'
      },
      {
        key: 'high',
        label: 'High'
      },
      {
        key: 'medium',
        label: 'Medium'
      },
      {
        key: 'low',
        label: 'Low'
      }
    ]
  }
];

const useStyles = makeStyles((theme) =>
  createStyles({
    tooltip: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1)
    },
    filter: {
      verticalAlign: 'middle',
      float: 'right',
      cursor: 'pointer',
      color: theme.palette.grey[500]
    },
    activeFilter: {
      color: theme.palette.primary.main
    },
    title: {
      marginBottom: theme.spacing(2)
    }
  })
);

const impactRankingMap: any = {
  'low': 1,
  'medium': 2,
  'high': 3,
  'veryHigh': 4
};

const frequencyRankingMap: any = {
  'daily': 'Daily',
  '3times': 'Three times a week',
  '2times': 'Twice a week',
  'weekly': 'Weekly'
};

const generatePredicate = (values: any) => {
  const predicatesArray: Array<() => boolean> = [];

  if (values.area) {
    const areaFilters = Object
      .entries(values.area)
      .filter(([key, value]) => value);

    if (areaFilters.length) {
      predicatesArray.push(anyPass(areaFilters.map(([key, value]) => (a: Action) => a.performanceAreas.includes(key))));
    }
  }

  if (values.frequency) {
    const frequencyFilters = Object
      .entries(values.frequency)
      .filter(([key, value]) => value);

    if (frequencyFilters.length) {
      predicatesArray.push(anyPass(frequencyFilters.map(([key, value]) => (a: Action) => a.frequency === frequencyRankingMap[key])));
    }
  }

  if (values.impactRanking) {
    const impactRankingFilters = Object
      .entries(values.impactRanking)
      .filter(([key, value]) => value);

    if (impactRankingFilters.length) {
      predicatesArray.push(anyPass(impactRankingFilters.map(([key, value]) => (a: Action) => a.impactRanking === impactRankingMap[key])));
    }
  }

  return allPass(predicatesArray);
};

const hasAnyFilter = (values: any) => (Object.values(values).reduce((currV: any, v: any) => currV.concat(Object.values(v)), []) as Array<boolean>).some((v: boolean) => v);

const ActionsList: React.FC<ActionsListProps> = ({ actions, title, description = '', tooltip }) => {
  const area = useURLSearchParams().get('area') as string;
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | SVGSVGElement>(null);
  const [filteredActions, setFilteredActions] = useState(actions);
  const [initialFilters, setInitialFilters] = useState<any>({});
  const [hasFilters, setHasFilters] = useState(false);

  useEffect(() => {
    if (actions) {
      setFilteredActions(actions);
    }
  }, [actions]);

  useEffect(() => {
    if (area) {
      setInitialFilters({
        ...initialFilters, area: {
          ...initialFilters.area,
          [area]: true
        }
      });
      setHasFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  useEffect(() => {
    if (actions) {
      setFilteredActions(filter(generatePredicate(initialFilters), actions));
    }
  }, [initialFilters, actions]);

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const applyFilters = (values: any) => {
    if (actions) {
      setFilteredActions(filter(generatePredicate(values), actions));
    }

    setHasFilters(hasAnyFilter(values));
  };

  let content;

  if (actions) {
    if (actions.length) {
      if (filteredActions && filteredActions.length)
        content = filteredActions.map(action => (
          <ActionsListItem key={action.id} action={action} />
        ));
      else
        content =
          <Box pt={4}>Try removing some of the filters to see your actions. Click on the three horizontal lines icon at
            the top right to see which filters are applied.</Box>;
    } else
      content = <Box pt={4}>You have no suggested actions for this category.</Box>;
  } else
    content = <Box pt={4}><Loading /></Box>;

  return (
    <>
      <Typography variant="h3" component="h2" className={classes.title}>
        {title}
        {tooltip && <StyledTooltip
          className={classes.tooltip}
          title={tooltip}>
          <InfoSquareIcon set="light" size="small" color={theme.palette.grey[500]} />
        </StyledTooltip>}
        <FunnelSimple className={clsx([classes.filter, hasFilters && classes.activeFilter])} onClick={handleClick} />
        <FilterMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          options={filtersConfig}
          onChange={applyFilters}
          initialValue={initialFilters}
        />
      </Typography>
      <Box mb={2}>
        {description}
      </Box>
      <HiddenCss mdDown>
        <Box pl={2} pr={2}>
          <Grid container>
            <Grid item xs><Typography variant="subtitle1">Action</Typography></Grid>
            <Grid item xs={2}><Typography variant="subtitle1">Frequency</Typography></Grid>
            <Grid item xs={2}><Typography variant="subtitle1">Impact score</Typography></Grid>
            <Grid item xs={2}><Typography variant="subtitle1">Recommended</Typography></Grid>
            <Grid item><Box width={51} /></Grid>
          </Grid>
        </Box>
      </HiddenCss>
      {content}
    </>
  );
};

export default ActionsList;