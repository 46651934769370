import { Box, createStyles, Grid, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import StyledPaper from 'components/StyledPaper';
import CurrentSubscription from 'pages/Profile/Subscriptions/CurrentSubscription';
import CurrentTest from 'pages/Profile/Subscriptions/CurrentTest';
import InvoicesTable from 'pages/Profile/Subscriptions/InvoicesTable';
import React from 'react';
import { fbanalytics } from '../../../config/firebase'

const useStyles = makeStyles(theme => createStyles({
  invoicesCell: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      height: 4,
      borderRadius: 2
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 2
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.divider
    }
  },
  testPaper: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 700
    }
  }
}));

const Subscriptions: React.FC = () => {
  const classes = useStyles();
  fbanalytics.logEvent('billing_visit');
  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <StyledPaper elevation={0} variant="outlined">
        <CurrentSubscription />
      </StyledPaper>
    </Grid>
    <Grid item xs={12}>
      <Box className={classes.invoicesCell} pb={1.5}>
        <StyledPaper elevation={0} variant="outlined" className={classes.testPaper}>
          <CurrentTest />
        </StyledPaper>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h3">
        Invoices
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Box className={classes.invoicesCell}>
        <InvoicesTable />
      </Box>
    </Grid>
  </Grid>;
};

export default Subscriptions;