import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';
import {
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme,
  Button,
  // Tooltip,
  // IconButton
} from '@material-ui/core';
import { ROUTE } from 'config/routes';
// import InfoTooltip from 'components/InfoTooltip';
// import Snackbar from 'components/Snackbar';
import { ArrowsClockwise } from 'phosphor-react';
import useUser from 'hooks/useUser';
import React, { useState } from 'react';
import { fbanalytics } from '../../config/firebase'
import {
  Activity as ActivityIcon,
  Chart as ChartIcon,
  Home as HomeIcon,
  Logout as LogoutIcon,
  User as UserIcon
  // @ts-ignore
} from 'react-iconly';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
// import { isUndefined } from 'util';
import {
  // Plan,
  StartPaymentSessionDocument,
  StartPaymentSessionQuery,
  Subscription,
  useGetSubscriptionQuery
} from 'generated/graphql';


const useStyles = makeStyles((theme) =>
  createStyles({
    tabIcon: {
      fontSize: 'inherit',
      marginRight: theme.spacing(1),
      '&:hover': {
        transform: 'rotate(45deg)',
        transition: 'all 0.75s ease-in -out',
      },
    },
    root: {
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        height: 'auto'
      }
    },
    list: {
      padding: 0
    },
    listItemIcon: {
      color: 'inherit',
      minWidth: theme.spacing(4)
    },
    listItemText: {
      color: 'inherit',
      fontSize: 16,
      '&:hover': {
        textDecoration: 'none'
      }
    }
  })
);

const useListItemStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(4)
      },
      marginBottom: 10,
      '&:after': {
        content: '" "',
        position: 'absolute',
        left: 0,
        top: 0,
        width: 5,
        height: '100%',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        transform: 'translateX(-100%)'
      }
    },
    selected: {
      '&.Mui-selected': {
        background: 'initial',
        color: theme.palette.primary.main,
        '&:hover, &:focus': {
          background: 'initial'
        },
        '&:after': {
          background: theme.palette.primary.main,
          transform: 'translateX(0)'
        }
      }
    }
  })
);




const SideMenu: React.FC = () => {
  const demoUser = process.env.REACT_APP_DEMO_ACCOUNT as string;
  const client = useApolloClient();
  const user = useUser();
  const classes = useStyles();
  const listItemStyles = useListItemStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  // const { data } = useGetSubscriptionQuery({
  //   variables: {
  //     customerId: user?.stripeCustomerID
  //   }
  // });
  // const subscription = data?.getSubscription as Subscription;
  let isUndefined = false;
  if (user?.goals?.length === 0) {
    isUndefined = true;
  } else if (user?.lifestyle?.sleepDuration?.length === undefined) {
    isUndefined = true;
  } else if (user?.lifestyle?.exerciseFrequency?.length === undefined) {
    isUndefined = true;
  } else if (user?.lifestyle?.sunExposure?.length === undefined) {
    isUndefined = true;
  } else if (user?.lifestyle?.smokingHabit?.length === undefined) {
    isUndefined = true;
  } else if (user?.lifestyle?.willingToTakeSupplements?.length === undefined) {
    isUndefined = true;
  }

  const [isShown, setIsShown] = useState(false);

  // const [display, setDisplay] = React.useState("");
  // const styles = {
  //   display: display
  // };

  // eslint-disable-next-line no-lone-blocks
  {/*}
  const onManageClick = () => {
    client.query<StartPaymentSessionQuery>({
      query: StartPaymentSessionDocument,
      variables: {
        customerId: user?.stripeCustomerID + "|" + user?.gender + "|" + user?.dob,
      }
    }).then(data => {
      const PaymentSession = data.data.startPaymentSession;
      if (PaymentSession?.url) {
        window.open(PaymentSession.url);
      }
    });

  };
*/}
  return (

    <Grid container direction="row" alignContent={isDesktop ? 'space-between' : 'flex-start'}
      className={classes.root}>
      <Grid item xs>
        <List className={classes.list}>
          <ListItem button selected={useRouteMatch(ROUTE.HOME)?.isExact} classes={listItemStyles}
            component={NavLink} to={ROUTE.HOME}>
            <ListItemIcon className={classes.listItemIcon}>
              <HomeIcon set="light" size={22} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            key="Insights"
            classes={listItemStyles}
            selected={useRouteMatch([ROUTE.INSIGHTS_PERFORMANCE_AREA, ROUTE.INSIGHTS])?.isExact}
            component={NavLink}
            to={ROUTE.INSIGHTS}>
            <ListItemIcon className={classes.listItemIcon}>
              <ChartIcon set="light" size={22} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="Results" />
          </ListItem>

          <div onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>

            {isShown && (
              <div style={{
                backgroundColor: '#fff',
                color: '#000',
                borderRadius: 6,
                fontWeight: 400,
                fontSize: 13,
                padding: 10,
                // border: '1px dashed #292830',
                boxShadow: '0px 47px 80px rgb(156 177 197 / 22%), 0px 14.1691px 24.1177px rgb(156 177 197 / 10%), 0px 5.88513px 10.0172px rgb(156 177 197 / 4%), 0px -2px 3.62304px rgb(156 177 197 / 6%)',
                // boxShadow: '0 1px 2px rgba(0,0,0,0.07),0 2px 4px rgba(0,0,0,0.07),0 4px 8px rgba(0,0,0,0.07),0 8px 16px rgba(0,0,0,0.07),0 16px 32px rgba(0,0,0,0.07),0 32px 64px rgba(0,0,0,0.07)',
                position: 'absolute',
                marginRight: '15px',
                marginLeft: '15px',
                zIndex: 1,
                visibility: isUndefined ? 'visible' : 'hidden',
              }}>
                Complete your <Link to={{
                  pathname: ROUTE.PROFILE,
                }}> lifestyle actions
                </Link> to see your actions.
              </div>
            )}

            <ListItem button classes={listItemStyles} selected={useRouteMatch(ROUTE.ACTIONS)?.isExact} disabled={isUndefined ? true : false}


              component={NavLink} to={ROUTE.ACTIONS}>
              <ListItemIcon className={classes.listItemIcon}>
                <ActivityIcon set="light" size={22} />
              </ListItemIcon>
              <ListItemText primary="Actions" className={classes.listItemText} />
            </ListItem>

          </div>


          <ListItem button classes={listItemStyles}
            selected={useRouteMatch([ROUTE.PROFILE_TAB, ROUTE.PROFILE])?.isExact}
            component={NavLink} to={ROUTE.PROFILE}>
            <ListItemIcon className={classes.listItemIcon}>
              <UserIcon set="light" size={22} />
            </ListItemIcon>
            <ListItemText primary="Profile" className={classes.listItemText} />
          </ListItem>
          {/*
          {
            ((user?.firebaseUID === demoUser) || (subscription && subscription.plan?.interval)) ?
              <>

              </>
              : <>  <br />

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    // type="submit"
                    // variant="contained"
                    // color="primary"
                    onClick={onManageClick}
                    style={{
                      width: '165px',
                      fontSize: '15px',
                      // fontWeight: '600',
                      color: '#fff',
                      cursor: 'pointer',
                      margin: '18px',
                      height: '40px',
                      textAlign: 'center',
                      border: 'none',
                      backgroundSize: '300% 100%',
                      borderRadius: '50px',
                      // mozTransition: 'all .4s ease-in-out',
                      transition: 'all .4s ease-in-out',
                      backgroundImage: 'linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673)',
                      boxShadow: '0 4px 15px 0 rgba(49, 196, 190, 0.75)'
                    }}
                  >
                    <ArrowsClockwise weight="light" className={classes.tabIcon} />Order new test
                  </Button>
                </div>
                <br /><br /></>
          }

          {
            user?.firebaseUID === demoUser ?
              <>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    // type="submit"
                    // variant="contained"
                    // color="primary"
                    onClick={() => {
                      fbanalytics.logEvent('demo_account_cta_click');
                    }}
                    href="https://elevateme.ai/health-plans/"
                    target="_blank"
                    style={{
                      width: '175px',
                      fontSize: '15px',
                      // fontWeight: '600',
                      color: '#fff',
                      cursor: 'pointer',
                      margin: '18px',
                      height: '45px',
                      textAlign: 'center',
                      border: 'none',
                      backgroundSize: '300% 100%',
                      borderRadius: '50px',
                      // mozTransition: 'all .4s ease-in-out',
                      transition: 'all .4s ease-in-out',
                      backgroundImage: 'linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673)',
                      // boxShadow: '0 4px 15px 0 rgba(49, 196, 190, 0.75)'

                    }}
                  >
                    Order Health Test
                  </Button>
                </div>
                <br /><br />
              </>
              : <></>
          }
        */}
        </List>
      </Grid >
      <Grid item xs>
        <List className={classes.list}>
          <ListItem button classes={listItemStyles} component={NavLink} to={ROUTE.LOGOUT}>
            <ListItemIcon className={classes.listItemIcon}>
              <LogoutIcon set="light" size={22} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="Log out" />
          </ListItem>
        </List>
      </Grid>
    </Grid >
  );
};

export default SideMenu;