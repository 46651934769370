export enum ACTION_TYPES {
  SHOW_PAGE = 'demo/SHOW_PAGE',
  RESET = 'demo/RESET'
}

export enum PAGE {
  DASHBOARD = 'dashboard',
  ACTIONS = 'actions',
  INSIGHTS = 'insights'
}

export const showPage = (page: PAGE) => ({
  type: ACTION_TYPES.SHOW_PAGE,
  payload: page
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
