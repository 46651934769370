import { Box, Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import ElevateTooltip from 'components/ElevateTooltip';
import React from 'react';
import {
  getIntervalPercentage,
  getQualityColor,
  getValueInterval,
  getValueQuality,
  ResultInterval
} from 'utils/biomarkersUtils';

const MIN_WIDTH = 25;

const useStyles = makeStyles((theme) => ({
  bar: {
    marginTop: 22,
    flexWrap: 'nowrap',
    height: 8
  },
  interval: {
    marginRight: 3,
    minWidth: MIN_WIDTH,
    overflow: 'visible',
    '&:first-child': {
      borderBottomLeftRadius: 6,
      borderTopLeftRadius: 6
    },
    '&:last-child': {
      marginRight: 0,
      borderBottomRightRadius: 6,
      borderTopRightRadius: 6
    }
  },
  value: {
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    borderWidth: 2.5,
    borderStyle: 'solid',
    width: 16,
    height: 16,
    top: -4
  },
  legendContainer: {
    marginTop: 4,
    height: 18,
    flexWrap: 'nowrap'
  },
  legend: {
    position: 'absolute',
    color: theme.palette.grey[500],
    fontSize: 12,
    display: 'inline-block',
    width: 20,
    marginLeft: -10,
    textAlign: 'center'
  },
  legendItem: {
    minWidth: MIN_WIDTH,
    '&:nth-child(even)': {
      [theme.breakpoints.down('xs')]: {
        marginTop: -32
      }
    }
  },
  lastLegend: {
    flexGrow: 0,
    minWidth: 0
  }
}));

export interface TestResultBarProps {
  intervals: Array<ResultInterval>
  value: number
  unit: string
}

const TestResultBar: React.FC<TestResultBarProps> = ({ intervals, value, unit }) => {
  const classes = useStyles();
  const quality = getValueQuality(intervals, value);
  const valueInterval = getValueInterval(intervals, value) as ResultInterval;
  const valuePosition = (value - valueInterval.lower) / (valueInterval.upper - valueInterval.lower) * 100;

  return <>
    <Grid container className={classes.bar}>
      {intervals.map(interval =>
        <Grid item key={interval.quality} className={classes.interval} style={{
          width: `${getIntervalPercentage(interval, intervals)}%`,
          backgroundColor: getQualityColor(interval.quality)
        }}>
          {quality === interval.quality && (
            <ElevateTooltip title={`${value} ${unit}`} placement="top" enterTouchDelay={10} leaveTouchDelay={10000}>
              <Box className={classes.value} style={{
                borderColor: getQualityColor(quality),
                left: `calc(${valuePosition}% - 12px)`
              }}/>
            </ElevateTooltip>
          )}
        </Grid>
      )}
    </Grid>
    <Grid container className={classes.legendContainer}>
      {intervals.map(interval =>
        <Grid item key={interval.quality} className={classes.legendItem} style={{
          width: `${getIntervalPercentage(interval, intervals)}%`
        }}>
          <Box component="span" className={classes.legend}>{interval.lower}</Box>
        </Grid>
      )}
      <Grid item
            className={clsx(classes.legendItem, classes.lastLegend)}>
        <Box component="span" className={classes.legend}>{intervals[intervals.length - 1].upper}</Box>
      </Grid>
    </Grid>
  </>;
};

export default TestResultBar;