import { SvgIconProps } from '@material-ui/core/SvgIcon';
import UIcon from 'icons/uicons/UIcon';
import React from 'react';

const MedicineIcon = (props: SvgIconProps) => (
  <UIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M16,7l-.023-1.177A2.992,2.992,0,0,0,15,0H9A2.993,2.993,0,0,0,8,5.816V7a5.006,5.006,0,0,0-5,5v7a5.006,5.006,0,0,0,5,5h8a5.006,5.006,0,0,0,5-5V12A5.006,5.006,0,0,0,16,7ZM9,2h6a1,1,0,0,1,0,2H9A1,1,0,0,1,9,2ZM19,19a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3V12A3,3,0,0,1,8,9H9a1,1,0,0,0,1-1V6h3.973L14,8.014A1,1,0,0,0,15,9h1a3,3,0,0,1,3,3Zm-3-4a1,1,0,0,1-1,1H13v2a1,1,0,0,1-2,0V16H9a1,1,0,0,1,0-2h2V12a1,1,0,0,1,2,0v2h2A1,1,0,0,1,16,15Z"/>
  </UIcon>
);

export default MedicineIcon;