import { Grid, GridProps, Typography, withStyles } from '@material-ui/core';
import GridSectionTitle from 'components/GridSectionTitle';
import React from 'react';

interface GridSectionProps extends GridProps {
  title: string,
}

const GridSection = withStyles(({ palette }) => ({
  root: { color: palette.grey[500] }
}))(({
       title,
       classes,
       children,
       ...props
     }: GridSectionProps) => {
  return <Grid {...props}>
    <GridSectionTitle>{title}</GridSectionTitle>
    <Typography className={classes?.root}>{children}</Typography>
  </Grid>;
});

export default GridSection;