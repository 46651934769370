import { createStyles, IconButton, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import { ROUTE } from 'config/routes';
import React from 'react';
// @ts-ignore
import { Activity as ActivityIcon, Chart as ChartIcon } from 'react-iconly';
import { generatePath, Link } from 'react-router-dom';
import useUser from 'hooks/useUser';
import {
  // Subscription,
  // useGetSubscriptionQuery
} from 'generated/graphql';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      verticalAlign: 'middle',
      marginRight: theme.spacing(0.8)
    },
    link: {
      textDecoration: 'inherit',
      color: 'inherit'
    }
  })
);



const ScoreButton: React.FC<{ items: Array<string>, menuId: string }> = ({ items, menuId }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const user = useUser();
  // const { data } = useGetSubscriptionQuery({
  //   variables: {
  //     customerId: user?.stripeCustomerID
  //   }
  // });
  // const subscription = data?.getSubscription as Subscription;
  let isUndefined = false;
  if (user?.goals?.length === 0) {
    isUndefined = true;
  } else if (user?.lifestyle?.sleepDuration?.length === undefined) {
    isUndefined = true;
  } else if (user?.lifestyle?.exerciseFrequency?.length === undefined) {
    isUndefined = true;
  } else if (user?.lifestyle?.sunExposure?.length === undefined) {
    isUndefined = true;
  } else if (user?.lifestyle?.smokingHabit?.length === undefined) {
    isUndefined = true;
  } else if (user?.lifestyle?.willingToTakeSupplements?.length === undefined) {
    isUndefined = true;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="small"
        aria-label="more"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizOutlinedIcon />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        elevation={1}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Link to={generatePath(ROUTE.INSIGHTS_PERFORMANCE_AREA, {
            areaKey: menuId
          })} className={classes.link}>
            <ChartIcon set="light" size={18} className={classes.icon} />{items[0]}
          </Link>
        </MenuItem>

        <MenuItem onClick={handleClose} disabled={isUndefined ? true : false}>
          <Link to={{
            pathname: ROUTE.ACTIONS,
            search: `?area=${menuId}`
          }} className={classes.link}
          >
            <ActivityIcon set="light" size={18} className={classes.icon} />{items[1]}
          </Link>
        </MenuItem>


      </Menu>
    </>
  );
};

export default ScoreButton;