import { ACTION_TYPES } from 'actions/userPreferences';
import { AnyAction } from 'redux';

const savedState = window.localStorage.getItem('UserPreferencesState');

const initialState = {
  darkMode: false
};

export type UserPreferencesState = Readonly<typeof initialState>;
const loadedState = savedState ? JSON.parse(savedState) as UserPreferencesState : initialState;

const userPreferencesReducer = (state: UserPreferencesState = loadedState, action: AnyAction): UserPreferencesState => {
  let newState = state;
  switch (action.type) {
    case ACTION_TYPES.TOGGLE_DARK_MODE:
      newState = {
        darkMode: action.payload
      };
      break;
    default:
      return newState;
  }

  window.localStorage.setItem('UserPreferencesState', JSON.stringify(newState));
  return newState;
}

export default userPreferencesReducer;