import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import { PAGE, showPage } from 'actions/demo';
import DemoMessage from 'components/DemoMessage';
import React from 'react';
import { useDispatch } from 'react-redux';

const DemoInsights: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(showPage(PAGE.INSIGHTS));
  };

  const button1 = <Button
    variant="contained"
    color="primary"
    fullWidth={isMobile}
    onClick={handleClick}
  >
    Demo Results
  </Button>;
  const button2 = <Button
    variant="contained"
    color="primary"
    fullWidth={isMobile}
    href="https://www.elevateme.ai/elevateme-blood-tests/"
    target="_blank"
  >
    Blood test reading
  </Button>;

  return <DemoMessage
    title="Your ElevateMe Results"
    body1="Your blood test results and insights will appear here once available."
    body2="For now, check out the demo account results page or read up on the blood markers you've tested for:"
    button1={button1}
    button2={button2}
  />;
};

export default DemoInsights;