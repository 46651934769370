import { Box, createStyles, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PAGE } from 'actions/demo';
import DemoBanner from 'components/DemoBanner';
import PageTitle from 'components/PageTitle';
import ScoresSelect from 'components/ScoresSelect';
import withDemoAccount from 'hocs/withDemoAccount';
import withUser from 'hocs/withUser';
import DemoDashboard from 'pages/Home/components/DemoDasboard';
import PerformanceScores from 'pages/Home/components/PerformanceScores';
import PerformanceTrends from 'pages/Home/components/PerformanceTrends';
import ProfileCard from 'pages/Home/components/ProfileCard';
import WellnessScore from 'pages/Home/components/WellnessScore';
import React from 'react';
import { fbanalytics } from '../../config/firebase'

const useStyles = makeStyles((theme) => createStyles({
  dashboard: {
    minHeight: '100vh',
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 5,
      paddingRight: 5
    }
  },
  leftSections: {
    flexWrap: 'nowrap',
    flexDirection: 'row-reverse',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 480,
      flexDirection: 'column'
    }
  },
  profileCard: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      marginBottom: theme.spacing(2)
    }
  },
  trendsSection: {
    height: '100%'
  }
}));

const Home: React.FC = () => {
  const classes = useStyles();
  fbanalytics.logEvent('homepage_visit');
  return (<>
    <DemoBanner page={PAGE.DASHBOARD} />
    <PageTitle title="Dashboard">
      <Box pl={2} component="span">
        <ScoresSelect />
      </Box>
    </PageTitle>
    <Grid container spacing={2} className={classes.dashboard}>

      <Grid item xs={12} lg={5} className={classes.leftSections} container>
        <Hidden xsDown>
          <Grid item xs={12} sm={6} lg={12} className={classes.profileCard}>
            <ProfileCard />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6} lg={12}>
          <WellnessScore />
        </Grid>
      </Grid>
      <Grid item xs={12} lg>
        <PerformanceScores />
      </Grid>
      <Grid item xs={12} className={classes.trendsSection}>
        <PerformanceTrends />
      </Grid>
    </Grid>
  </>);
};

export default withUser(withDemoAccount(Home, DemoDashboard, PAGE.DASHBOARD));
