import { SvgIconProps } from '@material-ui/core/SvgIcon';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { ArrowsClockwise, Barbell, Bed, CirclesThree, Lightning, ShieldCheck, Smiley } from 'phosphor-react';
import React from 'react';
import { SCORE } from 'utils/scoreUtils';

export interface PerformanceIconProps extends SvgIconProps {
  category: SCORE | 'all';
}

const PerformanceIcon: React.FC<PerformanceIconProps> = ({ category, ...other }) => {
  switch (category) {
    case 'all':
      return <FormatListBulletedIcon {...other}/>;
    case SCORE.COGNITION:
      return <CirclesThree {...other} weight="light"/>;
    case SCORE.ENERGY:
      return <Lightning {...other} weight="light"/>;
    case SCORE.FITNESS:
      return <Barbell {...other} weight="light"/>;
    case SCORE.IMMUNITY:
      return <ShieldCheck {...other} weight="light"/>;
    case SCORE.METABOLISM:
      return <ArrowsClockwise {...other} weight="light"/>;
    case SCORE.MOOD:
      return <Smiley {...other} weight="light"/>;
    case SCORE.SLEEP:
      return <Bed {...other} weight="light"/>;
  }
};

export default PerformanceIcon;