import { PAGE } from 'actions/demo';
import DemoOverlay from 'components/DemoOverlay';
import { WithUserProps } from 'hocs/withUser';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const withDemoAccount = <P extends WithUserProps>(WrappedComponent: React.ComponentType<P>, DemoComponent: React.ComponentType, page: PAGE): React.FC<P> => {
  const WithDemoAccount = (props: P) => {
    const { user } = props;
    const isVisible = useSelector((state: IRootState) => state.demo[page]);

    if (user.demoUser && !isVisible) {
      return (
        <>
          <WrappedComponent {...props} />
          <DemoOverlay>
            <DemoComponent/>
          </DemoOverlay>
        </>
      );
    } else {
      return <>
        <WrappedComponent {...props} />
      </>;
    }
  };

  return WithDemoAccount;
};

export default withDemoAccount;