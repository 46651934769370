import { reset } from 'actions/demo';
import CurrentScoreContext from 'contexts/currentScore';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';

const Logout: React.FC = () => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const { setScore } = useContext(CurrentScoreContext);

  useEffect(() => {
    setScore(null);
    dispatch(reset());
    firebase.logout();
  });

  return null;
};
export default Logout;