import { createContext } from 'react';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export type NotificationsContext = {
  text: string,
  severity: NotificationType,
  notify: (text: string, severity: NotificationType) => void,
  clear: () => void
}

const notificationsContext = createContext<NotificationsContext>({
  text: '',
  severity: 'info',
  notify: (text: string, severity: NotificationType) => {},
  clear: () => {}
});

export const NotificationsProvider = notificationsContext.Provider;
export const NotificationsConsumer = notificationsContext.Consumer;
export default notificationsContext;
