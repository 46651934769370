import { SvgIcon, withStyles } from '@material-ui/core';

const UIcon = withStyles({
  root: {
    overflow: 'visible',
    stroke: 'currentColor',
    strokeWidth: 0,
    fontSize: '1em'
  }
})(SvgIcon);

export default UIcon;