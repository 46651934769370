import { Button, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import StyledPaper from 'components/StyledPaper';
import StyledTooltip from 'components/StyledTooltip';
import { ROUTE } from 'config/routes';
import CurrentScoreContext from 'contexts/currentScore';
import React, { useContext } from 'react';
// @ts-ignore
import { InfoSquare as InfoSquareIcon } from 'react-iconly';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundImage: 'url(/assets/wellness-bg.svg), linear-gradient(104.14deg, #41B0F6 0%, #25EB9A 103.42%)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: '100% 50%',
      color: 'white',
      height: '100%',
      minHeight: 224,
      boxShadow: theme.palette.type === 'light' ? '20px 20px 80px rgb(65 176 246 / 24%), 0px 14.1691px 24.1177px rgb(182 224 251 / 22%), 0px 5.88513px 10.0172px rgb(87 126 162 / 4%), 0px -2px 3.62304px rgb(62 126 187 / 16%)' : 'none'
    },
    container: {
      height: '100%'
    },
    tooltip: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1)
    },
    score: {
      fontSize: 61
    },
    button: {
      background: theme.palette.common.white,
      color: theme.palette.common.black,
      width: 126,
      height: 38,
      '&:hover': {
        background: 'white'
      }
    }
  })
);

const WellnessScore: React.FC = () => {
  const classes = useStyles();
  const { score } = useContext(CurrentScoreContext);

  return (
    <StyledPaper className={classes.root}>
      <Grid container direction="row" justify="space-between"
        alignItems="center" className={classes.container}>
        <Grid item>
          <Typography variant="h3" component="h2">Wellness score
            <StyledTooltip
              className={classes.tooltip}
              placement="right"
              title="An average of all your Performance Scores, used to measure overall wellness improvements over time.">
              <InfoSquareIcon set="light" size="small" />
            </StyledTooltip>
          </Typography>
          <Typography variant="h3" component="div"
            className={classes.score}>{score && `${score?.wellnessScore}%`}</Typography>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            type="submit"
            component={RouterLink}
            to={ROUTE.INSIGHTS}
          >
            View insights
          </Button>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default WellnessScore;