import createBreakpoints, { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';


export const MOBILE_M = 370;

const breakpointsOptions: BreakpointsOptions = {
  keys: ['xs', 'mobileM', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 0,
    mobileM: MOBILE_M,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
};

const breakpoints = createBreakpoints(breakpointsOptions);

export default breakpoints;
