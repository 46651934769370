import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Theme,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FormikValues, useFormik } from 'formik';
import useURLSearchParams from 'hooks/useURLSearchParams';
import React, { useState } from 'react';
// @ts-ignore
import { Hide, Password, Show } from 'react-iconly';
import { useFirebase } from 'react-redux-firebase';
import { errorMsg, hasError } from 'utils/formikHelper';
import validationSchema from './resetPasswordValidationSchema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: 'auto',
      marginBottom: theme.spacing(2)
    },
    form: {
      width: '100%'
    },
    input: {
      maxWidth: 500,
      width: '100%'
    },
    buttonContainer: {
      textAlign: 'center'
    }
  })
);

export const ResetPassword: React.FC = () => {
  const firebase = useFirebase();
  const code = useURLSearchParams().get('oobCode') as string;
  const classes = useStyles();
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handleClickShowConfirmPassword = () => {
    setConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  const formik = useFormik<FormikValues>({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: (values) => {
      if (code) {
        firebase.confirmPasswordReset(code, values.password)
          .then(() => setPasswordChanged(true))
          .catch(error => formik.setFieldError('password', error.message));
      } else {
        formik.setFieldError('password', 'Invalid code');
      }
    }
  });

  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
    formik.setFieldValue(event.target.name as string, event.target.value as any);
  };

  return (
    <Grid container alignItems="center" direction="column" justify="center">
      <Grid item className={classes.title}>
        <Typography component="h1" variant="h1">
          Change password
        </Typography>
      </Grid>
      <Grid item xs className={classes.input}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth error={hasError(formik, 'password')}>
                <OutlinedInput
                  name="password" value={formik.values.newPassword} onChange={handleChange}
                  startAdornment={<InputAdornment position="start">
                    <Password primaryColor="#9CB1C5" set="light"/>
                  </InputAdornment>
                  }
                  placeholder="Password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  endAdornment={<InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="small"
                    >
                      {isPasswordVisible ? <Show primaryColor="#9CB1C5" set="light"/> :
                        <Hide primaryColor="#9CB1C5" set="light"/>}
                    </IconButton>
                  </InputAdornment>}/>
                <FormHelperText>{errorMsg(formik, 'password')}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={hasError(formik, 'confirmPassword')}>
                <OutlinedInput
                  name="confirmPassword" value={formik.values.confirmPassword}
                  onChange={handleChange}
                  startAdornment={<InputAdornment position="start">
                    <Password primaryColor="#9CB1C5" set="light"/>
                  </InputAdornment>
                  }
                  placeholder="Confirm Password"
                  type={isConfirmPasswordVisible ? 'text' : 'password'}
                  endAdornment={<InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="small"
                    >
                      {isConfirmPasswordVisible ? <Show primaryColor="#9CB1C5" set="light"/> :
                        <Hide primaryColor="#9CB1C5" set="light"/>}
                    </IconButton>
                  </InputAdornment>}/>
                <FormHelperText>{errorMsg(formik, 'confirmPassword')}</FormHelperText>
              </FormControl>
            </Grid>
            {passwordChanged && (
              <Grid item xs>
                <Alert severity="success">Password changed</Alert>
              </Grid>
            )}
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button color="primary" variant="contained" type="submit">Change password</Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
