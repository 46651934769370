import Snackbar from 'components/Snackbar';
import React, { useContext } from 'react';
import notificationsContext, { NotificationsContext } from 'contexts/notificationsContext';

const NotificationsContainer: React.FC = () => {
  const context = useContext<NotificationsContext>(notificationsContext);

  return context.text ? <Snackbar severity={context.severity} message={context.text}/> : null;
};

export default NotificationsContainer;