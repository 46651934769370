import { AccordionDetails, withStyles } from '@material-ui/core';

const ElevateAccordionDetails = withStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
    padding: theme.spacing(4),
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2)
    }
  }
}))(AccordionDetails);

export default ElevateAccordionDetails;