import { SvgIcon, withStyles } from '@material-ui/core';

const StyledSvgIcon = withStyles({
  root: {
    fill: 'none',
    stroke: 'currentColor',
    fontSize: '1em'
  }
})(SvgIcon);

export default StyledSvgIcon;