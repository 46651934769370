import { Box, createStyles } from '@material-ui/core';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import { makeStyles } from '@material-ui/core/styles';
import { TabContext, TabPanel } from '@material-ui/lab';
import { PAGE } from 'actions/demo';
import ActionIcon from 'components/ActionIcon';
import DemoBanner from 'components/DemoBanner/DemoBanner';
import PageTitle from 'components/PageTitle';
import ScoresSelect from 'components/ScoresSelect';
import { Tab, Tabs } from 'components/styled';
import StyledPaper from 'components/StyledPaper';
import CurrentScoreContext from 'contexts/currentScore';
import withDemoAccount from 'hocs/withDemoAccount';
import withUser, { WithUserProps } from 'hocs/withUser';
import ActionsList from 'pages/Actions/components/ActionsList';
import DemoActions from 'pages/Actions/components/DemoActions';
import React, { useContext } from 'react';
import { ACTION, getDescription, sortActions } from 'utils/actionsUtils';
import { useGetActionsQuery } from 'utils/queryProxy';
import { filterByDate } from 'utils/testDateUtils';
import { fbanalytics } from '../../config/firebase'

const useStyles = makeStyles((theme) =>
  createStyles({
    tabIcon: {
      fontSize: 'inherit',
      marginRight: theme.spacing(1)
    },
    tabPanel: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(1)}px 0`
      }
    },
    root: {
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    gradientLeft: {
      position: 'absolute',
      height: 44,
      width: 48,
      marginLeft: 0,
      marginTop: -50,
      background: theme.palette.type === 'light' ? 'linear-gradient(90deg, rgba(255, 255, 255, 0.84) 21.85%, rgba(255, 255, 255, 0) 100%)' :
        'linear-gradient(90deg, rgba(54, 53, 64, 0.84) 21.85%, rgba(54, 53, 64, 0) 100%)'
    },
    gradientRight: {
      position: 'absolute',
      height: 44,
      width: 48,
      right: 0,
      marginTop: -50,
      background: theme.palette.type === 'light' ? 'linear-gradient(270deg, rgba(255, 255, 255, 0.84) 21.85%, rgba(255, 255, 255, 0) 100%)' :
        'linear-gradient(270deg, rgba(54, 53, 64, 0.84) 21.85%, rgba(54, 53, 64, 0) 100%)'
    }
  })
);

const Actions: React.FC<WithUserProps> = ({ user }) => {
  const classes = useStyles();
  fbanalytics.logEvent('actions_visit');
  const { score } = useContext(CurrentScoreContext);
  const [tab, setTab] = React.useState('all');
  const { data: actionsData } = useGetActionsQuery({
    variables: {
      firebaseUID: user.firebaseUID
    }
  });

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
  };

  const actions = actionsData && filterByDate(sortActions(actionsData), score?.testDate);

  return (<>
    <DemoBanner page={PAGE.ACTIONS} />
    <PageTitle title="Actions">
      <Box pl={2} component="span">
        <ScoresSelect />
      </Box>
    </PageTitle>
    <TabContext value={tab}>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="inherit"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="All" value="all" icon={<ActionIcon category={ACTION.ALL} className={classes.tabIcon} />} />
        <Tab label="Nutrition" value="nutrition"
          icon={<ActionIcon category={ACTION.NUTRITION} className={classes.tabIcon} />} />
        <Tab label="Lifestyle" value="lifestyle"
          icon={<ActionIcon category={ACTION.LIFESTYLE} className={classes.tabIcon} />} />
        <Tab label="Supplements" value="supplements"
          icon={<ActionIcon category={ACTION.SUPPLEMENTS} className={classes.tabIcon} />} />
      </Tabs>
      <HiddenCss smUp>
        <div className={classes.gradientLeft} />
        <div className={classes.gradientRight} />
      </HiddenCss>
      <StyledPaper elevation={0} container className={classes.root}>
        <TabPanel value="all" className={classes.tabPanel}>
          <ActionsList
            actions={actions}
            title="All actions"
            tooltip={<>
              <div>
                Your tailored list of health improvement actions, ordered by greatest impact. Each
                impact score considers your health goals, blood test results and lifestyle behaviours.
              </div>
              <br />
              <div>
                Filter using the tabs or the filter icon. Click on the action to show more
                information.
              </div>
            </>}
            description={getDescription(ACTION.ALL, user)} />
        </TabPanel>
        <TabPanel value="nutrition" className={classes.tabPanel}>
          <ActionsList actions={actions?.filter(action => action.category === 'Nutrition')} title="Nutrition actions"
            description={getDescription(ACTION.NUTRITION, user)} />
        </TabPanel>
        <TabPanel value="lifestyle" className={classes.tabPanel}>
          <ActionsList actions={actions?.filter(action => action.category === 'Lifestyle')} title="Lifestyle actions"
            description={getDescription(ACTION.LIFESTYLE, user)} />
        </TabPanel>
        <TabPanel value="supplements" className={classes.tabPanel}>
          <ActionsList actions={actions?.filter(action => action.category === 'Supplements')}
            title="Supplements actions"
            description={getDescription(ACTION.SUPPLEMENTS, user)} />
        </TabPanel>
      </StyledPaper>
    </TabContext>
  </>);
};

export default withUser(withDemoAccount(Actions, DemoActions, PAGE.ACTIONS));
