import { ROUTE } from 'config/routes';
import useUser from 'hooks/useUser';
import LoginForm from 'pages/Login/components/LoginForm';
import ResetPasswordForm from 'pages/Login/components/ResetPasswordForm';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FirebaseReducer, isEmpty, isLoaded, useFirebase } from 'react-redux-firebase';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'reducers';

export interface ILoginProps extends RouteComponentProps<{}> {
}

export const Login: React.FC<ILoginProps> = ({ location }) => {
  const firebase = useFirebase();
  const [isLoginForm, setLoginForm] = useState(true);
  const { auth, authError } = useSelector<IRootState, FirebaseReducer.Reducer>(state => state.firebase);
  const user = useUser();

  const handleLogin = (email: string, password: string) => firebase.login({ email, password });
  const handleReset = (email: string) => firebase.resetPassword(email);
  const showReset = () => setLoginForm(false);
  const hideReset = () => setLoginForm(true);

  if (isLoaded(auth) && !isEmpty(auth) && user) {
    const prevPathname = (location.state as any)?.from?.pathname;

    const { from } = prevPathname && prevPathname !== ROUTE.LOGOUT ? (location.state as any) : {
      from: {
        pathname: user.demoUser ? '/profile/lifestyle' : '/',
        search: location.search
      }
    };

    return <Redirect to={from} />;
  }
  return (
    <>
      {isLoginForm ? <LoginForm handleLogin={handleLogin} handleReset={showReset} loginError={authError} /> :
        <ResetPasswordForm hideReset={hideReset} handleReset={handleReset} />}
    </>
  );
};

export default Login;
