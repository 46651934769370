import { Score } from 'generated/graphql';
import React from 'react';

export interface ICurrentScoreContext {
  score: Score | null;
  setScore: Function
}

const CurrentScoreContext = React.createContext<ICurrentScoreContext>({
  score: null,
  setScore: (score: any) => {}
});

export default CurrentScoreContext;