import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';
import { Box, Button, createStyles, Grid, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Maybe,
  Plan,
  StartBillingSessionDocument,
  StartBillingSessionQuery,
  Subscription,
  useGetSubscriptionQuery
} from 'generated/graphql';
import withUser, { WithUserProps } from 'hocs/withUser';
import { RefreshIcon } from 'icons/uicons';
import React from 'react';
import formatDate from 'utils/formatDate';

const useStyles = makeStyles(theme => createStyles({
  iconCell: {
    alignSelf: 'flex-start'
  },
  iconButton: {
    display: 'flex',
    borderRadius: '50%',
    background: 'linear-gradient(96.74deg, #41B0F6 0%, #8AD2FF 94.85%)',
    width: 40,
    height: 40,
    fontSize: 20,
    color: theme.palette.common.white
  },
  icon: {
    margin: 'auto'
  },
  renewalLabel: {
    fontWeight: 600
  },
  statusActive: {
    color: theme.palette.type === 'dark' ? theme.palette.success.main : theme.palette.success.contrastText,
    fontWeight: 600
  },
  statusNotActive: {
    color: theme.palette.error.main
  },
  linkCell: {
    textAlign: 'center'
  },
  link: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));

const getSubscriptionTitle = (plan?: Maybe<Plan>) => plan ? `ElevateMe ${plan.interval_count} ${plan.interval} subscription` : 'N/A';

const getSubscriptionStatusText = (plan?: Maybe<Plan>) => (plan && plan.active) ? 'Active' : 'N/A';

const CurrentSubscription: React.FC<WithUserProps> = ({ user }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const { data } = useGetSubscriptionQuery({
    variables: {
      customerId: user.stripeCustomerID
    }
  });

  const subscription = data?.getSubscription as Subscription;

  const onManageClick = () => {
    if (subscription && subscription.plan?.interval) {
      client.query<StartBillingSessionQuery>({
        query: StartBillingSessionDocument,
        variables: {
          customerId: user.stripeCustomerID
        }
      }).then(data => {
        const billingPortal = data.data.startBillingSession;
        if (billingPortal?.url) {
          window.open(billingPortal.url);
        }
      });
    } else {
      window.open('https://www.elevateme.ai/health-plans/')
    }
  };

  return <Grid container justify="space-between" alignItems="center" spacing={2}>
    <Grid item className={classes.iconCell}>
      <Box borderRadius="50%" className={classes.iconButton}>
        <RefreshIcon className={classes.icon} />
      </Box>
    </Grid>
    <Grid item container justify="space-around" xs spacing={1}>
      {
        (subscription && subscription.plan?.interval) ? (
          <>
            <Grid item xs={12} sm="auto">
              {getSubscriptionTitle(subscription.plan)}
            </Grid>
            <Grid item xs={12} sm="auto">
              <span
                className={classes.renewalLabel}>Renewal date:</span> {formatDate(subscription.current_period_end)}
            </Grid>
            <Grid item xs={12} sm="auto">
              <Typography className={subscription.plan?.active ? classes.statusActive : classes.statusNotActive}>
                {getSubscriptionStatusText(subscription.plan)}
              </Typography>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            You don’t appear to have an active ElevateMe test subscription.
          </Grid>
        )
      }

    </Grid>
    <Grid item xs={12} lg="auto" className={classes.linkCell}>
      <Button variant="contained" color="primary" className={classes.link} onClick={onManageClick}>
        {subscription.plan?.interval ? 'Manage subscription' : 'See price plans'}
      </Button>
    </Grid>
  </Grid>;
};

export default withUser(CurrentSubscription);