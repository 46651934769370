import { createStyles, makeStyles } from '@material-ui/core';
import { toggleDarkMode } from 'actions/userPreferences';
import ElevateToggleButton from 'components/ElevateToggleButton';
import ElevateToggleButtonGroup from 'components/ElevateToggleButtonGroup';
import MoonIcon from 'icons/Moon';
import SunIcon from 'icons/Sun';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducers';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      padding: 4
    },
    icon: {
      fontSize: 16,
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5)
    }
  }));

const ToggleTheme: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const darkMode = useSelector((state: IRootState) => state.userPreferences.darkMode);

  const handleClick = (event: React.MouseEvent<HTMLElement>, newMode: boolean | null) => {
    if (typeof newMode === 'boolean') {
      dispatch(toggleDarkMode(newMode));
    }
  };

  return (
    <ElevateToggleButtonGroup value={darkMode} onChange={handleClick} exclusive size="small">
      <ElevateToggleButton value={false} aria-label="light" className={classes.button}>
        <SunIcon className={classes.icon} />
      </ElevateToggleButton>
      <ElevateToggleButton value={true} aria-label="dark" className={classes.button}>
        <MoonIcon className={classes.icon} />
      </ElevateToggleButton>
    </ElevateToggleButtonGroup>
  );
};

export default ToggleTheme;