import { Box, createStyles, makeStyles, Typography, useTheme } from '@material-ui/core';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import { TabContext, TabPanel } from '@material-ui/lab';
import BloodGroupIcon from 'components/BloodGroupIcon';
import { Tab, Tabs } from 'components/styled';
import StyledTooltip from 'components/StyledTooltip/StyledTooltip';
import { TestResult } from 'generated/graphql';
import Biomarker from 'models/Biomarker';
import TestResultsListItem from 'pages/Insights/TestResultsListItem';
import React from 'react';
// @ts-ignore
import { InfoSquare as InfoSquareIcon } from 'react-iconly';
import { filterByBloodGroup } from 'utils/biomarkersUtils';

const useStyles = makeStyles((theme) =>
  createStyles({
    tooltip: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1)
    },
    title: {
      marginBottom: theme.spacing(2)
    },
    tab: {
      fontSize: 14
    },
    tabPanel: {
      padding: 0
    },
    gradientLeft: {
      position: 'absolute',
      height: 44,
      width: 48,
      marginLeft: 0,
      marginTop: -50,
      background: theme.palette.type === 'light' ? 'linear-gradient(90deg, rgba(255, 255, 255, 0.84) 21.85%, rgba(255, 255, 255, 0) 100%)' :
        'linear-gradient(90deg, rgba(54, 53, 64, 0.84) 21.85%, rgba(54, 53, 64, 0) 100%)'
    },
    gradientRight: {
      position: 'absolute',
      height: 44,
      width: 48,
      right: 15,
      marginTop: -50,
      background: theme.palette.type === 'light' ? 'linear-gradient(270deg, rgba(255, 255, 255, 0.84) 21.85%, rgba(255, 255, 255, 0) 100%)' :
        'linear-gradient(270deg, rgba(54, 53, 64, 0.84) 21.85%, rgba(54, 53, 64, 0) 100%)'
    }
  })
);

export interface BloodGroupsProps {
  biomarkers: Array<Biomarker>;
  testResult: TestResult;
}

const BloodGroups: React.FC<BloodGroupsProps> = ({ biomarkers, testResult }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [tab, setTab] = React.useState('all');

  const groupCategories = biomarkers
    .map(marker => marker.bloodGroup)
    .filter((elem, pos, arr) => arr.indexOf(elem) === pos)
    .sort((a, b) => a.localeCompare(b));

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
  };

  return (
    <>
      <Typography variant="h3" component="h2" className={classes.title}>
        Blood test results
        <StyledTooltip
          className={classes.tooltip}
          title={<>
            <div> Your blood test results are ordered with least optimal at the top.</div>
            <br />
            <div>
              Choose a tab to filter for blood groups or specific health areas. Click on the result to show
              insights
              and trends for that test.
            </div>
          </>}>
          <InfoSquareIcon set="light" size="small" color={theme.palette.grey[500]} />
        </StyledTooltip>
      </Typography>
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="All" value="all" className={classes.tab}
            icon={<BloodGroupIcon category="all" />} />
          {groupCategories.map(category =>
            <Tab label={category} value={category} key={category} className={classes.tab}
              icon={<BloodGroupIcon category={category} />} />
          )}
        </Tabs>
        <HiddenCss smUp>
          <div className={classes.gradientLeft} />
          <div className={classes.gradientRight} />
        </HiddenCss>
        <Box mt={3}>
          <TabPanel value="all" className={classes.tabPanel}>
            {biomarkers.map(biomarker => <TestResultsListItem key={biomarker.id} biomarker={biomarker}
              testResult={testResult} />)}
          </TabPanel>
          {groupCategories.map(category =>
            <TabPanel value={category} key={category} className={classes.tabPanel}>
              {filterByBloodGroup(biomarkers, category).map(biomarker => <TestResultsListItem key={biomarker.id}
                biomarker={biomarker}
                testResult={testResult} />)}
            </TabPanel>
          )}
        </Box>
      </TabContext>
    </>
  );
};

export default BloodGroups;