import { Actions, Home, Insights, Login, Logout, Profile, ResetPassword, Demo, OrderSuccess } from 'pages';
import { RouteProps } from 'react-router-dom';

export enum ROUTE {
  HOME = '/',
  ACTIONS = '/actions',
  INSIGHTS = '/insights',
  INSIGHTS_PERFORMANCE_AREA = '/insights/performance/:areaKey',
  LOGIN = '/login',
  DEMO = '/demo',
  LOGOUT = '/logout',
  PROFILE = '/profile',
  PROFILE_TAB = '/profile/:tab',
  RESET_PASSWORD = '/reset-password',
  ORDER_SUCCESS = '/order-success',
}

export const commonRoutes: RouteProps[] = [
  {
    path: ROUTE.LOGIN,
    component: Login
  },
  {
    path: ROUTE.DEMO,
    component: Demo
  },
  {
    path: ROUTE.RESET_PASSWORD,
    component: ResetPassword
  }
]

export const protectedRoutes: RouteProps[] = [
  {
    exact: true,
    path: ROUTE.HOME,
    component: Home
  },
  {
    exact: true,
    path: ROUTE.ACTIONS,
    component: Actions
  },
  {
    exact: true,
    path: [ROUTE.INSIGHTS, ROUTE.INSIGHTS_PERFORMANCE_AREA],
    component: Insights
  },
  {
    path: ROUTE.LOGOUT,
    component: Logout
  },
  {
    path: [ROUTE.PROFILE_TAB, ROUTE.PROFILE],
    component: Profile
  },
  {
    path: ROUTE.ORDER_SUCCESS,
    component: OrderSuccess
  }
];
