import { createStyles, LinearProgress, LinearProgressProps, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = (color: string) => makeStyles((theme) =>
  createStyles({
    root: {
      height: 7,
      borderRadius: 6
    },
    colorPrimary: {
      backgroundColor: 'rgba(156, 177, 197, 0.12)'
    },
    bar: {
      borderRadius: 6,
      backgroundColor: color
    }
  })
);
export type BorderLinearProgressProps = {
  barColor: string;
} & LinearProgressProps;

const BorderLinearProgress: React.FC<BorderLinearProgressProps> = ({ barColor, ...props }) => {
  const classes = useStyles(barColor)();
  return <LinearProgress classes={classes} {...props} />;
};

export default BorderLinearProgress;