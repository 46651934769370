import createPalette, { PaletteOptions } from '@material-ui/core/styles/createPalette';


export const GREEN = '#84EDB9';

const paletteOptions: PaletteOptions = {
  primary: {
    main: '#41B0F6'
  },
};

export const lightPalette = createPalette({
  ...paletteOptions,
  secondary: {
    main: '#E3F3FE'
  },
  divider: 'rgba(156, 177, 197, 0.2)',
  grey: {
    100: '#FBFDFF',
    // 500: '#9CB1C5'
    500: '#6A818F'
  },
  error: {
    // main: '#FA4323'
    main: '#f55b3f'
  },
  warning: {
    main: '#FFE9C2',
    contrastText: '#B57D1E'
  },
  success: {
    main: '#C3EED9',
    contrastText: '#0DA157'
  },
  // success: {
  //   main: '#84EDB9',
  //   contrastText: '#292830'
  // },
  orange: {
    main: '#FFDACE',
    contrastText: '#D0633E'
  },
  background: {
    default: '#F6FBFF'
  },
  text: {
    primary: '#292830',
    // secondary: '#9CB1C5'
    secondary: '#6A818F'
  },
  type: 'light'
});

export const darkPalette = createPalette({
  ...paletteOptions,
  secondary: {
    main: '#505461'
  },
  divider: '#555A68',
  grey: {
    // 500: '#8E8EA0',
    500: '#DBDBDB',
    900: '#292830'
  },
  error: {
    main: '#FF8D9F',
    contrastText: '#292830'
  },
  warning: {
    // main: '#FFD126',
    main: '#FFD388',
    contrastText: '#292830'
  },
  success: {
    main: '#84EDB9',
    contrastText: '#292830'
  },
  orange: {
    main: '#FFA588',
    contrastText: '#292830'
  },
  background: {
    default: '#393942',
    // paper: '#363540'
    // paper: '#292830'
    paper: '#313038'

  },
  text: {
    // secondary: '#8E8EA0'
    secondary: '#DBDBDB'
  },
  type: 'dark'
});
