import NotificationsContainer from 'components/NotificationsContainer';
import { NotificationsProvider, NotificationType } from 'contexts/notificationsContext';
import React, { useMemo, useState } from 'react';

const INITIAL_STATE: { text: string, severity: NotificationType } = { text: '', severity: 'info' };

const NotificationsManager: React.FC = ({ children }) => {
  const [state, setState] = useState(INITIAL_STATE);
  const notify = useMemo(() => (text: string, severity: NotificationType) => setState({ text, severity }), [setState]);
  const clear = useMemo(() => () => setState(INITIAL_STATE), [setState]);

  return (
    <NotificationsProvider value={{ ...state, notify, clear }}>
      {children}
      <NotificationsContainer/>
    </NotificationsProvider>
  );
};

export default NotificationsManager;