import { ACTION_TYPES, PAGE } from 'actions/demo';
import { AnyAction } from 'redux';

const savedState = window.localStorage.getItem('DemoState');

const initialState = {
  [PAGE.DASHBOARD]: false,
  [PAGE.ACTIONS]: false,
  [PAGE.INSIGHTS]: false,
};

export type DemoState = Readonly<typeof initialState>;
const loadedState = savedState ? JSON.parse(savedState) as DemoState : initialState;

const demoReducer = (state: DemoState = loadedState, action: AnyAction): DemoState => {
  let newState = state;
  switch (action.type) {
    case ACTION_TYPES.SHOW_PAGE:
      newState = {
        ...state,
        [action.payload]: true
      };
      break;
    case ACTION_TYPES.RESET:
      newState = initialState;
      break;
    default:
      return newState;
  }

  window.localStorage.setItem('DemoState', JSON.stringify(newState));
  return newState;
}

export default demoReducer;