import { Box, createStyles, Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ElevateTooltip from 'components/ElevateTooltip';
import Loading from 'components/Loading';
import StyledPaper from 'components/StyledPaper';
import { Invoice, useGetAllInvoicesQuery } from 'generated/graphql';
// import { Charge, useGetAllChargesQuery } from 'generated/graphql';
import withUser, { WithUserProps } from 'hocs/withUser';
import AddressTooltip from 'pages/Profile/Subscriptions/AddressTooltip';
import { Truck } from 'phosphor-react';
import React from 'react';
import formatCurrency from 'utils/formatCurrency';
import formatDate from 'utils/formatDate';

const useStyles = makeStyles(theme => createStyles({
  root: {
    minWidth: 700
  },
  caption: {
    padding: '15px 30px',
    [theme.breakpoints.down('xs')]: {
      padding: 15
    }
  },
  invoiceRow: {
    marginBottom: theme.spacing(1.5)
  },
  truckCell: {
    display: 'flex'
  },
  truckIcon: {
    margin: 'auto',
    color: theme.palette.grey[500],
    fontSize: 22
  }
}));

const InvoicesTable: React.FC<WithUserProps> = ({ user }) => {
  const classes = useStyles();
  const { loading, data } = useGetAllInvoicesQuery({
    variables: {
      customerId: user.stripeCustomerID
    }
  });

  const invoices = (data?.getAllInvoices as Array<Invoice> || []);

  return (
    <div className={classes.root}>
      <Box className={classes.caption}>
        <Grid container spacing={2}>
          <Grid item xs={3}>Order number</Grid>
          <Grid item xs={3}>Order type</Grid>
          <Grid item xs={2}>Price</Grid>
          <Grid item xs>Order date</Grid>
        </Grid>
      </Box>
      {loading && <Box height={50}><Loading /></Box>}
      {invoices.map((invoice) => (
        <StyledPaper elevation={0} variant="outlined" className={classes.invoiceRow} key={invoice.number}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={3}>{invoice.number}</Grid>
            <Grid item xs={3}>{invoice.subscription ? 'Subscription' : 'Single order'}</Grid>
            <Grid item xs={2}>{formatCurrency((invoice?.amount_paid || 0) / 100)}</Grid>
            <Grid item xs>{formatDate(invoice.created)}</Grid>
            <Grid item className={classes.truckCell}>
              <ElevateTooltip enterTouchDelay={10} leaveTouchDelay={10000} title={invoice.customer_shipping?.line1 ?
                <AddressTooltip address={invoice.customer_shipping} /> : ''}>
                <Truck className={classes.truckIcon} />
              </ElevateTooltip>
            </Grid>
          </Grid>
        </StyledPaper>
      ))}
    </div>
  );
};

export default withUser(InvoicesTable);