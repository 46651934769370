export const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1);
export const deCapitalize = (str: string) => str[0].toLowerCase() + str.slice(1);
export const removeParentheses = (str: string) => str.replace(/ *\([^)]*\) */g, '');

export const readableJoin = (values: Array<string>) => {
  if (!values) return '';
  const newValues = values.slice();
  if (newValues.length > 1) {
    const lastValue = newValues.pop();
    return [newValues.join(', '), lastValue].join(' and ');
  } else if (newValues.length === 1) return newValues[0];
  return '';
}