import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import { PAGE, showPage } from 'actions/demo';
import DemoMessage from 'components/DemoMessage';
import React from 'react';
import { useDispatch } from 'react-redux';

const DemoDashboard: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(showPage(PAGE.DASHBOARD));
  };

  const button1 = <Button
    variant="contained"
    color="primary"
    fullWidth={isMobile}
    onClick={handleClick}
  >
    Demo Dashboard
  </Button>;
  const button2 = <Button
    variant="contained"
    color="primary"
    fullWidth={isMobile}
    href="https://www.elevateme.ai/sample/#sample-video"
    target="_blank"
  >
    Blood sample video
  </Button>;

  return <DemoMessage
    title="Your ElevateMe Dashboard"
    body1="Any dashboard scores will appear here once your results are available."
    body2="For now, try out the demo account dashboard or check out our video on how to collect your blood sample:"
    button1={button1}
    button2={button2}
  />;
};

export default DemoDashboard;