/* eslint-disable react-hooks/exhaustive-deps */
import FirebaseStorageContext, { getDownloadURL } from 'contexts/FirebaseStorage';
import { useContext, useEffect, useState } from 'react';

const useStorageLink = (ref: string) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>();
  const { values, getDownloadURL } = useContext(FirebaseStorageContext);

  useEffect(() => {
    getDownloadURL(ref)
  }, []);

  useEffect(() => {
    if (values[ref]) {
      setImgSrc(values[ref] as string)
    } else if (values[ref] === null) {
      setImgSrc(undefined)
    }
  }, [values]);

  return imgSrc;
};

export const useFirebaseLink = (ref: string) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>();

  useEffect(() => {
    getDownloadURL(ref).then((link) =>  setImgSrc(link as string), () => {})
  }, [ref]);

  return imgSrc;
};

export const useAvatar = (uid: string) => useStorageLink(`users/${uid}/profile.jpg`);

export default useStorageLink;