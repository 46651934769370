import { Box, Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import StyledTooltip from 'components/StyledTooltip';
import { GREEN } from 'config/theme/palette';
import { format } from 'date-fns';
import { Metadata, useGetCustomerQuery } from 'generated/graphql';
import withUser, { WithUserProps } from 'hocs/withUser';
import React from 'react';

export type TestResultStatus = 'orderReceived' | 'kitDispatched' | 'labProcessing' | 'resultsReady';

const statusesOrder = {
  orderReceived: 0,
  kitDispatched: 1,
  labProcessing: 2,
  resultsReady: 3
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(3)
    }
  },
  line: {
    height: 7,
    marginLeft: -1,
    marginRight: -1,
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.success.main : GREEN
  },
  dottedLine: {
    height: 0,
    border: `2px dashed ${theme.palette.type === 'dark' ? theme.palette.success.main : GREEN}`
  },
  dot: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 9,
    borderStyle: 'solid',
    borderColor: theme.palette.type === 'dark' ? theme.palette.success.main : GREEN,
    width: 18,
    height: 18
  },
  dotCompleted: {
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.success.main : GREEN
  },
  legend: {
    position: 'relative',
    fontSize: 12,
    display: 'inline-block',
    top: -40,
    width: 200,
    marginLeft: -92,
    textAlign: 'center'
  },
  firstLegend: {
    marginLeft: -4,
    textAlign: 'left'
  },
  lastLegend: {
    marginLeft: -186,
    textAlign: 'right'
  }
}));

const getTestDate = (metadata: Metadata | null | undefined, field: 'order_received_date' | 'kit_dispatched_date' | 'lab_processing_date' | 'results_ready_date') =>
  (metadata && metadata[field] && metadata[field] !== 'null') ? format(parseInt(metadata[field]) * 1000, 'd MMM yy') : ''

const isStageCompleted = (stage: TestResultStatus, status: TestResultStatus) => statusesOrder[status] >= statusesOrder[stage];

const CurrentTestBar: React.FC<WithUserProps> = ({ user }) => {
  const { data } = useGetCustomerQuery({
    variables: {
      customerId: user.stripeCustomerID
    }
  });

  const metadata = data?.getCustomer?.metadata;
  const status = metadata?.current_order_status as TestResultStatus;
  const classes = useStyles();

  return <Box className={classes.root}>
    <Grid container alignItems="center">
      <StyledTooltip
        title={getTestDate(metadata, 'order_received_date')}
        placement="bottom">
        <Grid item className={clsx(classes.dot, isStageCompleted('orderReceived', status) && classes.dotCompleted)}>
          <Box component="span" className={clsx(classes.legend, classes.firstLegend)}>Order<br/>received</Box>
        </Grid>
      </StyledTooltip>
      <Grid item xs className={isStageCompleted('kitDispatched', status) ? classes.line : classes.dottedLine}/>
      <StyledTooltip
        title={getTestDate(metadata, 'kit_dispatched_date')}
        placement="bottom">
        <Grid item className={clsx(classes.dot, isStageCompleted('kitDispatched', status) && classes.dotCompleted)}>
          <Box component="span" className={classes.legend}>Kit<br/>dispatched</Box>
        </Grid>
      </StyledTooltip>
      <Grid item xs className={isStageCompleted('labProcessing', status) ? classes.line : classes.dottedLine}/>
      <StyledTooltip
        title={getTestDate(metadata, 'lab_processing_date')}
        placement="bottom">
        <Grid item className={clsx(classes.dot, isStageCompleted('labProcessing', status) && classes.dotCompleted)}>
          <Box component="span" className={classes.legend}>Lab<br/>processing</Box>
        </Grid>
      </StyledTooltip>
      <Grid item xs className={isStageCompleted('resultsReady', status) ? classes.line : classes.dottedLine}/>
      <StyledTooltip
        title={getTestDate(metadata, 'results_ready_date')}
        placement="bottom">
        <Grid item className={clsx(classes.dot, isStageCompleted('resultsReady', status) && classes.dotCompleted)}>
          <Box component="span" className={clsx(classes.legend, classes.lastLegend)}>Results<br/>ready</Box>
        </Grid>
      </StyledTooltip>
    </Grid>
  </Box>;
};

export default withUser(CurrentTestBar);