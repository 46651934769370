import AppLoader from 'components/AppLoader';

import Layout from 'components/Layout';
import ProtectedRoute from 'components/ProtectedRoute';
import { commonRoutes, protectedRoutes } from 'config/routes';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

const Router: React.FC = () => {
  return (
    <AppLoader>
      <BrowserRouter>
        <Layout>
          <Switch>
            {commonRoutes.map((route, i: number) => (
              <Route key={`common_${i}`} {...route} />
            ))}
            {protectedRoutes.map((route, i: number) => (
              <ProtectedRoute key={`protected_${i}`} {...route} />
            ))}
          </Switch>
        </Layout>
      </BrowserRouter>
    </AppLoader>
  );
};

export default Router;
