import { SvgIconProps } from '@material-ui/core/SvgIcon';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import BarbellIcon from 'icons/Barbell';
import CookieIcon from 'icons/uicons/Cookie';
import MedicineIcon from 'icons/uicons/Medicine';
import React from 'react';
import { ACTION } from 'utils/actionsUtils';

export interface ActionIconProps extends SvgIconProps {
  category: ACTION;
}

const ActionIcon: React.FC<ActionIconProps> = ({ category, ...other }) => {
  switch (category) {
    case ACTION.ALL:
      return <FormatListBulletedIcon {...other}/>;
    case ACTION.LIFESTYLE:
      return <BarbellIcon {...other}/>;
    case ACTION.NUTRITION:
      return <CookieIcon {...other}/>;
    case ACTION.SUPPLEMENTS:
      return <MedicineIcon {...other}/>;
  }
};

export default ActionIcon;