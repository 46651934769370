import { SvgIconProps } from '@material-ui/core/SvgIcon';
import UIcon from 'icons/uicons/UIcon';
import React from 'react';

const ShoppingBagIcon = (props: SvgIconProps) => (
  <UIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M21,6H18A6,6,0,0,0,6,6H3A3,3,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V9A3,3,0,0,0,21,6ZM12,2a4,4,0,0,1,4,4H8A4,4,0,0,1,12,2ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V9A1,1,0,0,1,3,8H6v2a1,1,0,0,0,2,0V8h8v2a1,1,0,0,0,2,0V8h3a1,1,0,0,1,1,1Z"/>
  </UIcon>
);

export default ShoppingBagIcon;