import { Box, createStyles, Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import ProfileMenu from 'components/ProfileMenu/ProfileMenu';
import ToggleTheme from 'components/ToggleTheme';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1)
      }
    }
  })
);

export interface PageTitleProps {
  title: string
}

const PageTitle: React.FC<PageTitleProps> = ({ title, children }) => {
  const classes = useStyles();

  return <Typography variant="h1" component="h1" className={classes.root}>
    <Grid container alignItems="center">
      <Grid item>
        {title}
        {children}
      </Grid>
      <Grid item xs />
      <Hidden smDown implementation="js">
        <Grid item>
          <ToggleTheme/>
        </Grid>
        <Grid item>
          <Box pl={2}>
            <ProfileMenu/>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  </Typography>;
};

export default PageTitle;