import { gql } from 'graphql-tag';

const GET_BIOMARKERS = (genderBound: string, ageBracket: string) => {
  const query = `query GetBiomarkers {
        getBiomarkers {
            id
            biomarkerRef
            name
            unit
            bloodGroup
            description
            tooLow
            tooHigh
            roomToImprove
            learnMore
            optimalLevel
            performanceAreas
            ${genderBound} {
                ${ageBracket} {
                    criticallyLowUB
                    lowUB
                    subOptimalUB
                    optimalUB
                    supraOptimalUB
                    highUB
                    criticallyHighUB
                }
            }
        }
    }`;
  return gql(query);
};

export default GET_BIOMARKERS;