import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { isIOS } from "react-device-detect";
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    backgroundImage: 'url(/assets/score-badge-bg-left.svg), url(/assets/score-badge-bg-right.svg)',
    backgroundPositionX: '2%, 106%',
    backgroundPositionY: '50%, 6px',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%',
    borderRadius: 14,
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 48,
    marginRight: 48,
    fontSize: 14,
    fontWeight: 700,
    color: '#41B0F6',

  },
  textContainerGradient: {
    background: '-webkit-linear-gradient(103.84deg, #25EB9A 8.26%, #41B0F6 103.4%)',
    '-webkit-background-clip': 'text',
    '-webkit-box-decoration-break': 'clone',
    textFillColor: 'transparent'
  },
  icon: {
    fontSize: 16,
    display: 'inline-flex',
    color: '#41B0F6',
    marginRight: theme.spacing(0.5)
  },
  value: {
    marginLeft: theme.spacing(1),
    fontSize: 25
  },
  container: {
    padding: 1,
    display: 'inline-flex',
    height: 62,
    background: 'linear-gradient(103deg, #41B0F6, #25EB9A)',
    borderRadius: 15,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));

export interface ScoreBadgeProps {
  label: string,
  value: number,
  icon?: React.ReactNode
}

const ScoreBadge: React.FC<ScoreBadgeProps> = ({ label, value, icon }) => {
  const classes = useStyles();

  return <div className={classes.container}>
    <div className={classes.root}>
      <div className={clsx(classes.textContainer, !isIOS && classes.textContainerGradient)}>
        {icon && <span className={classes.icon}>{icon}</span>}
        <span>{label}</span>
        <span className={classes.value}>{value}%</span>
      </div>
    </div>
  </div>;
};

export default ScoreBadge;