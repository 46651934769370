import {
  Checkbox, ClickAwayListener,
  Collapse,
  createStyles,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@material-ui/core';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandLess,
  ExpandMore
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

export interface FilterOptions {
  key: string,
  label: string
}

export interface FiltersOptions {
  key: string,
  label: string,
  filters: Array<FilterOptions>
}

export interface FilterMenuProps {
  anchorEl: null | HTMLElement | SVGSVGElement,
  handleClose: any,
  onChange?: Function
  options: Array<FiltersOptions>,
  initialValue?: any,
}

const useStyles = makeStyles((theme) =>
  createStyles({
    menuPaper: {
      marginTop: theme.spacing(2),
      maxHeight: 'calc(100vh - 100px)',
      overflowY: 'auto',
      borderRadius: 6,
      '&::-webkit-scrollbar': {
        width: 4,
        borderRadius: 2
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.main,
        borderRadius: 2
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.divider
      }
    },
    menuItem: {
      paddingTop: 4,
      paddingBottom: 4
    },
    expandIcon: {
      fontSize: 16,
      color: theme.palette.grey[500]
    },
    listItemIcon: {
      minWidth: 'auto'
    },
    checkbox: {
      padding: '0 8px 0 0'
    },
    icon: {
      fontSize: 16
    },
    option: {
      fontSize: 12
    }
  })
);

const FilterMenu: React.FC<FilterMenuProps> = ({ anchorEl, handleClose, options, initialValue = {}, onChange }) => {
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const [hide, setHide] = useState<any>({});
  const [value, setValue] = useState<any>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleClick = (section: string) => (event: React.ChangeEvent<{}>) => {
    setHide({ ...hide, [section]: !hide[section] });
  };

  const handleChange = (section: string, option: string) => () => {
    const oldOption = value[section] && value[section][option];
    const newValue = {
      ...value, [section]: {
        ...value[section],
        [option]: !oldOption
      }
    };
    setValue(newValue);
    onChange && onChange(newValue);
  };

  const handleClickAway = (event: React.MouseEvent<EventTarget>) => {
    if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
      return;
    }

    handleClose(false);
  }

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      keepMounted
      placement="bottom-end"
      popperOptions={{
        modifiers: {
          flip: {
            enabled: false
          }
        }
      }}
    >
      <Paper className={classes.menuPaper}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <MenuList dense>
            {options.map((filterSection) => (
              <div key={filterSection.key}>
                <MenuItem onClick={handleClick(filterSection.key)} className={classes.menuItem}>
                  <ListItemText primary={filterSection.label}/>
                  {hide[filterSection.key] ? <ExpandMore className={classes.expandIcon}/> :
                    <ExpandLess className={classes.expandIcon}/>}
                </MenuItem>
                <Collapse in={!hide[filterSection.key]} timeout="auto">
                  <MenuList disablePadding dense>
                    {filterSection.filters.map((filter) => (
                      <MenuItem
                        key={`${filterSection.key}_${filter.key}`}
                        className={classes.menuItem}
                        onClick={handleChange(filterSection.key, filter.key)}>
                        <ListItemIcon className={classes.listItemIcon}>
                          <Checkbox
                            className={classes.checkbox}
                            size="small"
                            color="primary"
                            checked={!!(value[filterSection.key] && value[filterSection.key][filter.key])}
                            icon={<CheckBoxOutlineBlankIcon className={classes.icon}/>}
                            checkedIcon={<CheckBoxIcon className={classes.icon}/>}
                            inputProps={{ 'aria-labelledby': `${filterSection.key}_${filter.key}` }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={filter.label}
                          id={`${filterSection.key}_${filter.key}`}
                          className={classes.option}
                        />
                      </MenuItem>
                    ))}
                  </MenuList>
                </Collapse>
              </div>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};

export default FilterMenu;

