import {
  Box,
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  Typography
} from '@material-ui/core';
import UserAvatar from 'components/UserAvatar';
import notificationsContext from 'contexts/notificationsContext';
import withUser, { WithUserProps } from 'hocs/withUser';
import useRemoveAvatar from 'pages/Profile/ProfileForm/ProfileFormAvatar/useRemoveAvatar';
import useUpdateAvatar from 'pages/Profile/ProfileForm/ProfileFormAvatar/useUpdateAvatar';
import React, { useContext, useState } from 'react';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const demoUser = process.env.REACT_APP_DEMO_ACCOUNT as string;

const useStyles = makeStyles((theme) => createStyles({
  root: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  avatarButton: {
    overflow: 'hidden',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  avatar: {
    height: 100,
    width: 100,
    fontSize: 50
  },
  avatarSizeHint: {
    position: 'absolute',
    fontSize: 12,
    fontWeight: 500,
    marginTop: theme.spacing(0.8),
    color: theme.palette.grey[500],
    [theme.breakpoints.down('xs')]: {
      position: 'initial'
    }
  },
  updateButton: {
    background: theme.palette.common.black,
    opacity: 0.5,
    bottom: 0,
    left: 0,
    right: 0,
    color: 'white',
    padding: 3,
    position: 'absolute'
  }
}));

const ProfileFormAvatar: React.FC<WithUserProps> = ({ user }) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  const { notify } = useContext(notificationsContext);

  const removeAvatar = useRemoveAvatar(user.firebaseUID);
  const removeAvatarDemo = () => { notify('This is a demo account and cannot be changed.', 'success'); }

  const updateAvatar = useUpdateAvatar(user.firebaseUID);

  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/jpeg, image/png';
  input.onchange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const file = target.files[0];

      if (file) {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
          notify('Invalid file type. Please upload an image.', 'error');
          return;
        }

        if (file.size < MAX_FILE_SIZE) {
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = readerEvent => {
            const content = readerEvent.target?.result as ArrayBuffer;
            updateAvatar(content);
          };
        } else {
          notify('This file size is too large. Choose a smaller file.', 'error');
        }
      }
    }
  };

  const uploadPhoto = () => {
    if (user.firebaseUID !== demoUser) {
      input.click();
    }
  };

  return <Grid container alignItems="center" spacing={2} className={classes.root}>
    <Grid item xs={12} sm="auto">
      <IconButton className={classes.avatarButton} onClick={uploadPhoto}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <UserAvatar firstName={user.firstName} lastName={user.lastName} uid={user.firebaseUID}
          className={classes.avatar} />
        <Slide in={hover} mountOnEnter unmountOnExit direction="up">
          <Box className={classes.updateButton}><AddAPhotoIcon /></Box>
        </Slide>
      </IconButton>
    </Grid>
    <Grid item xs={12} sm="auto">
      {user.firebaseUID !== demoUser
        ? <Button variant="contained" color="secondary" onClick={removeAvatar}>Remove</Button>
        : <Button variant="contained" color="secondary" onClick={removeAvatarDemo}>Remove</Button>
      }
      <Typography className={classes.avatarSizeHint}>Maximum photo size 25 MB</Typography>
    </Grid>
  </Grid>;
};

export default withUser(ProfileFormAvatar);