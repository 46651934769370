import { Theme, Tooltip, TooltipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderRadius: 6,
    fontWeight: 400,
    fontSize: 13,
    padding: 15,
    boxShadow: '0px 47px 80px rgb(156 177 197 / 12%), 0px 14.1691px 24.1177px rgb(156 177 197 / 10%), 0px 5.88513px 10.0172px rgb(156 177 197 / 4%), 0px -2px 3.62304px rgb(156 177 197 / 6%)'
  }
}));

const StyledTooltip = (props: TooltipProps) => {
  const classes = useStyles();

  return <Tooltip enterTouchDelay={10} leaveTouchDelay={10000} arrow classes={classes} {...props} />;
};

export default StyledTooltip;