import Loading from 'components/Loading';
import { User } from 'generated/graphql';
import useUser from 'hooks/useUser';
import React from 'react';

export interface WithUserProps {
  user: User
}

const withUser = <P extends object>(WrappedComponent: React.ComponentType<P & WithUserProps>): React.FC<P> => {
  const WithUser = (props: P) => {
    const user = useUser();

    if (user) {
      return <WrappedComponent user={user} {...props} />;
    } else {
      return <Loading/>;
    }
  };

  return WithUser;
};

export default withUser;