import {
  AppBar,
  Box,
  createStyles,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ProfileMenu from 'components/ProfileMenu';
import SideMenu from 'components/SideMenu';
import ToggleTheme from 'components/ToggleTheme';
import React from 'react';

const drawerWidth = 256;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    grow: {
      flexGrow: 1
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1, 0, 4),
      ...theme.mixins.toolbar,
      alignContent: 'center',
      justifyContent: 'flex-end'
    },
    drawerTitle: {
      fontWeight: 'bold'
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        height: '100vh',
        flexShrink: 0
      }
    },
    drawerPaper: {
      width: drawerWidth,
      border: 'none',
      backgroundColor: theme.palette.type === 'light' ? '#fff' : theme.palette.grey[900],
      [theme.breakpoints.up('md')]: {
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15
      }
    },
    desktopLinks: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(4)
    },
    toolbar: theme.mixins.toolbar,
    appBar: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      }
    },
    toolbarMobile: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1)
      }
    },
    appLogo: {
      width: 180
    },
    appLogoMobile: {
      width: 131
    },
    menuButton: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      marginRight: 0,
      marginLeft: 0,
      marginTop: theme.spacing(2),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
        width: `calc(100% - ${theme.spacing(4)}px)`
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(3),
        width: `calc(100% - ${drawerWidth + theme.spacing(6)}px)`,
        marginLeft: drawerWidth + theme.spacing(3)
      }
    }
  })
);

export type IAuthenticatedLayoutProps = {};

const AuthenticatedLayout: React.FC<IAuthenticatedLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  return (
    <div className={classes.root}>
      <Hidden mdUp implementation="css">
        <AppBar position="fixed" className={classes.appBar} color="default" elevation={1}>
          <Toolbar className={classes.toolbarMobile}>
            <img className={classes.appLogoMobile}
              src={theme.palette.type === 'light' ? '/assets/logo-light.svg' : '/assets/logo-dark.svg'}
              alt="logo" />
            <div className={classes.grow} />
            <ProfileMenu />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          <Grid container className={classes.drawerHeader}>
            <Grid item xs>
              <Typography className={classes.drawerTitle} variant="h4">Menu</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <SideMenu />
          <Box ml={4} mt={2} display="inherit">
            <ToggleTheme />
          </Box>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          <Box pt={2.5} pl={4} pb={1.5}>
            <img src={theme.palette.type === 'light' ? '/assets/logo-light.svg' : '/assets/logo-dark.svg'}
              alt="logo" className={classes.appLogo} />
          </Box>
          <SideMenu />
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <Hidden mdUp implementation="css">
          <div className={classes.toolbar} />
        </Hidden>
        {children}
      </main>
    </div>
  );
};

export default AuthenticatedLayout;
