import { Box, createStyles, Grid, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';

const getRatingText = (rating: number): string => {
  switch (rating) {
    case 1:
      return 'Low';
    case 2:
      return 'Medium';
    case 3:
      return 'High';
    case 4:
      return 'Very high';
    default:
      return '';
  }
};

const getBarColor = (rating: number, position: number, fallback: string): string => {
  if (rating > position) {
    switch (rating) {
      case 1:
        return '#FA851B';
      case 2:
        return '#FFD126';
      case 3:
      case 4:
        return '#25EB9A';
      default:
        break;
    }
  }
  return fallback;
};

const useStyles = makeStyles(theme => createStyles({
  scoreBar: {
    height: 5,
    width: 23,
    marginRight: 3,
    '&:first-child': {
      borderRadius: '10px 0 0 10px'
    },
    '&:last-child': {
      borderRadius: '0 10px 10px 0'
    }
  }
}));

export interface ImpactRatingProps {
  rating: number
}

const ImpactRating: React.FC<ImpactRatingProps> = ({ rating }) => {
  const classes = useStyles();
  const theme = useTheme();

  return <>
    <Box pb={1}>{getRatingText(rating)}</Box>
    <Grid container>
      <Grid item className={classes.scoreBar}
        style={{ backgroundColor: getBarColor(rating, 0, theme.palette.divider) }} />
      <Grid item className={classes.scoreBar}
        style={{ backgroundColor: getBarColor(rating, 1, theme.palette.divider) }} />
      <Grid item className={classes.scoreBar}
        style={{ backgroundColor: getBarColor(rating, 2, theme.palette.divider) }} />
      <Grid item className={classes.scoreBar}
        style={{ backgroundColor: getBarColor(rating, 3, theme.palette.divider) }} />
    </Grid>
  </>;
};

export default ImpactRating;